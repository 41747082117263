import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import logo from "../assets/images/logo/logo.png";
import man from "../assets/images/user/user_default.jpg";
import auth from "../assets/images/auth/left-image.png";
import Divider from "../assets/icoicon/divider.svg";
import Facebook from "../assets/icoicon/facebook.svg";
import Google from "../assets/icoicon/google.svg";
import Twitter from "../assets/icoicon/twitter.svg";
import Loader from "../components/common/Loader";
import API from "../utils";
import axios from "axios";

const Logins = () => {
  const { BASE_URL, ENDPOINTS } = API;
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const [value, setValue] = useState(localStorage.getItem("profileURL"));

  useEffect(() => {
    if (value) localStorage.setItem("profileURL", value);
    else localStorage.setItem("profileURL", man);
  }, [value]);


  const validate = () => {
    const newErrors = {};

    const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/; // email regex
    const mobilePattern = /^[0-9]{10}$/; // mobile number regex (10 digits)

    // Check if email is valid (email, username, or mobile)
    if (!email.trim()) {
      newErrors.email = "Username/Email/Mobile is required";
    } else if (emailPattern.test(email.trim())) {
      // Valid email format
    } else if (mobilePattern.test(email.trim())) {
      // Valid mobile number format
    } else if (!/^[a-zA-Z0-9]{3,20}$/.test(email.trim())) {
      newErrors.email = "Invalid username format"; // Valid username (alphanumeric, length 3 to 20)
    } else { // newErrors.email = "Please enter a valid Username/Email/Mobile"; 
    }

    // Password validation (required and min length)
    if (!password.trim()) {
      newErrors.password = "Password is required";
    } else if (password.trim().length < 8) {
      newErrors.password = "Password must be at least 8 characters";
    }

    setErrors(newErrors); // Set the error state

    // Return true if no errors, otherwise false
    return Object.keys(newErrors).length === 0;
  };

  const loginAuth = async () => {
    // if (email === "test@gmail.com" && password === "test123") {
    //   localStorage.setItem("login", true);
    //   navigate(`/home`);
    // } else {
    //   toast.error("Incorrect password or username!");
    // }

    if (!validate()) {
      console.log("Validation failed", errors);
      return;
    }

    setLoading(true);
    try {
      const loginForm = new FormData();
      loginForm.append("role", "user");
      loginForm.append("username", email ?? "");
      loginForm.append("password", password ?? "");

      const response = await axios.post(`${BASE_URL}${ENDPOINTS.login}`, loginForm);
      const { status, message, payload } = response.data;
      if (status) {
        toast.success(message || "Login successful");
        localStorage.setItem("login", true);
        if (payload) {
          localStorage.setItem("token", payload?.token);
          localStorage.setItem("user", JSON.stringify(payload));
          localStorage.setItem("user_id", payload?.id);
        }
        navigate(`/home`);
      } else toast.error(message || "Login failed");
    } catch (error) {
      toast.error(error.response?.data.message || error.message);
      // console.error("Login Error:", error.response?.data.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  const socialAuth = (platform) => {
    // Simulate redirection for social logins
    navigate(`/home`);
    toast.success(`Logged in with ${platform}!`);
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <div className="ossn-layout-startup">
      <div className="container-fluid">
        <div className="ossn-home-container">
          <div className="row">
            <div className="col-center ossn-page-contents">
              <div className="row register-row">
                <div className="col-md-6 home-left-contents">
                  <img className="img-responsive" src={auth} alt="Welcome" />
                </div>
                <div className="col-md-6 home-right-contents">
                  <div className="logo">
                    <img src={logo} alt="Logo" />
                  </div>
                  <div className="ossn-widget-login">
                    <div className="widget-heading-home">Welcome to xPets</div>
                    <div className="widget-contents">
                      <p className="subtitle">Register now and start your adventure.</p>
                    </div>
                    <div className="login-container">
                      <div className="social-login-container">
                        <a href="https://app.xpets.com/action/social/login/facebook" className="btn btn-block facebooklogin">
                          <img src={Facebook} alt="Facebook" />
                          <div className="social-login-text">Login or Sign Up with Facebook</div>
                        </a>
                        {/* <a href="https://app.xpets.com/action/social/login/twitter" className="btn btn-block facebooklogin">
                          <img src={Twitter} alt="Twitter" />
                          <div className="social-login-text">Login or Sign Up with X</div>
                        </a> */}
                        <a href="https://app.xpets.com/action/social/login/google" className="btn btn-block facebooklogin">
                          <img src={Google} alt="Google" />
                          <div className="social-login-text">Login or Sign Up with Google</div>
                        </a>
                      </div>
                      <div>
                        <img className="divider-login" src={Divider} alt="Divider" />
                        <div className="login-box-form">
                          <label>Username/Email/Mobile</label>
                          <div className="form-group ossn-form-group">
                            <input
                              type="text"
                              name="username"
                              value={email}
                              // placeholder="Enter your email or username"
                              onChange={(e) => setEmail(e.target.value)}
                            />
                            {errors.email && <div className="error">{errors.email}</div>}
                          </div>
                          <label className="custom-label">Password</label>
                          <Link className="loginlink" style={{ float: "right", marginBottom: "6px" }} to="/resetlogin">
                            Reset Password
                          </Link>
                          <div className="form-group ossn-form-group">
                            <input
                              type="password"
                              name="password"
                              value={password}
                              // placeholder="Enter your password"
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            {errors.password && <div className="error">{errors.password}</div>}
                          </div>
                          <div style={{ marginBottom: "39px" }}>
                            <button type="button" style={{ width: "100%", padding: '13px' }} className=" btn btn-primary" onClick={loginAuth}>
                              Login
                            </button>
                            <div style={{ marginTop: "10px", textAlign: "center" }}>
                              <span> Don’t have an account? </span>
                              <Link to="/signup" className="loginlink">
                                Register now
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ToastContainer />
                    </div>
                  </div>

                </div>
              </div>
              <footer>
                <div class="footer-contents">
                  <div class="ossn-footer-menu">
                    <a class="menu-footer-a_copyrights" href="/">
                      © COPYRIGHT xpets
                    </a>
                    <a class="menu-footer-about" href="https://www.xpets.com/work/emerge-f42y8">
                      About
                    </a>
                    <a class="menu-footer-site" href="https://xpets.com/terms-of-service">
                      Terms and Conditions
                    </a>
                    <a class="menu-footer-privacy" href="https://www.xpets.com/privacy-policy">
                      Privacy
                    </a>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logins;
