import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import NewsfeedRight from "../birthDayCoponent/newsfeedright";
import CommentSection from "../common/commentsSection";
import API from "../../utils";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";

const PhotosDetails = () => {
  const [album, setAlbum] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [albumName, setAlbumName] = useState("");
  const [privacy, setPrivacy] = useState("");
  const [loading, setLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false); // Modal visibility state
  const { BASE_URL, ENDPOINTS } = API;
  const { albumId, photoId } = useParams();
  const navigate = useNavigate();

  useEffect(
    () => {
      fetchAlbum();
    },
    [BASE_URL, ENDPOINTS, albumId, photoId]
  );

  const fetchAlbum = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}${ENDPOINTS.singlealbum}/${albumId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      );

      if (response.data.status) {
        const albumData = response.data.payload;
        setAlbum(albumData);
        setAlbumName(albumData.album_name);
        setPrivacy(albumData.status);

        const selectedPhoto = albumData.photos.find(
          p => p.id === parseInt(photoId)
        );
        setPhoto(selectedPhoto || null);
      } else {
        console.error("Failed to fetch album details.");
      }
    } catch (error) {
      console.error("Error fetching album details.", error);
    } finally {
      setLoading(false);
    }
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDelete = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${BASE_URL}${ENDPOINTS.removephoto}/${photoId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.data.status) {
        toast.success("Album deleted successfully.");
        setShowDeleteModal(false);
        navigate(`/album/view/${albumId}`);
      } else {
        toast.error("Failed to delete album.");
      }
    } catch (error) {
      console.error("Error deleting album:", error);
    }
  };
  
  return (
    <Fragment>
      <div className="mainDiv">
        <div className="main-content-container">
          <div className="ossn-system-messages">
            <div className="row">
              <div className="col-md-11">
                <div className="ossn-system-messages-inner" />
              </div>
            </div>
          </div>
          <div className="ossn-layout-newsfeed d-lg-flex">
            <div className="newsfeed-container">
              <div className="newsfeed-middle">
                {loading
                  ? <p>Loading album details...</p>
                  : <div>
                      <div className="ossn-layout-module m-0">
                        <div className="module-title d-flex justify-content-between">
                          <div className="title">
                            {albumName}
                          </div>
                          <div className="controls">
                            <a
                              className="button-grey-photod bg-danger ossn-make-sure w-100 p-2"
                              href="javascript:void(0);"
                              onClick={openDeleteModal} // Open the delete modal
                            >
                              Delete
                            </a>
                            <a
                              className="button-grey photos-back"
                              href="/users/userEdit?tab=photos"
                            >
                              Back
                            </a>
                          </div>
                        </div>
                        <div className="module-contents">
                          <div className="photos-details">
                            <div className="photo-display">
                              {photo
                                ? <img
                                    src={photo.image}
                                    alt={`Photo ${photo.id}`}
                                    className="pthumb"
                                  />
                                : <p>
                                    No photo available for the selected ID.
                                  </p>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>}
              </div>
            </div>
            <div className="newsfeed-right">
              <NewsfeedRight />
            </div>
          </div>
        </div>
      </div>

      {/* Modal for delete confirmation */}
      <Modal show={showDeleteModal} onHide={closeDeleteModal}>
        <Modal.Header closeButton className="p-0">
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this photo?</Modal.Body>
        <Modal.Footer className="p-0">
          <button
            type="button"
            class="btn btn-default  ms-2"
            onClick={closeDeleteModal}
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary ms-2"
            onClick={handleDelete}
          >
            Yes, Delete
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default PhotosDetails;
