import React, { useEffect, useState } from "react";
import Stories from "react-insta-stories";
import API from "../../../utils";
import axios from "axios";

const StoryViewer = ({ stories, onClose }) => {
  return (
    <>
      <div
        className="ossn-halt ossn-light"
        style={{ height: "100%", position: "fixed", display: "block" }}
        onClick={onClose} // Close viewer when clicking outside
      ></div>
      <div className="ossn-message-box-sorty" style={{ display: "block" }}>
        <button
          onClick={onClose}
          className="close-button"
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            zIndex: 1000,
            background: "red",
            color: "white",
            border: "none",
            borderRadius: "50%",
            width: "30px",
            height: "30px",
            cursor: "pointer",
          }}
        >
          &times;
        </button>
        <div className="story-viewer">
          <Stories
            loop
            keyboardNavigation
            defaultInterval={3000}
            stories={stories.map(story => ({
              url: story.imageLinks[0] || '', // Set the first image in the story
              header: {
                heading: story.postedBy?.name,
                subheading: `${new Date(story.createdAt).toLocaleString()}`,
                profileImage: story.postedBy?.profileImage,
              },
            }))}
            onStoryEnd={(s, st) => console.log("Story ended", s, st)}
            onAllStoriesEnd={(s, st) => console.log("All stories ended", s, st)}
            onStoryStart={(s, st) => console.log("Story started", s, st)}
          />
        </div>
      </div>
    </>
  );
};

const UserCustomCalendar = () => {
  const token = localStorage.getItem("token");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [isViewerOpen, setViewerOpen] = useState(false);
  const [profileImg, setProfileImg] = useState(null);
  const [stories, setStories] = useState([]);
  const [storiesByDate, setStoriesByDate] = useState({});

  const daysInMonth = (month, year) => new Date(year, month + 1, 0).getDate();

  const formatDateKey = (date) => date?.toISOString().split("T")[0];

  const handleDateClick = (date) => {
    const dateKey = formatDateKey(date);
    if (storiesByDate[dateKey]) {
      setSelectedDate(date);
      setViewerOpen(true); // Open the story viewer
    } else {
      setSelectedDate(date);
    }
  };

  const handleCloseViewer = () => {
    setViewerOpen(false);
  };

  useEffect(() => {
    const fetchStoriesAndProfile = async () => {
      try {
        // Fetch stories
        const storiesResponse = await axios.get(
          `${API.BASE_URL}${API.ENDPOINTS.posts}?type=${2}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        const formattedStories = storiesResponse.data.payload.map((story) => ({
          id: story.id,
          title: story.title,
          description: story.description,
          type: story.type,
          imageLinks: story.image_links || [],
          postedBy: story.posted_user
            ? {
                name: story.posted_user.name,
                email: story.posted_user.email,
                petName: story.posted_user.pet_name,
                profileImage: story.posted_user.profile_img,
              }
            : null,
          createdAt: new Date(story.created_at), // Ensure correct date parsing
          likeCommentCount: story.like_comment_count,
        }));

        setStories(formattedStories);

        // Organize stories by date
        const storiesByDate = {};
        formattedStories.forEach((story) => {
          const dateKey = formatDateKey(story.createdAt);
          if (!storiesByDate[dateKey]) {
            storiesByDate[dateKey] = [];
          }
          storiesByDate[dateKey].push(story);
        });

        setStoriesByDate(storiesByDate);

        // Fetch profile data
        const profileResponse = await axios.get(
          `${API.BASE_URL}${API.ENDPOINTS.profile}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        const profileData = profileResponse.data?.payload;
        setProfileImg(profileData?.profile_img || "default-avatar-url.jpg");
      } catch (error) {
        console.error("Error fetching stories or profile:", error);
      }
    };

    fetchStoriesAndProfile();
  }, []);

  const renderCalendar = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDayOfMonth = new Date(year, month, 1).getDay();
    const totalDays = daysInMonth(month, year);

    const calendarDays = [];
    for (let i = 0; i < firstDayOfMonth; i++) {
      calendarDays.push(<div key={`empty-${i}`} className="day empty"></div>);
    }

    for (let day = 1; day <= totalDays; day++) {
      const currentDateKey = formatDateKey(new Date(year, month, day));
      calendarDays.push(
        <div
          key={day}
          className={`day ${storiesByDate[currentDateKey] ? "has-story" : ""} ${
            selectedDate?.getDate() === day &&
            selectedDate.getMonth() === month &&
            selectedDate.getFullYear() === year
              ? "selected"
              : ""
          }`}
          onClick={() => handleDateClick(new Date(year, month, day))}
        >
          <div className="inner-div-date">{day}</div>
        </div>
      );
    }

    return calendarDays;
  };

  const changeMonth = (direction) => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + direction, 1)
    );
  };

console.log("storiesByDate", storiesByDate)

  return (
    <div className="calendar-section">
      <div className="custom-calendar">
        <div className="calendar-header">
          <button onClick={() => changeMonth(-1)}>&lt;</button>
          <span>
            {currentDate.toLocaleString("default", { month: "long" })}{" "}
            {currentDate.getFullYear()}
          </span>
          <button onClick={() => changeMonth(1)}>&gt;</button>
        </div>
        <div className="calendar-grid">
          <div className="day-label">Sun</div>
          <div className="day-label">Mon</div>
          <div className="day-label">Tue</div>
          <div className="day-label">Wed</div>
          <div className="day-label">Thu</div>
          <div className="day-label">Fri</div>
          <div className="day-label">Sat</div>
          {renderCalendar()}
        </div>
      </div>
      {isViewerOpen && selectedDate && (
        <StoryViewer
          stories={storiesByDate[formatDateKey(selectedDate)]}
          onClose={handleCloseViewer}
        />
      )}
    </div>
  );
};

export default UserCustomCalendar;
