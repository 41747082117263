import React, { Fragment, useState } from "react";

import NewsfeedRight from "../birthDayCoponent/newsfeedright";
import Video from "./video";

const VideoDetails = () => {

  const [posts, setPosts] = useState([
    {
      postId: 1,
      userData: {
        avatarUrl: "https://app.xpets.com/avatar/chinnumvijayan/small/8638f832ab254412bd3cee8c15614e03.jpeg",
        username: "chinnumvijayan",
        name: "Promy",
      },
      postContent: "This is the first post.",
    },
    
  ]);

  const [newPostContent, setNewPostContent] = useState("");

  const handleAddPost = () => {
    if (newPostContent.trim()) {
      const newPost = {
        postId: posts.length + 1,
        userData: {
          avatarUrl: "https://app.xpets.com/avatar/default-avatar.jpg", 
          username: "newuser",
          name: "New User",
        },
        postContent: newPostContent,
      };
      setPosts([newPost, ...posts]);
      setNewPostContent("");
    }
  };

  return (
    <Fragment>
      <div className="mainDiv">
        <div className="main-content-container">
          <div className="ossn-system-messages">
            <div className="row">
              <div className="col-md-11">
                <div className="ossn-system-messages-inner"></div>
              </div>
            </div>
          </div>
          <div className="ossn-layout-newsfeed d-lg-flex">
          <div className="newsfeed-container">
              <div className="newsfeed-middle">
               
                {/* <PostForm /> */}
                {posts.map((post) => (
                  <Video
                    key={post.postId}
                    postId={post.postId}
                    userData={post.userData}
                    postContent={post.initialPostContent }
                  />
                ))}
              </div>
            </div>
            <div className="newsfeed-right">
              <NewsfeedRight />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default VideoDetails;
