// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { postData } from "../../../utils/axiosApiReq";
// import { selectUserId } from "../user/userSlice";
// import { toast } from "react-toastify";
// import API from "../../../utils";
// const { ENDPOINTS } = API;


// // Async function to fetch friends and groups from an API
// export const fetchGroupsAndFriends = createAsyncThunk("chat/fetchGroupsAndFriends", async () => {
//   const response = await postData(ENDPOINTS.chatGroupUserList);
//   return response;
// });

// // Async function to fetch chat messages
// export const getMessages = createAsyncThunk("chat/getMessages", async ({ receiverId, type }, { getState }) => {
//   const state = getState();
//   const senderId = selectUserId(state); // Get userId from userSlice
//   if (!senderId) {
//     throw new Error("User ID not available");
//   }
//   const response = await postData(ENDPOINTS.getChatMessages, { sender_id: senderId, receiver_id: receiverId, type });
//   return response.payload; // Assuming the API returns a payload with the messages
// });

// export const sendMessage = createAsyncThunk("chat/sendMessage", async ({ message, receiver_id, type, files }, { getState, rejectWithValue  }) => {
//   try {
//     const state = getState();
//     const senderId = selectUserId(state); // Get userId from userSlice

//     if (!senderId) {
//       throw new Error("User ID not available");
//     }

//     const response = await postData(ENDPOINTS.sendMessage, { sender_id: senderId, receiver_id, message, type, files }, true);

//     if (!response.status) {
//       toast.error(response?.message);
//       return rejectWithValue(response);
//     }

//     return response.payload;
//   } catch (error) {
//     toast.error(error.response?.data?.message || "An unexpected error occurred. Please try again.");
//     return rejectWithValue(error.response?.data || { message: "An unexpected error occurred. Please try again.", code: 500 });
//   }

//   // const state = getState();
//   // const senderId = selectUserId(state); // Get userId from userSlice
//   // if (!senderId) {
//   //   throw new Error("User ID not available");
//   // }
//   // const response = await postData(ENDPOINTS.sendMessage, { sender_id: senderId, receiver_id, message, type, files }, true);
//   // return response.payload;
// });

// export const chatSlice = createSlice({
//   name: "chat",
//   initialState: {
//     groups: [],
//     friends: [],
//     selectedChat: null, // currently opened friend or group chat
//     chatType: null,
//     loading: false,
//     error: null,
//     // Get Messages
//     messages: [], // Holds messages for the selected chat
//     loadingMessages: false, // Loading state for messages
//     errorMessages: null, // Error state for messages
//     // Send Message
//     sendingMessage: false, // Loading state for sending message
//     errorSendingMessage: null, // Error state for sending message
//     sentMessage: null, // Holds the most recently sent message
//   },
//   reducers: {
//     selectChat: (state, action) => {
//       state.selectedChat = action.payload;
//     },
//     resetSentMessage: (state) => {
//       state.sentMessage = null; // Reset the sent message state
//     },
//     selectChatType: (state, action) => {
//       state.chatType = action.payload;
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       // Handle groups and friends fetch
//       .addCase(fetchGroupsAndFriends.pending, (state) => {
//         state.loading = true;
//       })
//       .addCase(fetchGroupsAndFriends.fulfilled, (state, action) => {
//         state.loading = false;
//         state.groups = action.payload.payload.group || [];
//         state.friends = action.payload.payload.user || [];
//       })
//       .addCase(fetchGroupsAndFriends.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload.message;
//       })
//       // Handle fetching messages
//       .addCase(getMessages.pending, (state) => {
//         state.loadingMessages = true;
//       })
//       .addCase(getMessages.fulfilled, (state, action) => {
//         state.loadingMessages = false;
//         state.messages = action.payload.reverse() || [];
//       })
//       .addCase(getMessages.rejected, (state, action) => {
//         state.loadingMessages = false;
//         state.errorMessages = action.payload.message;
//       })
//       // Handle sending message
//       .addCase(sendMessage.pending, (state) => {
//         state.sendingMessage = true;
//         state.errorSendingMessage = null; // Reset error when a new message is being sent
//       })
//       .addCase(sendMessage.fulfilled, (state, action) => {
//         state.sendingMessage = false;
//         state.sentMessage = action.payload; // Store the sent message
//         state.messages.push(action.payload); // Add the sent message to the messages list
//       })
//       .addCase(sendMessage.rejected, (state, action) => {
//         state.sendingMessage = false;
//         state.errorSendingMessage = action.data?.message || "Failed to send message.";
//         // state.errorSendingMessage = action.error.message;
//       });
//   },
// });

// export const { selectChat, selectChatType, resetSentMessage } = chatSlice.actions;

// export default chatSlice.reducer;


import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postData } from "../../../utils/axiosApiReq";
import { selectUserId } from "../user/userSlice";
import { toast } from "react-toastify";
import API from "../../../utils";
const { ENDPOINTS } = API;

// Async function to fetch friends and groups from an API
export const fetchGroupsAndFriends = createAsyncThunk("chat/fetchGroupsAndFriends", async () => {
  const response = await postData(ENDPOINTS.chatGroupUserList);
  return response;
});

// Async function to fetch chat messages
export const getMessages = createAsyncThunk("chat/getMessages", async ({ receiverId, type }, { getState }) => {
  const state = getState();
  const senderId = selectUserId(state); // Get userId from userSlice
  if (!senderId) {
    throw new Error("User ID not available");
  }
  const response = await postData(ENDPOINTS.getChatMessages, { sender_id: senderId, receiver_id: receiverId, type });
  return response.payload; // Assuming the API returns a payload with the messages
});

export const sendMessage = createAsyncThunk("chat/sendMessage", async ({ message, receiver_id, type, files }, { getState, rejectWithValue }) => {
  try {
    const state = getState();
    const senderId = selectUserId(state); // Get userId from userSlice

    if (!senderId) {
      throw new Error("User ID not available");
    }

    const response = await postData(ENDPOINTS.sendMessage, { sender_id: senderId, receiver_id, message, type, files }, true);

    if (!response.status) {
      toast.error(response?.message);
      return rejectWithValue(response);
    }

    return response.payload;
  } catch (error) {
    toast.error(error.response?.data?.message || "An unexpected error occurred. Please try again.");
    return rejectWithValue(error.response?.data || { message: "An unexpected error occurred. Please try again.", code: 500 });
  }
});

export const chatSlice = createSlice({
  name: "chat",
  initialState: {
    groups: [],
    friends: [],
    selectedChat: null, // currently opened friend or group chat
    chatType: null,
    loading: false,
    error: null,
    // Get Messages
    messages: [], // Holds messages for the selected chat
    loadingMessages: false, // Loading state for messages
    errorMessages: null, // Error state for messages
    // Send Message
    sendingMessage: false, // Loading state for sending message
    errorSendingMessage: null, // Error state for sending message
    sentMessage: null, // Holds the most recently sent message
  },
  reducers: {
    selectChat: (state, action) => {
      state.selectedChat = action.payload;
    },
    resetSentMessage: (state) => {
      state.sentMessage = null; // Reset the sent message state
    },
    selectChatType: (state, action) => {
      state.chatType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle groups and friends fetch
      .addCase(fetchGroupsAndFriends.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGroupsAndFriends.fulfilled, (state, action) => {
        state.loading = false;
        state.groups = action.payload.payload.group || [];
        state.friends = action.payload.payload.user || [];
      })
      .addCase(fetchGroupsAndFriends.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "An error occurred while fetching groups and friends.";
      })
      // Handle fetching messages
      .addCase(getMessages.pending, (state) => {
        state.loadingMessages = true;
      })
      .addCase(getMessages.fulfilled, (state, action) => {
        state.loadingMessages = false;
        state.messages = action.payload.reverse() || [];
      })
      .addCase(getMessages.rejected, (state, action) => {
        state.loadingMessages = false;
        state.errorMessages = action.payload?.message || "An error occurred while fetching messages.";
      })
      // Handle sending message
      .addCase(sendMessage.pending, (state) => {
        state.sendingMessage = true;
        state.errorSendingMessage = null; // Reset error when a new message is being sent
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.sendingMessage = false;
        state.sentMessage = action.payload; // Store the sent message
        state.messages.push(action.payload); // Add the sent message to the messages list
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.sendingMessage = false;
        state.errorSendingMessage = action.payload?.message || "Failed to send message.";
      });
  },
});

export const { selectChat, selectChatType, resetSentMessage } = chatSlice.actions;

export default chatSlice.reducer;
  