import { createSlice } from '@reduxjs/toolkit';

export const themeCustomizerSlice = createSlice({
  name: 'themeCustomizer',
  initialState: {
    selectedTheme: 'light', // default theme
    recentFriends: [], // friends who recently sent messages
  },
  reducers: {
    setTheme: (state, action) => {
      state.selectedTheme = action.payload;
    },
    setRecentFriends: (state, action) => {
      state.recentFriends = action.payload;
    },
  },
});

export const { setTheme, setRecentFriends } = themeCustomizerSlice.actions;

export default themeCustomizerSlice.reducer;
