import React from 'react';
import { ChatProvider } from './ChatContext';
import ChatUI from './index';

const Chat = () => {
  return (
    <ChatProvider>
      <ChatUI/>
    </ChatProvider>
  )
}

export default Chat