import React, { memo } from "react";

const DeleteModal = memo(({closeDeleteModal}) => {
    console.log('DeleteModal rendered'); // Debug render behavior
  return (
    <>
    <div className="ossn-halt ossn-light" style={{ height: '10357px', position: 'fixed', display: 'block' }}></div>
      <div className="ossn-message-box" id="chatDeleteModal" style={{ display: "block" }}>
        <div className="title">
          Delete
          <div className="close-box" onClick={closeDeleteModal}>
            X
          </div>
        </div>
        <div className="contents">
          <div className="ossn-box-inner">
            <div style={{ width: "100%", margin: "auto" }}>
              <form action="https://app.xpets.com/action/message/delete" id="ossn-message-delete-form" className="ossn-form" method="post" encType="multipart/form-data">
                <fieldset>
                  <input type="hidden" name="ossn_ts" value="1732776648" />
                  <input type="hidden" name="ossn_token" value="585b6da6225d344456cd4815d48dd64901e7ba4d40c9f1d0e26ef3d459faacb6" />
                  <div className="ossn-privacy">
                    <div className="radio-block">
                      <input className="ossn-radio-input" type="radio" name="type" value="all" />
                      <span>Remove for everyone (You'll permanently remove this message for other member. Other member will be able to see that you've removed a message.)</span>
                    </div>
                    <div className="radio-block">
                      <input className="ossn-radio-input" type="radio" name="type" value="me" defaultChecked />
                      <span>Remove for you (This message will be removed for you. Other member will still be able to see it.)</span>
                    </div>
                    <input type="hidden" name="id" value="24" />
                    <input type="submit" className="hidden" value="Save" id="ossn-md-edit-save" />
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
        <div className="control">
          <div className="controls">
            <a href="javascript:void(0);" onClick={() => console.log("Form submitted")} className="btn btn-primary btn-sm">
              {/* onClick={() => Ossn.Clk("#ossn-md-edit-save")} */}
              Delete
            </a>
            <a href="javascript:void(0);" onClick={closeDeleteModal} className="btn btn-default btn-sm">
              Cancel
            </a>
          </div>
        </div>
      </div>
    </>
  );
});

export default DeleteModal;
