import React, { useState, Fragment, useEffect, useLayoutEffect } from 'react';
import logo from '../../../assets/images/logo/x.png';
import UserMenu from './userMenu';
import Notification from './notification';
import SearchHeader from './searchHeader';
import { Link } from 'react-router-dom';
import { MoreHorizontal } from 'react-feather';
import { MdFormatAlignLeft } from 'react-icons/md';
import Notificationimg from "../../../assets/images/sidebar/notification.png";
import Messagesimg from "../../../assets/images/sidebar/envelope.png";
import Friendsimg from "../../../assets/images/sidebar/friends.png";

import Messages from './messages';
import Friends from './friends';
import axios from 'axios';
import API from '../../../utils';
import { toast } from 'react-toastify';

const Header = () => {
  const [sidebar, setSidebar] = useState(false);
  const [headerbar, setHeaderbar] = useState(true);
  const [notificationData, setNotificationData] = useState([]);
  const [count, setCount] = useState("");

  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth <= 991) {
        setSidebar(false);
        document.querySelector(".page-main-header").classList.add('open');
        document.querySelector(".page-sidebar").classList.add('open');
      } else {
        setSidebar(true);
        document.querySelector(".page-main-header").classList.remove('open');
        document.querySelector(".page-sidebar").classList.remove('open');
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 991) {
      setSidebar(false);
      document.querySelector(".page-main-header").classList.add('open');
      document.querySelector(".page-sidebar").classList.add('open');
    } else {
      setSidebar(true);
      document.querySelector(".page-main-header").classList.remove('open');
      document.querySelector(".page-sidebar").classList.remove('open');
    }
    fetchNotificationCount();
    fetchNotificationData();
  }, []);

  function openCloseSidebar() {
    if (sidebar) {
      setSidebar(!sidebar);
      document.querySelector(".page-main-header").classList.remove('open');
      document.querySelector(".page-sidebar").classList.remove('open');
    } else {
      setSidebar(!sidebar);
      document.querySelector(".page-main-header").classList.add('open');
      document.querySelector(".page-sidebar").classList.add('open');
    }
  }
  // /notification/all
  const fetchNotificationData = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(
        `${API.BASE_URL}${API.ENDPOINTS.notifications}?is_new=1`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.data.success === true) {
        const parsedData = response.data.data.map(notification => {
          const { title, message, sender, receiver, id: notificationId, content_type } = JSON.parse(notification.comment);
          return {
            ...notification,
            title,
            message,
            sender,
            receiver,
            notificationId,
            contentType: content_type,
          };
        });
        setNotificationData(parsedData);
        
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching notification data:", error);
    }
  };

  const fetchNotificationCount = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(
        `${API.BASE_URL}${API.ENDPOINTS.unreadNotificationCount}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.data.success === true) {
        setCount(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching notification count:", error);
    }
  };

  return (
    <Fragment>
      <div className="page-main-header">
        <div className="main-header-right row">
          <div className="mobile-sidebar d-block col-auto">
            <div className="flex-grow-1 text-end switch-sm mobile-tablet-only">
              <label className="switch">
                <a href="#" onClick={() => openCloseSidebar()}>
                  <MdFormatAlignLeft className='header-alignleft' style={{ color: '#0f3b4a', fontSize: '24px' }} />
                </a>
              </label>
            </div>
          </div>
          <div className="main-header-left d-lg-none col-auto">
            <div className="logo-wrapper">
              <Link className='logo-container' to={`/home`}>
                <img id="logo-icon" className="img-fluid" src={logo} alt="" />
              </Link>
            </div>
          </div>
          <div className="nav-right col p-0">
            <ul className={`nav-menus d-flex justify-content-end ${headerbar ? '' : 'open'}`}>
              <li className='p-0'>
                {window.innerWidth > 991 && <SearchHeader />}
              </li>
              <li className="onhover-dropdown notification-hover">
                <span className="ossn-notification-container">{count}</span>
                <img
                  src={Notificationimg}
                  alt="notification-icon"
                  style={{ width: '24px', height: '24px' }}
                />
                <Notification notificationData={notificationData} />
              </li>
              <li className="onhover-dropdown messages-hover">
                {/* <Messages /> */}
                {/* <span className="ossn-notification-container">{"10"}</span> */}
                <Link to={"/messages"}>
                <img
                  src={Messagesimg}
                  alt="notification-icon"
                  style={{ width: '24px', height: '24px' }}
                />
                </Link>
                {/* <Messages /> */}
              </li>
              <li className="onhover-dropdown messages-hover">
                {/* <Friends /> */}
                {/* <span className="ossn-notification-container">{"12"}</span> */}
                <Link to={"/friends"}>
                <img
                  src={Friendsimg}
                  alt="notification-icon"
                  style={{ width: '24px', height: '24px' }}
                />
               
                {/* <Friends /> */}
                </Link>
              </li>
              <UserMenu />
            </ul>
            <div className="d-lg-none mobile-toggle pull-right" onClick={() => setHeaderbar(!headerbar)}>
              <MoreHorizontal style={{ color: '#0f3b4a' }} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Header;
