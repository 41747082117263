import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import API from "../../utils";
import Stories from "react-insta-stories";
import { toast } from "react-toastify";
import defalutimages from "../../assets/images/user/user_default.jpg";
import { FiSend } from "react-icons/fi";

const StoryUploader = ({
  addStory,
  openStoryViewer,
  profileImg,
  postedUser,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [loading, setLoading] = useState(false);

  const fileInputRef = useRef();

  const handleFileUpload = (e) => {
    const selectedFile = e.target.files[0];
    const validTypes = ["image/jpeg", "image/png", "video/mp4", "video/mov"];
    const maxSize = 15 * 1024 * 1024; // 10MB
    if (!validTypes.includes(selectedFile.type)) {
      toast.error("Invalid file type");
      return;
    }
    if (selectedFile.size > maxSize) {
      toast.error("File size exceeds 15MB limit.");
      return;
    }
    setFile(selectedFile);
    setImagePreview(URL.createObjectURL(selectedFile));
  };

  const handleUploadSubmit = async () => {
    setLoading(true);

    const formData = new FormData();
    formData.append("title", "story");
    formData.append("content_type", 2);
    formData.append("type", 2);
    formData.append("files[0]", file);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.posts}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.status === true) {
        const newStory = {
          id: response.data.payload.id,
          title: response.data.payload.title,
          imageLinks: response.data.payload.image_links || [],
          videoLinks: response.data.payload.video_links || [],
          postedBy: response.data.payload.posted_user,
          createdAt: response.data.payload.created_at,
        };

        addStory(newStory);
        setModalOpen(false);
        setTitle("");
        setFile(null);
        setImagePreview("");
        fileInputRef.current.value = "";
      }

    } catch (error) {
      console.error("Error uploading story:", error);
      toast.error("Error uploading story, please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="story-uploader flex justify-center items-center">
      <div className="category-inner-circle flex justify-center items-center">
        <img
          src={profileImg || defalutimages}
          alt="User Avatar"
          className="category-image"
          onClick={openStoryViewer}
        />
      </div>

      {postedUser?.id && (
        <div className="icon-wrapper" onClick={() => setModalOpen(true)}>
          <i className="icofont icofont-ui-add"></i>
        </div>
      )}

      {modalOpen && (
        <>
          <div className="ossn-halt ossn-light" style={{ height: '10357px', position: 'fixed', display: 'block' }}></div>
          <div className="ossn-message-box" style={{ display: 'block' }}>
            <div className="title justify-content-between d-flex">
              Add Story
              <div className="close-box" onClick={() => setModalOpen(false)}>X</div>
            </div>
            <hr />
            <div className="contents">
              <div className="ossn-box-inner">
                <form className="ossn-form">
                  <fieldset>
                    <input
                      type="file"
                      className="mb-2"
                      ref={fileInputRef}
                      onChange={handleFileUpload}
                    />
                    {imagePreview && (
                      <div className="image-preview-container d-flex">
                        <div className="image-preview ms-2">
                          <img
                            src={imagePreview}
                            style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                            alt="Preview"
                          />
                        </div>
                      </div>
                    )}
                  </fieldset>
                </form>
              </div>
            </div>
            <div className="control">
              <button className="btn btn-primary btn-sm m-0" onClick={handleUploadSubmit} disabled={loading}>
                {loading ? "Uploading..." : "Submit"}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const StoryViewer = ({ stories, onClose, userId, fetchStoriesAndProfile }) => {
  const uid = localStorage.getItem("user_id"); // Logged-in user ID
  const token = localStorage.getItem("token");

  const [likes, setLikes] = useState({});
  const [comments, setComments] = useState({});
  const [newComment, setNewComment] = useState("");
  const [commentInputVisible, setCommentInputVisible] = useState(null);
  const [likedStories, setLikedStories] = useState({});

  useEffect(() => {
    const initialLikes = {};
    const initialComments = {};
    const initialLikedStories = {};
    stories.forEach((story) => {
      initialLikes[story.id] = story.likes || 0;
      initialComments[story.id] = story.comments || [];
      initialLikedStories[story.id] = story.is_liked_by_user || false; // Assuming the API returns a `is_liked_by_user` field
    });
    setLikes(initialLikes);
    setComments(initialComments);
    setLikedStories(initialLikedStories);
  }, [stories]);

  // Handle like count
  const likeReel = async (storyId) => {
    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.likescomments}`,
        { post_id: storyId, type: "like" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.status) {
        fetchStoriesAndProfile();
      }
    } catch (error) {
      console.error("Error liking the reel", error);
    }
  };

  // Handle unlike count
  const unlikeReel = async (is_liked_by_user) => {
    try {
      const response = await axios.delete(
        `${API.BASE_URL}${API.ENDPOINTS.likescomments}/${is_liked_by_user}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.status) {
        fetchStoriesAndProfile();
      }
    } catch (error) {
      console.error("Error unliking the reel", error);
    }
  };

  const handleSendMessage = async (story) => {
    if (!newComment.trim()) return;

    const formData = new FormData();
    formData.append("type", 1);
    formData.append("message", newComment);
    formData.append("sender_id", uid); // Logged-in user's ID
    formData.append("receiver_id", userId); // Story uploader's ID

    try {
      await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.sendMessage}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setComments((prev) => ({
        ...prev,
        [story.id]: [...prev[story.id], { text: newComment, id: Date.now() }],
      }));
      setNewComment("");
      setCommentInputVisible(null); // Close the comment input field
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const buildStoryContent = (story) => {
    if (story.videoLinks && story.videoLinks.length > 0) {
      // Handle video stories
      return {
        url: story.videoLinks[0],
        type: "video", // Indicate that this is a video story
        header: {
          heading: story.postedBy?.pet_name,
          subheading: new Date(story.createdAt).toLocaleString(),
          profileImage: story.postedBy?.profile_img,
        },
      };
    }

    // Handle image stories
    return {
      url: story.imageLinks[0] || "",
      header: {
        heading: story.postedBy?.pet_name,
        subheading: new Date(story.createdAt).toLocaleString(),
        profileImage: story.postedBy?.profile_img,
      },
    };
  };

  return (
    <>
      <div
        className="ossn-halt ossn-light"
        style={{
          height: "100%",
          position: "fixed",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
        onClick={onClose}
      ></div>
      <div className="ossn-message-box-sorty" style={{ display: "block" }}>
        <button
          onClick={onClose}
          className="close-button"
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            zIndex: 1000,
            background: "red",
            color: "white",
            border: "none",
            borderRadius: "50%",
            width: "30px",
            height: "30px",
            cursor: "pointer",
          }}
        >
          &times;
        </button>
        <div className="story-viewer" style={{ height: "100vh" }}>
          {stories.map((story) => (
            <div key={story.id} className="story">
              <Stories
                loop
                keyboardNavigation
                defaultInterval={3000}
                stories={[buildStoryContent(story)]}
              />
              <div className="story-actions">
                <div className="likes-comments-container justify-content-end d-grid gap-3">
                  <span
                    className="like-button"
                    style={{ cursor: 'pointer' }}

                    onClick={() =>
                      story.is_liked_by_user
                        ? unlikeReel(story.is_liked_by_user) // Pass the unique like ID
                        : likeReel(story.id) // Pass the reel ID
                    }
                  >
                    <svg
                      width="48"
                      height="40"
                      viewBox="0 0 24 24"
                      fill={story.is_liked_by_user ? "red" : "white"}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
                    </svg>
                  </span>

                  <span
                    style={{ cursor: 'pointer' }}
                    className="comment-button d-block"
                    onClick={() => setCommentInputVisible((prev) => (prev === story.id ? null : story.id))}
                  >
                    <svg
                      width="48"
                      height="40"
                      viewBox="0 0 24 24"
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M20 2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4V4c0-1.1-.9-2-2-2zm0 14H4V4h16v12z" />
                    </svg>
                  </span>
                  
                </div>
                {commentInputVisible === story.id && (
                  <div className="comment-input-container ">
                    <input
                      type="text"
                      value={newComment}
                      onChange={(e) => setNewComment(e.target.value)}
                      placeholder="Write a comment..."
                      className="form-control me-2"
                    />
                    <button
                      className="btn btn-primary m-0"
                      onClick={() => handleSendMessage(story)}
                      disabled={!newComment.trim()}
                    >
                      <FiSend />
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};


const StoryApp = ({ fetchStoriesAndProfile }) => {
  const [storiesByUser, setStoriesByUser] = useState({});
  const [isViewerOpen, setViewerOpen] = useState(null);
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("user_id"); // Logged-in user ID
  const [profileImg, setProfileImg] = useState(null);
  const [postedUser, setPostedUser] = useState(null);

  useEffect(() => {
    const fetchStoriesAndProfile = async () => {
      try {
        const response = await axios.get(
          `${API.BASE_URL}${API.ENDPOINTS.posts}?type=${2}&force_story=${1}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        const stories = response.data.payload || [];

        // Group stories by posted_user.id
        const groupedStories = stories.reduce((acc, story) => {
          const userId = story.posted_user.id;
          if (!acc[userId]) {
            acc[userId] = {
              user: story.posted_user,
              stories: [],
            };
          }
          acc[userId].stories.push({
            id: story.id,
            title: story.title,
            imageLinks: story.image_links || [],
            videoLinks: story.video_links || [],
            createdAt: story.created_at,
            postedBy: story.posted_user, // Include postedBy information
            is_liked_by_user: story.is_liked_by_user || false, // Include is_liked_by_user information
          });
          return acc;
        }, {});

        setStoriesByUser(groupedStories);

        // Fetch profile data
        const profileResponse = await axios.get(
          `${API.BASE_URL}${API.ENDPOINTS.profile}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        const profileData = profileResponse.data?.payload;
        setProfileImg(profileData?.profile_img || "default-avatar-url.jpg");
        setPostedUser(profileData);
      } catch (error) {
        console.error("Error fetching stories or profile:", error);
      }
    };

    fetchStoriesAndProfile();
  }, [token]);

  const handleStoryUploaded = (newStory) => {
    const userId = newStory.postedBy.id;
    setStoriesByUser((prev) => ({
      ...prev,
      [userId]: {
        user: newStory.postedBy,
        stories: [newStory, ...(prev[userId]?.stories || [])],
      },
    }));
  };

  const openStoryViewer = (userId) => {
    setViewerOpen(userId);
  };

  const handleCloseViewer = () => {
    setViewerOpen(null);
  };

  return (
    <div className="mainDiv p-3">
      <div className="story-section d-flex">
        <StoryUploader
          profileImg={profileImg}
          addStory={handleStoryUploaded}
          openStoryViewer={() => openStoryViewer(postedUser?.id)}
          postedUser={postedUser}
        />

        <div className="all-users-stories d-flex">
          {Object.entries(storiesByUser)
            .filter(([userId]) => parseInt(userId) !== parseInt(uid)) // Exclude logged-in user's stories
            .map(([userId, { user, stories }]) => (
              <div key={userId} className="user-stories">
                <div
                  className="category-outer-circle"
                  onClick={() => openStoryViewer(parseInt(userId))}
                >
                  <div className="category-inner-circle">
                    <img
                      src={user.profile_img || "default-avatar-url.jpg"}
                      alt={`${user.name}'s profile`}
                      className="category-image"
                    />
                  </div>
                </div>
                {isViewerOpen === parseInt(userId) && (
                  <StoryViewer stories={stories} onClose={handleCloseViewer} userId={userId} />
                )}
              </div>
            ))}
        </div>

        {isViewerOpen === parseInt(uid) && (
          <StoryViewer
            fetchStoriesAndProfile={fetchStoriesAndProfile}
            stories={storiesByUser[uid]?.stories || []}
            onClose={handleCloseViewer}
          />
        )}
      </div>
    </div>
  );
};

export default StoryApp;
