import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaCalendar, FaClock } from "react-icons/fa";
import AddEventModal from "./modals/addEvents";
import API from "../../../utils";
import Loader from "../../common/Loader";
import { getData } from "../../../utils/axiosApiReq";
import { toast } from "react-toastify";
import ErrorImage from "../../../assets/images/onErrImage.png";
import moment from "moment";
import plusImg from "../../../assets/images/plus.png";

const EventItem = ({ id, image, name, details, location, date, start_time, end_time, link }) => {
  const navigate = useNavigate();
  const [imgSrc, setImgSrc] = useState(image);

  const handleEventClick = () => {
    navigate(link);
  };

  return (
    <div className="row event-list-item" onClick={handleEventClick} style={{ cursor: "pointer" }}>
      <div className="col-md-4">
        <div className="image-event">
          <img src={imgSrc || ErrorImage} alt={name} onError={() => setImgSrc(ErrorImage)} />
        </div>
      </div>
      <div className="col-md-8">
        <div className="title">
          <span>{name || "Untitled Event"}</span>
        </div>
        <p>{details?.length > 200 ? `${details.substring(0, 200)}...` : details}</p>
        <div className="options">
          <div className="metadata">
            <li>
              <i className="fa fa-map-marker metadata-ion"></i>
              {location || "Location not provided"}
            </li>
            <li>
              <FaCalendar className="metadata-ion" />
              {date || "Date not available"}
            </li>
            <li>
              <FaClock className="metadata-ion" />
              {start_time || end_time ? (
                <>
                  {start_time && end_time
                    ? `${moment(start_time, "HH:mm:ss").format("hh:mm A")} - ${moment(end_time, "HH:mm:ss").format("hh:mm A")}`
                    : start_time
                    ? `${moment(start_time, "HH:mm:ss").format("hh:mm A")}`
                    : `${moment(end_time, "HH:mm:ss").format("hh:mm A")}`}
                </>
              ) : (
                "Time not available"
              )}
            </li>
          </div>
          <a style={{ float: "right", marginRight: "25px", marginTop: "15px", marginBottom: "20px" }} href={`event-details/${id}`} className="btn btn-primary">
            Browse
          </a>
        </div>
      </div>
    </div>
  );
};

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
  return (
    <ul className="pagination ossn-pagination flex-wrap">
      <li className="page-item">
        <a href="?offset=1" className="page-link ossn-pagination-page">
          First
        </a>
      </li>
      {pages.map((page) => (
        <li key={page} className={`page-item ${currentPage === page ? "active" : ""}`}>
          <a href={`?offset=${page}`} className="page-link">
            {page}
          </a>
        </li>
      ))}
      <li className="page-item">
        <a href={`?offset=${totalPages}`} className="page-link ossn-pagination-page">
          Last
        </a>
      </li>
    </ul>
  );
};

const UserEvents = () => {
  const { ENDPOINTS } = API;
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);

  const getEvents = async (offset = 0, forcereel = 1, limit = 10) => {
    try {
      const response = await getData(ENDPOINTS.getevent, { offset, limit, forcereel });
      const { status, message, payload } = response;
      if (status) {
        setEvents(payload);
      } else {
        toast.error(message || "Failed to get events");
      }
    } catch (error) {
      toast.error(error.response?.data.message);
    }
  };
  useEffect(() => {
    getEvents(0, 1, 10);
  }, []);

  const [showAddEventModal, setShowAddEventModal] = useState(false);

  const handleAddEvent = (newEvent) => {
    setEvents((prevEvents) => [...prevEvents, newEvent]);
    setShowAddEventModal(false);
  };

  const handleShowAddEventModal = () => {
    setShowAddEventModal(true);
  };

  const handleCloseAddEventModal = () => {
    setShowAddEventModal(false);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="mainDiv">
      <div className="main-content-container">
        <div className="ossn-system-messages">
          <div className="row">
            <div className="col-md-11">
              <div className="ossn-system-messages-inner"></div>
            </div>
          </div>
        </div>

        <div className="ossn-layout-newsfeed d-flex">
          <div className="newsfeed-container">
            <div className="newsfeed-middle">
              <div className="ossn-page-contents">
                <div style={{ marginBottom: "20px" }} className="d-flex justify-content-between align-items-center">
                  <div className="title">Events</div>
                  <div className="controls">
                    {/* <a id="ossn-add-album" onClick={handleShowAddEventModal}>
                      <img src={plusImg} alt="Add Event" />
                      Add Event
                    </a> */}
                  </div>
                </div>

                <div className="events list-items">
                  {events.map((event, index) => (
                    <EventItem key={index} {...event} />
                  ))}
                </div>
                {/* <div className="container-table container-table-pagination">
                  <div className="center-row">
                    <Pagination currentPage={1} totalPages={3} />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="newsfeed-footer-container">
        <footer>
          <div className="footer-contents">
            <div className="ossn-footer-menu">
              <a className="menu-footer-a_copyright">...</a>
            </div>
          </div>
        </footer>
      </div>
      <AddEventModal showModal={showAddEventModal} handleClose={handleCloseAddEventModal} handleAddEvent={handleAddEvent} getEvents={getEvents} />
    </div>
  );
};

export default UserEvents;
