import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import pawgreen from '../../../assets/icoicon/paw-green.svg';
import API from "../../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import dotsImg from "../../../assets/images/dots.png";
import blockImg from "../../../assets/images/block.png";
const Profile = () => {
  const [activeTab, setActiveTab] = useState("basic");
  const [profileData, setProfileData] = useState({
    username: "",
    email: "",
    password: "",
    new_password: "",
    name: "",
    last_name: "",
    dob: "",
    phone: "",
    pet_name: "",
    pet_age: "",
    type_of_pet: "",
    other: "",
    location: "",
    language: "",
  });
  const [relationshipFormVisible, setRelationshipFormVisible] = useState(false);
  const [familyFormVisible, setFamilyFormVisible] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const dropdownRef = useRef(null);
  const [relationshipForm, setRelationshipForm] = useState({
    type: "",
    privacy: "2",
    friendGuid: "",
    since: "",
  });
  const [familyForm, setFamilyForm] = useState({
    friendGuid: "",
    type: "",
    privacy: "2",
  });
  const [showModal, setShowModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let errors = { ...validationErrors };
    switch (name) {
      case "password":
        if (!value) {
          errors.password = "Current password is required.";
        } else {
          delete errors.password;
        }
        break;
      case "new_password":
        if (value && value.length < 6) {
          errors.new_password = "New password must be at least 6 characters long.";
        } else {
          delete errors.new_password;
        }
        break;
      case "name":
        if (!value) {
          errors.name = "First name is required.";
        } else {
          delete errors.name;
        }
        break;
      case "last_name":
        if (!value) {
          errors.last_name = "Last name is required.";
        } else {
          delete errors.last_name;
        }
        break;
      case "dob":
        if (!value) {
          errors.dob = "Date of birth is required.";
        } else {
          delete errors.dob;
        }
        break;
      case "phone":
        if (!value) {
          errors.phone = "phone number is required.";
        } else if (!/^\d{10}$/.test(value)) {
          errors.phone = "phone number must be 10 digits.";
        } else {
          delete errors.phone;
        }
        break;
      case "pet_name":
        if (!value) {
          errors.pet_name = "Pet name is required.";
        } else {
          delete errors.pet_name;
        }
        break;
        case "pet_age":
          if (!value) {
              errors.pet_age = "Pet age is required.";
          } else if (!/^\d+$/.test(value)) {
              errors.pet_age = "Pet age must be a numeric value.";
          } else {
              delete errors.pet_age;
          }
          break;
      
      case "type_of_pet":
        if (!value) {
          errors.type_of_pet = "Pet type is required.";
        } else {
          delete errors.type_of_pet;
        }
        break;
      case "location":
        if (!value) {
          errors.location = "Location is required.";
        } else {
          delete errors.location;
        }
        break;
      case "language":
        if (!value) {
          errors.language = "Language is required.";
        } else {
          delete errors.language;
        }
        break;
      default:
        break;
    }
    setValidationErrors(errors);
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    const token = localStorage.getItem('token');
    const uid = localStorage.getItem('user_id');

    try {
      const response = await axios.get(
        `${API.BASE_URL}${API.ENDPOINTS.profile}/${uid}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.data.status === true) {
        const data = response.data.payload.user;
        setProfileData({
          username: data.username || "",
          email: data.email || "",
          password: "",
          new_password: "",
          name: data.name || "",
          last_name: data.last_name || "",
          dob: data.user_details.dob || "",
          phone: data.user_details.phone || "",
          pet_name: data.user_details.pet_name || "",
          pet_age: data.user_details.pet_age || "",
          type_of_pet: data.user_details.type_of_pet || "",
          other: data.user_details.other || "",
          location: data.user_details.location || "",
          language: data.user_details.language || "",
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching owner data:", error);
    }
  };

  const [blockedUsers, setBlockedUsers] = useState([]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchBlockedUsers = async () => {
      try {
        const response = await axios.get(
          `${API.BASE_URL}${API.ENDPOINTS.blockFriends}`, // Replace with your API endpoint
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.status === true) {
          setBlockedUsers(response.data.payload); // Adjust based on API structure
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching blocked users:", error);
        // toast.error("Failed to fetch blocked users.");
      }
    };

    fetchBlockedUsers();
  }, [token]);


  const validateAllFields = () => {
    let errors = {};
    Object.keys(profileData).forEach((key) => {
      validateField(key, profileData[key]);
      if (validationErrors[key]) {
        errors[key] = validationErrors[key];
      }
    });
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const updateProfileData = async () => {
    if (!validateAllFields()) {
      toast.error("Please fix the errors in the form.");
      return;
    }

    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.profileUpdate}`,
        profileData,
        {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.status === true) {
        toast.success("Profile updated successfully!");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error updating profile data:", error);
      toast.error("Failed to update profile data.");
    }
  };

  const handleRelationshipFormChange = (e) => {
    const { name, value } = e.target;
    setRelationshipForm({ ...relationshipForm, [name]: value });
  };

  const handleFamilyFormChange = (e) => {
    const { name, value } = e.target;
    setFamilyForm({ ...familyForm, [name]: value });
  };

  const handleRelationshipSubmit = (e) => {
    e.preventDefault();
    console.log("Relationship Form Submitted:", relationshipForm);
  };

  const handleFamilySubmit = (e) => {
    e.preventDefault();
    console.log("Family Form Submitted:", familyForm);
    // Submit the familyForm data here via API
  };
  const toggleDropdown = (id) => {
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirmDelete = () => {
    // Handle the account deletion logic here
    console.log("Account deletion confirmed");
    handleCloseModal();
  };


  const handleUnblock = async (id) => {
    const token = localStorage.getItem("token");

    const formData = new FormData();
    formData.append("id", id);
    formData.append("status", 1);
    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.relationsunfollow}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.data.status === true) {
        toast.success(response.data.message);
        window.location.reload(); 
      }
    } catch (error) {
      console.error("Error blocking friend:", error);
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "basic":
        return (
          <>
            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Username</label>
                <input
                  type="text"
                  className="form-control"
                  name="username"
                  value={profileData.username}
                  onChange={handleInputChange}
                  disabled
                />
                {validationErrors.username && (
                  <div className="text-danger">{validationErrors.username}</div>
                )}
              </div>
              <div className="col-md-6">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={profileData.email}
                  onChange={handleInputChange}
                  readOnly
                />
                {validationErrors.email && (
                  <div className="text-danger">{validationErrors.email}</div>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Current Password</label>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  value={profileData.password}
                  onChange={handleInputChange}
                />
                {validationErrors.password && (
                  <div className="text-danger">{validationErrors.password}</div>
                )}
              </div>
              <div className="col-md-6">
                <label className="form-label">New Password</label>
                <input
                  type="password"
                  className="form-control"
                  name="new_password"
                  value={profileData.new_password}
                  onChange={handleInputChange}
                />
                {validationErrors.new_password && (
                  <div className="text-danger">{validationErrors.new_password}</div>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Owner's First Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={profileData.name}
                  onChange={handleInputChange}
                />
                {validationErrors.name && (
                  <div className="text-danger">{validationErrors.name}</div>
                )}
              </div>
              <div className="col-md-6">
                <label className="form-label">Owner's Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="last_name"
                  value={profileData.last_name}
                  onChange={handleInputChange}
                />
                {validationErrors.last_name && (
                  <div className="text-danger">{validationErrors.last_name}</div>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Date of Birth</label>
                <input
                  type="date"
                  className="form-control"
                  name="dob"
                  value={profileData.dob}
                  onChange={handleInputChange}
                />
                {validationErrors.dob && (
                  <div className="text-danger">{validationErrors.dob}</div>
                )}
              </div>
              <div className="col-md-6">
                <label className="form-label">phone</label>
                <input
                  type="tel"
                  className="form-control"
                  name="phone"
                  value={profileData.phone}
                  onChange={handleInputChange}
                />
                {validationErrors.phone && (
                  <div className="text-danger">{validationErrors.phone}</div>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Pet Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="pet_name"
                  value={profileData.pet_name}
                  onChange={handleInputChange}
                />
                {validationErrors.pet_name && (
                  <div className="text-danger">{validationErrors.pet_name}</div>
                )}
              </div>
              <div className="col-md-6">
                <label className="form-label">Pet Age</label>
                <input
                  type="text"
                  className="form-control"
                  name="pet_age"
                  value={profileData.pet_age}
                  onChange={handleInputChange}
                />
                {validationErrors.pet_age && (
                  <div className="text-danger">{validationErrors.pet_age}</div>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Pet Type</label>

                <select 
               className="form-control"
               name="type_of_pet"
               value={profileData.type_of_pet}
               onChange={handleInputChange}>
                              <option value="">Select pet type</option>
                              <option value="Dog">Dog</option>
                              <option value="Cat">Cat</option>
                              <option value="Bird">Bird</option>
                              <option value="Other">Other</option>
                            </select>
                            {validationErrors.type_of_pet && (
                  <div className="text-danger">{validationErrors.type_of_pet}</div>
                )}               
              </div>
              <div className="col-md-6">
                <label className="form-label">Other</label>
                <input
                  type="text"
                  className="form-control"
                  name="other"
                  value={profileData.other}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Location</label>
                <input
                  type="text"
                  className="form-control"
                  name="location"
                  value={profileData.location}
                  onChange={handleInputChange}
                />
                {validationErrors.location && (
                  <div className="text-danger">{validationErrors.location}</div>
                )}
              </div>
              <div className="col-md-6">
                <label className="form-label">Language</label>
                <input
                  type="text"
                  className="form-control"
                  name="language"
                  value={profileData.language}
                  onChange={handleInputChange}
                />
                {validationErrors.language && (
                  <div className="text-danger">{validationErrors.language}</div>
                )}
              </div>
            </div>
          </>
        );
      case "blocking":
        return (
          <div className="profile-edit-layout-right">
          <p className="blocked-title">Blocking</p>
          <p>This page contains the list of people you blocked.</p>
          <div className="ossn-block-lists">
            <div className="row">
              {blockedUsers.length > 0 ? (
                blockedUsers.map((user) => (
                  <div className="ossn-users-list-item mx-2" key={user.receiver.id}>
              
                    <div className="friends-list-controls-menu" id={`users-list-controls-menu-${user.id}`}>
                            <img
                              className="img-responsive"
                              src={dotsImg}
                              alt="Options"
                              onClick={() => toggleDropdown(user.id)} 
                              style={{ cursor: "pointer" }}
                            />
                             {openDropdownId === user.id && ( 
                              <div className="custom-dropdown" ref={dropdownRef}>
                                <div>
                                  
                                  <a className="users-list-controls-menu-item" onClick={() => handleUnblock(user.id)}>
                                    <div>Block</div>
                                    <img className="img-responsive icon" src={blockImg} alt="Block Icon" />
                                  </a>
                                  
                                </div>
                              </div>
                            )}
                             </div>
                             <div className="user-item-container">
                      <img
                        className="img-responsive"
                        src={user.sender.profile_img || "/default-avatar.png"}
                        width="100"
                        height="100"
                        alt="User Avatar"
                      />
                      
                      <div className="uinfo">
                        <a
                          className="ossn-output-user-url userlink"
                          data-username={user.receiver.pet_name}
                        >
                          {user.receiver.details.pet_name}
                        </a>
                      </div>
                      {/* <button
                        className="btn btn-primary mt-2"
                        onClick={() => handleUnblock(user.id)}
                      >
                        Unblock
                      </button> */}
                    </div>
                  </div>
                ))
              ) : (
                <p>No blocked users found.</p>
              )}
            </div>
          </div>
        </div>
        
        );
      case "deleteacc":
        return (
          <div className="profile-edit-layout-right">
            <p className="alert delete-alert">
              Warning! The account delete will be permanent. This action is not
              reversible.
            </p>
            <Button className="btn btn-sm btn-primary" onClick={handleOpenModal}>
              Delete Account
            </Button>
          </div>
        );
      case "familyrelationships":
        return (
          <>
            <div className="profile-edit-layout-right">
              <div className="family-relationships-container">
                {/* Relationship Section */}
                <div className="family-status">
                  <div className="family-relationships-title profile-edit-layout-title">
                    Relationship
                  </div>
                  <button
                    className="add-relationship-member add-family-status"
                    onClick={() => setRelationshipFormVisible(!relationshipFormVisible)}
                  >
                    <img
                      src={pawgreen}
                      alt="Add Relationship"
                    />
                    Add relationship status
                  </button>
                  {relationshipFormVisible && (
                    <div className="relation-member-add-form" style={{ display: 'block' }}>
                      <form onSubmit={handleRelationshipSubmit}>
                        <div>
                          <select
                            className="ossn-dropdown-input"
                            name="type"
                            value={relationshipForm.type}
                            onChange={handleRelationshipFormChange}
                          >
                            <option value="0">-</option>
                            <option value="Single">Single</option>
                            <option value="InaRelationship">In a Relationship</option>
                            <option value="Engaged">Engaged</option>
                            <option value="Married">Married</option>
                            <option value="ItsComplicated">It's Complicated</option>
                            <option value="InanOpenRelationship">
                              In an Open Relationship
                            </option>
                            <option value="Widowed">Widowed</option>
                            <option value="Separated">Separated</option>
                            <option value="Divorced">Divorced</option>
                            <option value="InaCivilUnion">In a Civil Union</option>
                            <option value="InaDomesticPartnership">
                              In a Domestic Partnership
                            </option>
                          </select>
                        </div>
                        <div className="margin-top-10">
                          <label>Privacy</label>
                          <select
                            className="ossn-dropdown-input"
                            name="privacy"
                            value={relationshipForm.privacy}
                            onChange={handleRelationshipFormChange}
                          >
                            <option value="2">Public</option>
                            <option value="3">Friends</option>
                          </select>
                        </div>
                        <div className="margin-top-10">
                          <input
                            type="text"
                            className="form-control mb-3"
                            name="friendGuid"
                            placeholder="Search friends list"
                            value={relationshipForm.friendGuid}
                            onChange={handleRelationshipFormChange}
                          />
                        </div>
                        <div>
                          <input
                            type="date"
                            className="form-control"
                            name="since"
                            placeholder="Since"
                            value={relationshipForm.since}
                            onChange={handleRelationshipFormChange}
                          />
                        </div>
                        <p>
                          We will send a confirmation request to the member for this
                          relationship.
                        </p>
                        <button type="button" className="btn btn-sm btn-primary btn btn-primary">Save</button>
                      </form>
                    </div>
                  )}
                  <div className="family-members-list relationship-status"></div>
                </div>

                <div className="family-status">
                  <div className="family-relationships-title margin-top-10 profile-edit-layout-title">
                    Family Members
                  </div>
                  <button
                    className="add-family-member"
                    onClick={() => setFamilyFormVisible(!familyFormVisible)}
                  >
                    <img
                      src={pawgreen}
                      alt="Add Family Member"
                    />
                    Add family member
                  </button>
                  {familyFormVisible && (
                    <div className="relation-member-add-form" style={{ display: 'block' }}>
                      <form onSubmit={handleFamilySubmit}>
                        <div className="row">
                          <div className="col-sm-12 col-md-6">
                            <input
                              type="text"
                              className="form-control mb-3"
                              name="friendGuid"
                              placeholder="Search friends list"
                              value={familyForm.friendGuid}
                              onChange={handleFamilyFormChange}
                            />
                          </div>
                          <div className="col-sm-12 col-md-6">
                            <select
                              className="ossn-dropdown-input"
                              name="type"
                              value={familyForm.type}
                              onChange={handleFamilyFormChange}
                            >
                              <option value="Mother">Mother</option>
                              <option value="Father">Father</option>
                              <option value="Daughter">Daughter</option>
                              <option value="Son">Son</option>
                              <option value="Sister">Sister</option>
                              <option value="Brother">Brother</option>
                              <option value="Auntie">Auntie</option>
                              <option value="Uncle">Uncle</option>
                              <option value="Niece">Niece</option>
                              <option value="Nephew">Nephew</option>
                              <option value="Grandmother">Grandmother</option>
                              <option value="Grandfather">Grandfather</option>
                            </select>
                          </div>
                        </div>
                        <div className="margin-top-10">
                          <label>Privacy</label>
                          <select
                            className="ossn-dropdown-input"
                            name="privacy"
                            value={familyForm.privacy}
                            onChange={handleFamilyFormChange}
                          >
                            <option value="2">Public</option>
                            <option value="3">Friends</option>
                          </select>
                        </div>
                        <p>
                          We will send a confirmation request to the member for this
                          relationship.
                        </p>
                        <button type="button" className="btn btn-sm btn-primary btn btn-primary">Save</button>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      default:
        return <h5>Select a tab to view content</h5>;
    }
  };

  return (
    <div className="container-fluid">
      <div className="edit-profile">
        <div className="row">
          <div className="col-sm-12 col-md-3 col-lg-3 p-2">
            <div className="profile-edit-tabs">
              <div className="profile-edit-layout-title">Edit</div>
              <div>
                <a
                  className={`profile-edit-tab-item ${
                    activeTab === "basic" ? "profile-edit-tab-item-active" : ""
                  }`}
                  onClick={() => setActiveTab("basic")}
                >
                  Basic Settings
                </a>
                <a
                  className={`profile-edit-tab-item ${
                    activeTab === "blocking" ? "profile-edit-tab-item-active" : ""
                  }`}
                  onClick={() => setActiveTab("blocking")}
                >
                  Blocking
                </a>
                <a
                  className={`profile-edit-tab-item ${
                    activeTab === "deleteacc" ? "profile-edit-tab-item-active" : ""
                  }`}
                  onClick={() => setActiveTab("deleteacc")}
                >
                  Delete Account
                </a>
                <a
                  className={`profile-edit-tab-item ${
                    activeTab === "familyrelationships"
                      ? "profile-edit-tab-item-active"
                      : ""
                  }`}
                  onClick={() => setActiveTab("familyrelationships")}
                >
                  Family and Relationships
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-9  p-2">
            <div className="card">
              <div className="card-body">{renderTabContent()}
              {activeTab === "basic" && (
                <div >
                  <Button className="btn btn-primary" onClick={updateProfileData}>
                    Save Changes
                  </Button>
                </div>
              )}
              </div>

            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        showModal={showModal}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirmDelete}
      />
    </div>
  );
};

const ConfirmationModal = ({ showModal, handleClose, handleConfirm }) => {
  if (!showModal) return null;

  return (
    <>
      <div id="confirmationModal" className="modal" style={{ display: 'block' }}>
        <div className="modal-content">
          <div className="title">
            Are you sure you want to delete your account?
            <div className="close" onClick={handleClose}>
              X
            </div>
          </div>
          <div className="modal-buttons">
            <button className="btn btn-primary btn-sm" onClick={handleConfirm}>
              Yes
            </button>
            <button className="btn btn-default btn-sm" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
