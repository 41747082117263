import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";

const VideosCarousel = ({ videos }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const videoRefs = useRef([]);

  console.log("videos", videos);

  // Filter only video items
  const videoItems = videos.filter((item) => item.type === "video");

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? videoItems.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === videoItems.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const videoPlayer = entry.target.querySelector('video');
            if (videoPlayer) {
              videoPlayer.currentTime = 0; // Reset to the beginning
              videoPlayer.play();
            }
          } else {
            const videoPlayer = entry.target.querySelector('video');
            if (videoPlayer) {
              videoPlayer.pause();
            }
          }
        });
      },
      { threshold: 0.1 }
    );

    videoRefs.current.forEach((container) => {
      if (container) {
        observer.observe(container);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [videoItems]);

  return (
    <div className="carousel">
      <div
        className="carouselInner"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {videoItems.map((item, index) => (
          <div
            key={index}
            className="slide"
            ref={(el) => (videoRefs.current[index] = el)}
          >
            <ReactPlayer
              url={item.url}
              controls
              playing={index === currentIndex} // Play only the active video
              loop
              muted
              width="100%"
              height="300px"
              style={{ maxWidth: "100%" }}
            />
          </div>
        ))}
      </div>
      {videoItems.length > 1 && (
        <>
          <button className="leftArrow" onClick={goToPrevious}>
            ❮
          </button>
          <button className="rightArrow" onClick={goToNext}>
            ❯
          </button>
        </>
      )}
    </div>
  );
};

export default VideosCarousel;
