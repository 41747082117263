import React, { useEffect, useRef, useState } from 'react';
import Share from '../../assets/images/sidebar/share.png';
import Comment from '../../assets/images/sidebar/message-blank.png';
import Like from '../../assets/images/sidebar/heart_new.png';
import Menu from '../../assets/images/sidebar/dots.png';
import { Button, Col, Row } from 'react-bootstrap';
import { FaPen } from 'react-icons/fa';
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import NewsfeedRight from "../birthDayCoponent/newsfeedright";
// import Event from "./event";
import API from "../../utils";
import { toast } from "react-toastify";
import axios from "axios";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import ReactCountryDropdown from 'react-country-dropdown';
import defalutimages from "../../assets/images/user/user_default.jpg";
import Loader from "../common/Loader";

const ConfirmationModal = ({ showModal, handleClose, handleConfirm }) => {
    if (!showModal) return null;

    return (
        <div id="confirmationModal" className="modal" style={{ display: 'block' }}>
            <div className="modal-content">
                <div className="title">
                    Are you sure you want to delete this post?
                    <div className="close" onClick={handleClose}>X</div>
                </div>
                <div className="modal-buttons">
                    <button className="btn btn-primary btn-sm" onClick={handleConfirm}>Yes</button>
                    <button className="btn btn-default btn-sm" onClick={handleClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
};


const ShareModal = ({ showModal, handleClose, postId }) => {
    if (!showModal) return null;

    return (
        <>
            <div
                className="ossn-halt ossn-light"
                style={{ height: '10357px', position: 'fixed', display: 'block' }}
            ></div>
            <div className="ossn-message-box" style={{ display: 'block' }}>
                <div className="title">
                    Share
                    <div className="close-box" onClick={handleClose}>X</div>
                </div>
                <div className="contents">
                    <div className="ossn-box-inner">
                        <div style={{ width: '100%', margin: 'auto' }}>
                            <form
                                action="https://app.xpets.com/action/post/share"
                                id="share-post-form"
                                className="ossn-form"
                                method="post"
                                encType="multipart/form-data"
                            >
                                <fieldset>
                                    <input type="hidden" name="ossn_ts" value="1732683242" />
                                    <input type="hidden" name="ossn_token" value="43d92f178962e05f6ff6f7476c7986598c26bcdb69c7d2406daa64bd32223391" />
                                    <div>
                                        <label>Select Type</label>
                                        <select className="ossn-dropdown-input" name="type" id="sharepost-select-type">
                                            <option disabled selected hidden>Select Type</option>
                                            <option value="newsfeed">Newsfeed</option>
                                            <option value="friend">Friend Profile</option>
                                            <option value="group">Group</option>
                                        </select>
                                    </div>
                                    <input type="hidden" name="guid" value={postId} />
                                    <input type="hidden" name="share_type" value="object" />
                                    <input type="submit" className="hidden" id="sharepost-cb" value="Save" />
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="control">
                    <div className="controls">
                        <a href="javascript:void(0);" onClick={() => document.getElementById('sharepost-cb').click()} className="btn btn-primary btn-sm">Share</a>
                        <a href="javascript:void(0);" onClick={handleClose} className="btn btn-default btn-sm">Cancel</a>
                    </div>
                </div>
            </div>
        </>
    );
};




const EditModal = ({ showModal, handleClose, formData, handleSave }) => {
    const [localFormData, setLocalFormData] = useState(formData);

    const { id } = useParams();

    useEffect(() => {
        setLocalFormData(formData); // Sync with props when modal opens
    }, [formData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLocalFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setLocalFormData((prevData) => ({ ...prevData, image: file }));
    };
    const handleDateChange = (date) => {
        setLocalFormData((prev) => ({
            ...prev,
            date: date.format("MM-DD-YYYY"),
        }));
    };

    const handleCountryChange = (country) => {
        setLocalFormData((prev) => ({ ...prev, country }));
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        handleSave(localFormData); // Call parent save function with updated data
    };

    if (!showModal) return null;

    return (
        <div id="editModal" className="modal" style={{ display: "block" }}>
            <div className="modal-content">
                <div className="newsfeed-middle">
                    <div>
                        <div className="d-flex justify-content-between">
                            <div className="widget-heading">Edit</div>
                            <div className="close" onClick={handleClose}>
                                X
                            </div>
                        </div>
                        <div className="widget-body">
                            <form onSubmit={handleSubmit} className="ossn-form" encType="multipart/form-data">
                                <fieldset>
                                    {/* Event Name */}
                                    <div>
                                        <label>Event Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            className="form-control"
                                        />
                                    </div>

                                    {/* Event Description */}
                                    <div>
                                        <label>Event Description</label>
                                        <textarea
                                            name="details"
                                            value={formData.details}
                                            onChange={handleChange}
                                            className="form-control"
                                        />
                                    </div>

                                    {/* Country */}
                                    <div id="addEventModal_Country">
                                        <label>Country</label>
                                        <ReactCountryDropdown
                                            defaultCountry="IN"
                                            onSelect={(country) => handleCountryChange(country.name)}
                                            className="form-control"
                                        />
                                    </div>

                                    {/* Start Time */}
                                    <div>
                                        <label>Start Time</label>
                                        <input
                                            type="time"
                                            name="start_time"
                                            value={formData.start_time}
                                            onChange={handleChange}
                                            className="form-control"
                                        />
                                    </div>

                                    {/* End Time */}
                                    <div>
                                        <label>End Time</label>
                                        <input
                                            type="time"
                                            name="end_time"
                                            value={formData.end_time}
                                            onChange={handleChange}
                                            className="form-control"
                                        />
                                    </div>

                                    {/* Location */}
                                    <div>
                                        <label>Location</label>
                                        <input
                                            type="text"
                                            name="location"
                                            value={formData.location}
                                            onChange={handleChange}
                                            className="form-control"
                                        />
                                    </div>

                                    {/* Date */}
                                    <div>
                                        <label>Date</label>
                                        <DateTime
                                            value={formData.date}
                                            onChange={handleDateChange}
                                            dateFormat="MM/DD/YYYY"
                                            timeFormat={false}
                                            inputProps={{ readOnly: true }}
                                            isValidDate={(currentDate) =>
                                                currentDate.isSameOrAfter(new Date(), "day")
                                            }
                                        />
                                    </div>

                                    {/* Image */}
                                    <div>
                                        <label>Image</label>
                                        <input
                                            type="file"
                                            name="image"
                                            onChange={handleFileChange}
                                            className="form-control"
                                        />
                                    </div>

                                    {/* Submit Button */}
                                    <div className="mt-3">
                                        <input type="submit" value="Save" className="btn btn-primary" />
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ReportModal = ({ showModal, handleClose, postId }) => {
    if (!showModal) return null;

    return (
        <>
            <div
                className="ossn-halt ossn-light"
                style={{ height: '10357px', position: 'fixed', display: 'block' }}
            ></div>
            <div className="ossn-message-box" style={{ display: 'block' }}>
                <div className="title">
                    Report this
                    <div className="close-box" onClick={handleClose}>X</div>
                </div>
                <div className="contents">
                    <div className="ossn-box-inner">
                        <div style={{ width: '100%', margin: 'auto' }}>
                            <form className="ossn-form">
                                <fieldset>
                                    <div>
                                        <label>Reason</label>
                                        <textarea name="reason"></textarea>
                                    </div>
                                    <input type="hidden" name="guid" value={postId} />
                                    <input type="hidden" name="type" value="post" />
                                    <input type="submit" className="hidden" id="ossn-file-report" />
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="control">
                    <div className="controls">
                        <a href="javascript:void(0);" onClick={() => document.getElementById('ossn-file-report').click()} className="btn btn-primary btn-sm">Save</a>
                        <a href="javascript:void(0);" onClick={handleClose} className="btn btn-default btn-sm">Cancel</a>
                    </div>
                </div>
            </div>
        </>
    );
};

const Event = ({ postId, userData = {}, initialPostContent }) => {
    const [showReportModal, setShowReportModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const [commentText, setCommentText] = useState('');
    const [comments, setComments] = useState([]);
    const [attachment, setAttachment] = useState(null);
    const [attachmentPreview, setAttachmentPreview] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [postContent, setPostContent] = useState(initialPostContent);
    const [editedContent, setEditedContent] = useState(initialPostContent);
    const [likes, setLikes] = useState(0);
    const [isLiked, setIsLiked] = useState(false);
    const [eventData, setEventData] = useState(null);

    const [loading, setLoading] = useState(true);
    const dropdownRef = useRef();
    const [openDropdowns, setOpenDropdowns] = useState({});
    const toggleDropdown = (id) => {
        setOpenDropdowns((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };
    const [formData, setFormData] = useState({}); // Holds the form data for editing
    const [postIds, setPostIds] = useState(null); // ID of the post being edited
    const handleEditPost = () => setShowEditModal(true);
    const handleShowEditModal = (post) => {
        setFormData(post); // Set initial data in the form
        setPostIds(post.id); // Store the ID of the post being edited
        setShowEditModal(true);
    };
    const handleCloseEditModal = () => setShowEditModal(false);
    const handleShowReportModal = () => setShowReportModal(true);
    const handleSaveEdit = async (updatedData) => {
        try {
            const token = localStorage.getItem("token"); // Retrieve token if required
            const response = await axios.put(
                `${API.BASE_URL}${API.ENDPOINTS.update_events}/${postId}`,
                updatedData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
    
            if (response.status === 200) {
                console.log("Post updated successfully:", response.data);
                toast.success("Post updated successfully!");
            } else {
                console.error("Failed to update post:", response);
                toast.error("Failed to update post.");
            }
        } catch (error) {
            console.error("Error updating post:", error);
            toast.error("Error occurred while updating post.");
        } finally {
            setShowEditModal(false); // Close the modal
        }
    };
    
    

    const handleCloseReportModal = () => setShowReportModal(false);
    const handleEditSave = async (id, status) => {
        const formData = new FormData();
        formData.append('event_id', id); // Pass the event ID
        formData.append('status', status); // Pass the status (1 = Interested, 2 = Not Interested, 3 = Going)

        const token = localStorage.getItem('token'); // Retrieve token

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.events_intrest_update}`,
                formData,
                {
                    headers: {
                        Authorization: 'Bearer ' + token,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.data.status) {
                toast.success(response.data.message, {
                    className: 'toast-success',
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

                fetchUserList(id); // Refresh the list if needed
            } else {
                toast.error(response.data.message || 'An error occurred!');
            }
        } catch (error) {
            console.error("Error during API call:", error);
            toast.error("An error occurred while saving changes.");
        }
    };


    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpenDropdowns({});
            }
        };
        document.addEventListener('mousedown', handleOutsideClick);
    }, []);
    const { id } = useParams(); // Assuming your route includes an :id parameter
    const fetchUserList = async (id) => {
        const token = localStorage.getItem('token');
        setLoading(true);
        try {
            const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.eventdetails}/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.status) {
                setEventData(response.data.payload);
            } else {
                toast.error(response.data.message, { position: "top-right", autoClose: 5000 });
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
            // toast.error("Failed to fetch user data.");
        } finally {
            setLoading(false);
        }
    };



    useEffect(() => {
        fetchUserList(id);
    }, [id]);

    const { avatarUrl = defalutimages, name, username } = userData;
    const navigate = useNavigate(); // Initialize navigate
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const handleDeletePost = async () => {
        try {
            const token = localStorage.getItem("token"); // Assuming token is stored in localStorage
            const response = await axios.delete(
                `${API.BASE_URL}${API.ENDPOINTS.delete_events}/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`, // Add token to headers if required
                    },
                }
            );

            if (response.status === 200) {
                console.log(`Post ${id} deleted successfully`);
                toast.success("Post deleted successfully!"); // Display success notification
                fetchUserList(id); // Refresh the user list if needed
                navigate("/users/userEdit"); // Navigate to the desired page
            } else {
                console.error("Failed to delete the post:", response);
                toast.error("Failed to delete the post."); // Display error notification
            }
        } catch (error) {
            console.error("Error deleting post:", error);
            toast.error("Error occurred while deleting the post."); // Display error notification
        } finally {
            setShowModal(false); // Close the modal in all cases
        }
    };

    const handleCommentPost = () => {
        if (commentText.trim()) {
            const newComment = { user: 'User', text: commentText };
            setComments((prevComments) => [...prevComments, newComment]);
            setCommentText('');
            // socket.emit('add_comment', { postId, comment: newComment });
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setAttachment(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setAttachmentPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleLikePost = () => {
        setIsLiked(!isLiked);
        setLikes((prevLikes) => (isLiked ? prevLikes - 1 : prevLikes + 1));
        // Emit the like update to the backend
        // socket.emit('update_like', { postId, isLiked: !isLiked });
    };

    const handleSharePost = () => {
        setShowShareModal(true);
    };

    const handleCloseShareModal = () => {
        setShowShareModal(false);
    };



    const [taggedFriends, setTaggedFriends] = useState(""); // State for storing friends input
    const [eventId] = useState(430); // Event ID (static in this case)


    // Handle input change
    const handleInputChange = (e) => {
        setTaggedFriends(e.target.value);
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();

    };


    return (
        <div className="ossn-wall-item ossn-wall-item-476" id={`activity-item-${postId}`}>
            <div className="row">
                {loading ? (
                    <Loader />
                ) : eventData ? (
                    <>
                        <div className='event-title d-flex justify-content-between'>
                            <div className="widget-heading">{eventData.name || "Event Name"}</div>


                            <div class="controls">
                                {/* <a class="btn-primary-caption mx-1" title=" Edit" onClick={handleShowEditModal}><FaPen /></a> */}
                                <a class="btn-primary-caption" title="Delete" onClick={handleShowModal}><i class="fa fa-trash"></i></a>
                            </div>

                        </div>


                        <div class="row mt-4">
                            <div class="col-md-6 col-sm-12">
                                <div class="image-event">
                                    <img src={eventData.image || "Event Name"} />
                                </div>
                                <div className="manager-control">
                                    <div className="event-manager">
                                        <strong>Created By:</strong> {eventData?.creator?.username || "Event Name"}
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-6 col-sm-12 event-info">
                                <div className="event-actions">
                                    <a
                                        className="btn-primary"
                                        onClick={() => handleEditSave(eventData.id, 3)} // Going: status = 3
                                    >
                                        Going
                                    </a>

                                    <a
                                        className="btn-primary mx-2"
                                        onClick={() => handleEditSave(eventData.id, 1)} // Interested: status = 1
                                    >
                                        Interested
                                    </a>

                                    <a
                                        className="btn-primary"
                                        onClick={() => handleEditSave(eventData.id, 2)} // Not Interested: status = 2
                                    >
                                        Not Interested
                                    </a>
                                </div>

                                <p className="event-details">{eventData.details || "Event Name"}</p>
                            </div>
                        </div>



                        <Row className='mt-4 event-bottom-panel'>

                            <Col lg={4} md={4} sm={12}><div class="event-box mb-4">
                                <div className="title">Date</div>

                                <div className="event-date">
                                    <div className="event-basic-info">
                                        {eventData?.created_at
                                            ? format(new Date(eventData.created_at), "MMMM, dd yyyy")
                                            : "Event Name"}
                                    </div>
                                </div>
                            </div></Col>
                            <Col lg={8} md={8} sm={12}><div class="event-box mb-4">
                                <div class="title">Location</div>
                                <div class="event-basic-info">
                                    {eventData.location || "location"} </div>
                            </div></Col>

                            <Col lg={4} md={4} sm={12}>
                                <div className="event-box mb-4">
                                    <div className="title">Going</div>
                                    <div className="event-date">
                                        <div className="event-basic-info">
                                            {eventData.auth_user_status == 3 ? '3' : '0'} {/* Display "Going" if status is 3 */}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4} sm={12}>
                                <div className="event-box mb-4">
                                    <div className="title">Interested</div>
                                    <div className="event-date">
                                        <div className="event-basic-info">
                                            {eventData.auth_user_status == 1 ? '1' : '0'} {/* Display "Interested" if status is 1 */}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4} sm={12}>
                                <div className="event-box">
                                    <div className="title">Not Interested</div>
                                    <div className="event-date">
                                        <div className="event-basic-info">
                                            {eventData.auth_user_status == 2 ? '2' : '0'} {/* Display "Not Interested" if status is 2 */}
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={6} md={4} sm={12}><div class="event-box mb-4">
                                <div class="title">Start Time </div>
                                <div class="event-date">
                                    <div class="event-basic-info">
                                        {eventData.start_time || "end_time"}    </div>
                                </div>
                            </div></Col>
                            <Col lg={6} md={4} sm={12}><div class="event-box mb-4">
                                <div class="title">End Time </div>
                                <div class="event-date">
                                    <div class="event-basic-info">
                                        {eventData.end_time || "end_time"}           </div>
                                </div>
                            </div></Col>


                        </Row>
                    </>
                ) : (
                    <div>No event data available.</div>
                )}

                           </div>
            <input type="file" id="file-input" style={{ display: 'none' }} onChange={handleFileChange} />
            {attachmentPreview && (
                <div className="attachment-preview">
                    <img src={attachmentPreview} alt="Attachment Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                </div>
            )}
    <ConfirmationModal
                showModal={showModal}
                handleClose={handleCloseModal}
                handleConfirm={handleDeletePost}
            />
            <ShareModal
                showModal={showShareModal}
                handleClose={handleCloseShareModal}
                postId={postId}
            />
            <ReportModal
                showModal={showReportModal}
                handleClose={handleCloseReportModal}
                postId={postId}
            />
           <EditModal
    showModal={showEditModal}
    handleClose={handleCloseEditModal}
    formData={formData}
    handleSave={handleSaveEdit}
/>
        </div>
    );
};

export default Event;
