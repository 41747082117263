import React, { Fragment } from "react";
import Header from "./common/header-component/header";
import Sidebar from "./common/sidebar-component/sidebar";
import RightSidebar from "./common/right-sidebar";
import Footer from "./common/footer";
import ThemeCustomizer from "./common/theme-customizer";
import { ToastContainer } from "react-toastify";
import { Outlet, useLocation } from "react-router-dom";

import { Provider } from "react-redux";
import store from "../store";
import { NotificationsProvider } from "./common/header-component/notificationsContext";

const AppLayout = (props) => {
  const location = useLocation();

  // Define routes where the Footer should not appear
  const excludedRoutes = ["/reels", "/users/userEdit"];
  const isExcludedRoute = excludedRoutes.includes(location.pathname);

  return (
    <Fragment>
      {/* <Loader /> */}
      <div className="page-wrapper">
        <div className="page-body-wrapper">
          <NotificationsProvider> <Header />
          </NotificationsProvider>
         
          <Provider store={store}>
            <Sidebar />
          </Provider>
          <RightSidebar />
          <div className="page-body">
            <Outlet />
            {!isExcludedRoute && <Footer />}
          </div>
          <ThemeCustomizer />
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default AppLayout;
