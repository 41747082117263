import React from 'react';

const EditCommentModal = ({ showModal, handleClose, commentText, setCommentText, handleSave, commentId }) => {
    if (!showModal) return null;
    const handleFormSubmit = (e) => {
        e.preventDefault();
        handleSave();
    };
    return (
        <>
            <div
                className="ossn-halt ossn-light"
                style={{ height: '10357px', position: 'fixed', display: 'block' }}
            ></div>
            <div className="ossn-message-box" style={{ display: 'block' }}>
                <div className="title">
                    Edit
                    <div className="close-box" onClick={handleClose}>X</div>
                </div>
                <div className="contents">
                    <div className="ossn-box-inner">
                        <div style={{ width: '100%', margin: 'auto' }}>
                            <form className="ossn-form" onSubmit={handleFormSubmit}>
                                <fieldset>
                                    <div>
                                        <textarea
                                            id="comment-edit"
                                            name="comment"
                                            value={commentText}
                                            onChange={(e) => setCommentText(e.target.value)}
                                            style={{ width: '100%', height: '100px' }}
                                        ></textarea>
                                        <input type="hidden" name="guid" value={commentId} />
                                        <input type="submit" className="hidden" id="ossn-comment-edit-save" />
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="control">
                    <div className="controls">
                        <button className="btn btn-primary btn-sm" onClick={handleFormSubmit}>Save</button>
                        <button className="btn btn-default btn-sm" onClick={handleClose}>Cancel</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditCommentModal;
