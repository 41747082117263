import React, { useEffect, useState } from "react";
import API from "../../../../utils";
import { toast } from "react-toastify";
import { getData, postData } from "../../../../utils/axiosApiReq";
import InfiniteScroll from "react-infinite-scroll-component";
import petErrImg from "../../../../assets/images/themes/fozzy.jpg";
import { useNavigate } from "react-router-dom";

const AllNotification = () => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [perPageNoti, setPerPageNoti] = useState(10);

  const fetchNotificationData = async (per_page = 10, page = 1) => {
    try {
      /* const token = localStorage.getItem("token");
      const response = await axios.get(
        `${API.BASE_URL}${API.ENDPOINTS.notifications}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      
      if (response.data.success) {
        const notificationData = response.data.data.map((notification) => {
          const parsed = parseComment(notification.comment);
          return {
            id: notification.id,
            link: notification.link,
            image: notification.image || "/default-avatar.png", // Use a default image if none is provided
            unviewed: notification.unviewed,
            title: parsed.title,
            message: parsed.message,
            sender: parsed.sender,
            receiver: parsed.receiver,
            contentType: parsed.contentType,
          };
        });
        setNotifications(notificationData);
      }else {
        toast.error(response.data.message);
      }*/

      setLoading(true);

      const response = await getData(API?.ENDPOINTS?.notifications, { per_page, page });
      const { success, message, data } = response;

      if (success) {
        /*const notificationData = data.map((notification) => {
          const parsed = parseComment(notification.comment);
          return {
            ...notification,
            id: notification.id,
            link: notification.link,
            image: notification.image || "/default-avatar.png", // Use a default image if none is provided
            unviewed: notification.unviewed,
            title: parsed.title,
            message: parsed.message,
            sender: parsed.sender,
            receiver: parsed.receiver,
            contentType: parsed.contentType,
          };
        });*/
        // setNotifications(notificationData);
        setNotifications((prevNotifications) => [...prevNotifications, ...data]);
        if (data.length < per_page) {
          setHasMore(false); // No more data to fetch
        }
      } else {
        toast.error(message || "Error fetching notifications.");
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    } finally {
      setLoading(false);
    }
  };

  /*const parseComment = (comment) => {
    try {
      const parsedComment = JSON.parse(comment);
      return {
        title: parsedComment.title,
        message: parsedComment.message,
        sender: parsedComment.sender,
        receiver: parsedComment.receiver,
        id: parsedComment.id,
        contentType: parsedComment.content_type,
      };
    } catch (error) {
      console.error("Error parsing comment:", error);
      return {
        title: "Unknown",
        message: "Could not parse notification details.",
      };
    }
  };*/

  useEffect(() => {
    fetchNotificationData(perPageNoti);
    // fetchNotificationData();
  }, []);

  // Handle fetching more videos on scroll
  const fetchMoreNotifications = () => {
    if (!loading && hasMore) {
      setPage((prevPage) => prevPage + 1); // Increment page number
      fetchNotificationData(perPageNoti, page + 1); // Fetch next page of videos
    }
  };

  const handleNotificationClick = async (id, redirectId, contentType, link) => {
    console.log("contentType", contentType);
    const token = localStorage.getItem("token");
    try {
      /*const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.notifications}/${id}/mark-viewed`, null, {
        headers: {Authorization: "Bearer " + token,"Content-Type": "multipart/form-data",},
      });*/
      const response = await postData(`${API.ENDPOINTS.notifications}/${id}/mark-viewed`);
      if (response?.success) {
        if (contentType === "posts") {
          console.log(`/post-details`);
          window.location.href =`/post-details/${redirectId}`;
        } else if (contentType === "group") {
          window.location.href =`/groups-view/${redirectId}`;
        } else if (contentType === "video") {
          window.location.href =`/video-details/${redirectId}`;
        }
          else if (contentType === "event") {
            window.location.href =`/event-details/${redirectId}`;
          }
            else if (contentType === "friends") {
              window.location.href =`/messages/${redirectId}`;
            }
            else if (contentType === "story") {
              window.location.href =`/users/userEdit${redirectId}`;
        } else {
          window.location.href = link || "/";
        }
      } else {
        toast.error(response.data.message || "Failed to post the comment.");
      }
    } catch (error) {
      console.error("Error posting comment:", error);
      toast.error("Error posting comment. Please try again.");
    }
  };

  const markAllAsRead = async () => {
    try {
      setLoading(true);
  
      // Example of custom headers - include null or handle properly
      const headers = {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token") || null, // Pass null explicitly if token is not available
      };
  
      // Ensure headers pass as intended by your backend
      const response = await getData(API.ENDPOINTS.markallviewed, { headers });
  
      console.log(response);
  
      const { success, message } = response;
  
      if (success) {
        toast.success(message || "All notifications marked as viewed.");
      } else {
        toast.error(message || "Failed to mark all notifications as viewed.");
      }
    } catch (error) {
      console.error("Failed to mark all notifications as viewed:", error);
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "Failed to mark all notifications as viewed."
      );
    } finally {
      setLoading(false);
    }
  };
  

  const renderNotifications = () => {
    return notifications.map((notification, index) => {
      const { id, is_view, comment_data } = notification;
      const { id: commentId, title, message, posts, data, content_type } = comment_data;
      return (
        <a
          key={index}
          onClick={() => handleNotificationClick(id, commentId, content_type, notification.link || "/")}
          className={`btn ossn-notification${notification.unviewed ? "-unviewed" : ""}`}
        >
          <li>
            {!is_view && <div className="rounded-circle" style={{ width: "10px", height: "10px", background: "#005248" }}></div>}

            <div className="notification-image">
              <img src={data?.posted_user?.profile_img || petErrImg} alt="Notification" />
            </div>
            <div className="notfi-meta">
              <div className="data">
              <strong>{title}</strong>
                <p>{message}</p>                
              </div>
            </div>
          </li>
        </a>
      );
    });
  };

  return (
    <div className="mainDiv">
      <div className="container">
        <div className="ossn-layout-media">
          <div className="ossn-system-messages">
            <div className="row">
              <div className="col-md-11">
                <div className="ossn-system-messages-inner"></div>
              </div>
            </div>
          </div>

          <div className="content-page-sidebar-container">
            <div className="content">
              <div className="ossn-widget">
                <div className="widget-heading">
                  Notifications
                  <div className="notifications-mark-all" onClick={markAllAsRead}>Mark all as read</div>
                </div>
                <div className="widget-contents">
                  <div className="ossn-notifications-all ossn-notification-page">
                    <InfiniteScroll
                      dataLength={notifications.length}
                      next={fetchMoreNotifications}
                      hasMore={hasMore}
                      // loader={<h4>Loading...</h4>} 
                      endMessage={<p className="d-none">No more videos to load</p>} // Message when there are no more videos
                    >
                      {notifications.length > 0 ? renderNotifications() : <p>No notifications available.</p>}
                    </InfiniteScroll>
                    {/* {notifications.length > 0 ? (
                      renderNotifications()
                    ) : (
                      <p>No notifications available.</p>
                    )} */}
                  </div>
                  {/* <div className="container-table container-table-pagination">
                    <div className="center-row">
                      <ul className="pagination ossn-pagination flex-wrap">
                        <li className="page-item">
                          <a href="?offset=1" className="page-link ossn-pagination-page">
                            First
                          </a>
                        </li>
                        <li className="page-item active">
                          <a className="page-link" href="?offset=1">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="?offset=2">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="?offset=2">
                            Last
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <footer>
            <div className="footer-contents">
              <div className="ossn-footer-menu">
                <a className="menu-footer-a_copyrights" href="https://app.xpets.com/">
                  © COPYRIGHT xpets
                </a>
                <a className="menu-footer-about" href="https://xpets.com/work/emerge-f42y8">
                  About
                </a>
                <a className="menu-footer-site" href="https://xpets.com/terms-of-service">
                  Terms and Conditions
                </a>
                <a className="menu-footer-privacy" href="https://xpets.com/privacy-policy">
                  Privacy
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default AllNotification;
