import React, { Fragment } from 'react';

const Messages = (props) => {
    return (
        <Fragment>
            <div>
                <ul className="notification-dropdown ossn-notifications-box onhover-show-div p-0">
                    <div className="selected notifications"></div>
                    <div className="metadata">
                        {/* Messages Section */}
                        <div className="messages-inner">
                            <div className="ossn-notification-messages">
                                <div className="user-item">
                                    <div
                                        className="user-item-inner"
                                        onClick={() =>
                                            window.location.href = 'messages/message/fozzy'
                                        }
                                    >
                                        <div className="image">
                                            <img
                                                src="https://app.xpets.com/avatar/fozzy/small"
                                                alt="User Avatar"
                                            />
                                        </div>
                                        <div className="data">
                                            <div className="time">
                                                <img
                                                    src="https://app.xpets.com/themes/xpets/images/clock.png"
                                                    alt="Clock Icon"
                                                />{' '}
                                                2 weeks ago
                                            </div>
                                            <div className="name">digger</div>
                                            <div className="reply">
                                                <div className="reply-text-from">
                                                    Hello
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Bottom Link */}
                        <div className="bottom-all">
                            <a href="/home">See All</a>
                        </div>
                    </div>
                </ul>
            </div>
        </Fragment>
    );
};

export default Messages;
