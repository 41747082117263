import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import API from "../../../utils";
import { format } from "date-fns";  
const NewsfeedContainer = ({ data, updateCoverImageApi }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newCoverImage, setNewCoverImage] = useState(null);
  const token = localStorage.getItem("token");
  const [groupId, setGroupId] = useState(null);
  const [name, setName] = useState(""); // State to hold the name
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  // Handle file input change
  const handleFileChange = (event) => {
    setNewCoverImage(event.target.files[0]);
  };
  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  useEffect(() => {
    if (data && data.id) {
      setGroupId(data.id);
    }
  }, [data]);
  // Handle API call for updating cover image
  const handleCoverImageUpdate = async () => {
    

    const formData = new FormData();
    formData.append("cover_image", newCoverImage);
    formData.append("id", data?.id || "");
    // formData.append("name", name); // Add the name to the form data

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.createEditGroup}`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status) {
        toast.success("Group cover image and name updated successfully.");
        toggleModal(); // Close the modal after success
      } else {
        toast.error(response.data.message || "Failed to update.");
      }
    } catch (error) {
      console.error("Error updating cover image:", error);
      toast.error("Error updating cover image. Please try again.");
    }
  };

  return (
    <>
      <nav aria-label="breadcrumb">
        {/* Breadcrumb navigation */}
      </nav>
      <div className="col-md-12 col-sm-12 group-boxing">
        <div className="profile-header">
          <div
            className="ossn-group-cover"
            id="container"
            style={{ minHeight: "200px" }}
          >
            <img
              id="draggable"
              src={data.cover_image || ""}
              style={{ width: "100%" }}
              alt="Group Cover"
            />
          </div>
          <div
            className="header-bottom"
            style={{
              zIndex: "99999999",
              marginTop: "-138px",
            }}
          >
            <div className="group-header-sep">
              <div
                className="group-name"
                style={{
                  width: "100%",
                  marginTop: "10px",
                  marginBottom: "25px",
                  textAlign: "center",
                }}
              >
                <div className="group-icon">
                  <span>{data?.name?.slice(0, 2) || ""}</span>
                </div>
                <a style={{ marginLeft: "10px" }}>{data.name}</a>

                <a role="button" className="edit-cover-profile" onClick={toggleModal}>
                  <img
                    src="https://app.xpets.com/themes/xpets/images/dots.png"
                    alt="Edit Cover"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="group-wall w-100">
          <div className="ossn-widget about-group widget-description">
            <div className="widget-heading">About</div>
            <div className="widget-contents"> {data.details}</div>
          </div>
          <div className="ossn-widget about-group widget-description">
            <div className="widget-heading">Created</div>
            <div className="widget-contents">
              {data.created_at && format(new Date(data.created_at), 'MM/dd/yyyy, hh:mm:ss a')}
            </div>         
             </div>

          <div className="group-widget-members">
              <div className="ossn-widget memberheading">
                <div className="widget-heading">
                  Events ({data.events ? data.events.length : 0})
                </div>
                <div className="widget-contents">
                  {data.events && data.events.length > 0 ? (
                    data.events.map((event, index) => (
                      <div className="col-md-6 col-sm-12" key={index}>
                        <a href="/group">
                          <img
                            className="user-icon-small"
                            style={{
                              marginRight: "28px",
                              borderRadius: "8px",
                            }}
                            src={event.avatar}
                            alt={event.name}
                            title={event.name}
                          />
                          {event.name}
                        </a>
                      </div>
                    ))
                  ) : (
                    <p>No events available.</p>
                  )}
                </div>
              </div>
            </div>

            <div className=" row-group"> </div>
            <div className="group-widget-members">
              <div className="ossn-widget memberheading">
                <div className="widget-heading">
                  Members ({data.members ? data.members.length : 0})
                </div>
                <div className="widget-contents">
                  {data.members && data.members.length > 0 ? (
                    data.members.map((member, index) => (
                      <div className="col-md-6 col-sm-12" key={index}>
                        <a>
                          <img
                            className="user-icon-small"
                            style={{
                              marginRight: "28px",
                              borderRadius: "8px",
                            }}
                            src={member.avatar}
                            alt={member.name}
                            title={member.name}
                          />
                          {member.name}
                        </a>
                      </div>
                    ))
                  ) : (
                    <p>No members available.</p>
                  )}
                </div>
              </div>
            </div>
        </div>
      </div>

      {/* Bootstrap Modal for updating cover image */}
      <div
        className={`modal fade ${isModalOpen ? "show" : ""}`}
        id="coverImageModal"
        tabIndex="-1"
        aria-labelledby="coverImageModalLabel"
        aria-hidden={!isModalOpen}
        style={{ display: isModalOpen ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="coverImageModalLabel">
                Change Cover Image and Name
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={toggleModal}
              ></button>
            </div>
            <div className="modal-body">
              {/* <label>Name</label>
              <input
                type="text"
                value={name}
                onChange={handleNameChange}
                className="form-control"
                placeholder="Enter group name"
              /> */}
              <label>Cover Image</label>
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="form-control"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleCoverImageUpdate}
              >
                Update
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={toggleModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsfeedContainer;
