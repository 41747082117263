import InfiniteScroll from "react-infinite-scroll-component";
import API from "../../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

const AllGroupSearch = () => {
  const [groups, setGroups] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("user_id"); // Fetch the user ID from localStorage
  const [loading, setLoading] = useState(false);
  const [perPageNoti, setPerPageNoti] = useState(10);
  const [page, setPage] = useState(1);

  useEffect(() => {
    fetchGroups(perPageNoti);
  }, []);

  const fetchGroups = async (per_page = 10, page = 1) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.groupList}?per_page=${per_page}&page=${page}`, // Pass params in the URL
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.status === true) {
        const data = response.data.payload;
        setGroups((prevGroups) => [...prevGroups, ...data]);
        if (data.length < per_page) {
          setHasMore(false); // No more data to fetch
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching group data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchMoreNotifications = () => {
    if (!loading && hasMore) {
      setPage((prevPage) => prevPage + 1); // Increment page number
      fetchGroups(perPageNoti, page + 1); // Fetch next page
    }
  };

  const handleJoinToggle = async (id) => {
    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.sentJoinRequest}/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status === true) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="search-data">
      <div className="title">All  Groups</div>
      <hr />
      <InfiniteScroll
        dataLength={groups.length}
        next={fetchMoreNotifications}
        hasMore={hasMore}
        endMessage={<p className="d-none">No more videos to load</p>}
      >
        <div className="group-search-items">
          {groups.map((group) => (
            <div className="row" key={group.id}>
              <div className="col-md-10 col-lg-10 col-sm-12">
                <div
                  className="icon"
                  style={{ float: 'left', marginRight: '20px' }}
                >
                  {group.image ? (
                    <img
                      src={group.image}
                      alt={group.name}
                      style={{ width: 50, height: 50, borderRadius: '50%' }}
                    />
                  ) : (
                    <span>{group.name.charAt(0).toUpperCase()}</span>
                  )}
                </div>
                <div className="group-search-details">
                  <p className="group-name" style={{ fontWeight: 'bold',wordBreak:'break-word' }}>
                    {group.name}
                  </p>
                  <p className="ossn-group-search-by m-0">{group.details}</p>
                </div>
              </div>
              {/* Conditionally show Join Group button */}
              {group.created_by.toString() !== uid && (
                <div className="col-md-2 col-lg-2 col-sm-12 d-flex align-items-center justify-content-center justify-content-lg-end">
                  <button
                    className="btn m-0 btn-primary"
                    onClick={() => handleJoinToggle(group.id)}
                  >
                    Join Group
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default AllGroupSearch;
