import React from 'react';

const NewsfeedRight = () => {
    return (
        <>
            <div className="ossn-widget birthdays">
                <div className="widget-heading">
                    <span>Birthdays upcoming</span>
                </div>
                <div className="widget-contents">
                    <ul>
                        <a href="/Peterparker">
                            <li>

                                <img
                                    className="birthday-icon"
                                    src="https://app.xpets.com/themes/xpets/images/birthday.png"
                                    alt="Birthday Icon"
                                />
                                <div className="birthday-text">
                                    Alfie
                                    <br />
                                    <span className="birthday-date">21st of December</span>
                                </div>
                            </li>
                        </a>
                    </ul>
                </div>
            </div>

            <div className="ossn-widget hashtag-trending">
                <div className="widget-heading">Trending</div>
                <div className="widget-contents">
                    <a className="hashtag-trending-link"  href="#">
                        <div className="hashtag-trending-container">
                            <div className="d-inline-block">
                                <div className="hashtag-trending-title">#DramaQueen</div>
                                <div className="hashtag-trending-count">2 wall posts</div>
                            </div>
                        </div>
                    </a>
                    <br />
                    <a className="hashtag-trending-link"  href="#">
                        <div className="hashtag-trending-container">
                            <div className="d-inline-block">
                                <div className="hashtag-trending-title">#PetGoals</div>
                                <div className="hashtag-trending-count">2 wall posts</div>
                            </div>
                        </div>
                    </a>
                    <br />
                    <a className="hashtag-trending-link"  href="#">
                        <div className="hashtag-trending-container">
                            <div className="d-inline-block">
                                <div className="hashtag-trending-title">#bestfriend</div>
                                <div className="hashtag-trending-count">1 wall post</div>
                            </div>
                        </div>
                    </a>
                    <br />
                    <a className="hashtag-trending-link"  href="#">
                        <div className="hashtag-trending-container">
                            <div className="d-inline-block">
                                <div className="hashtag-trending-title">#WorkHardPlayHarder</div>
                                <div className="hashtag-trending-count">1 wall post</div>
                            </div>
                        </div>
                    </a>
                    <br />
                    <a className="hashtag-trending-link"  href="#">
                        <div className="hashtag-trending-container">
                            <div className="d-inline-block">
                                <div className="hashtag-trending-title">#PetLife</div>
                                <div className="hashtag-trending-count">1 wall post</div>
                            </div>
                        </div>
                    </a>
                    <br />
                    <a className="hashtag-trending-link" href="#">
                        <div className="hashtag-trending-container">
                            <div className="d-inline-block">
                                <div className="hashtag-trending-title">#LivingHisBestLife</div>
                                <div className="hashtag-trending-count">1 wall post</div>
                            </div>
                        </div>
                    </a>
                    <br />
                </div>
            </div>
        </>
    );
};

export default NewsfeedRight;
