import React, { Fragment, useContext, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import API from "../../../utils";
import { toast } from "react-toastify";
import { NotificationsContext } from "./notificationsContext";
import petErrImg from "../../../assets/images/themes/fozzy.jpg";

const Notifications = ({ notificationData }) => {
  const navigate = useNavigate();
  const [apiResponse, setApiResponse] = useState(null);
  const { notifications, loading, fetchNotifications, markNotificationAsViewed } = useContext(NotificationsContext);
  const handleNotificationClick = async (link, contentType, id, redirectId) => {
    console.log("contentType", contentType);
  
    const success = await markNotificationAsViewed(id);
    if (success) {
      if (contentType === "posts") {
        window.location.href =`/post-details/${redirectId}`;
      } else if (contentType === "group") {
        window.location.href =`/groups-view/${redirectId}`;
      } else if (contentType === "video") {
        window.location.href = `/video-details/${redirectId}`;
      } else if (contentType === "event") {
        window.location.href = `/event-details/${redirectId}`;
      }
      else if (contentType === "friends") {
        window.location.href = `/messages/${redirectId}`;
      } else {
        window.location.href = link || "/" ;
      }
    }
  };

  const limitedNotifications = notificationData.slice(0, 7);
  const hasMoreNotifications = notificationData.length > 7;
  //   const limitedNotifications = notifications.slice(0, 7);
  //   const hasMoreNotifications = notifications.length > 7;

  return (
    <Fragment>
      <div>
        <ul className="notification-dropdown onhover-show-div p-0">
          <div className="selected notifications"></div>
          <div className="metadata">
            <div className="messages-inner">
              {limitedNotifications.length > 0 ? (
                limitedNotifications.map((notification, index) => (
                  <div key={index} className="ossn-notifications-all">
                  <a
  onClick={() =>
    handleNotificationClick(
      notification.link || "/",
      notification.contentType,
      notification.id,
      notification.notificationId
    )
  }
  className={notification.is_view === 0 ? "ossn-notification-unviewed" : ""}
>
                      <li>
                        <div className="notification-image">
                          <img src={notification?.comment_data?.data?.posted_user?.profile_img || petErrImg} alt="User Avatar" />
                        </div>
                        <div className="notfi-meta">
                          <div className="data">
                            <strong>{notification.message}</strong> {notification.title}
                          </div>
                        </div>
                      </li>
                    </a>
                  </div>
                ))
              ) : (
                <div className="no-notifications text-center">No notifications found.</div>
              )}
            </div>
            {hasMoreNotifications && (
              <div className="bottom-all">
                <Link to="/notification/all">See All</Link>
              </div>
            )}
          </div>
        </ul>
      </div>
    </Fragment>
  );
};

export default Notifications;
