import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify"; // If you are using toast notifications
import API from "../../../utils";
import Nophotoalbum from "../../../assets/images/nophoto-album.png";
import AddAlbum from "../../../assets/images/sidebar/plus.png";
import { FaPlus } from "react-icons/fa";

const Photos = () => {
  const { BASE_URL, ENDPOINTS } = API;
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [albumName, setAlbumName] = useState("");
  const [privacy, setPrivacy] = useState(0); // Use 0 for Public, 1 for Friends
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [albums, setAlbums] = useState([]); // State to store album data

  // Fetch albums on component mount
  useEffect(() => {
    const fetchAlbums = async () => {
      try {
        const response = await axios.get(`${BASE_URL}${ENDPOINTS.albumlist}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (response.data.status) {
          setAlbums(response.data.payload); // Store albums in state
        } else {
          toast.error("Failed to fetch albums.");
        }
      } catch (error) {
      }
    };

    fetchAlbums();
  }, [BASE_URL, ENDPOINTS]);

  const handleImageClick = (albumId) => {
    navigate(`/album/view/${albumId}`);
  };

  const validateForm = () => {
    let formErrors = {};
    if (!albumName.trim()) {
      formErrors.albumName = "Album name is required.";
    }
    if (privacy === null) {
      formErrors.privacy = "Please select a privacy option.";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleAddAlbum = async () => {
    if (validateForm()) {
      try {
        setLoading(true);
  
        // Creating a FormData object
        const formData = new FormData();
        formData.append("album_name", albumName);
        formData.append("status", privacy);
  
        const token = localStorage.getItem("token");
  
        const response = await axios.post(`${BASE_URL}${ENDPOINTS.createalbum}`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });
  
        if (response.data.status) {
          toast.success("Album created successfully");
  
          // Add the newly created album to the state (with the id)
          setAlbums((prevAlbums) => [
            ...prevAlbums,
            { id: response.data.payload.id, album_name: albumName, thumbnail: null, privacy },
          ]);
  
          setShowModal(false);
          setAlbumName("");
          setPrivacy(0);
        } else {
          toast.error(response.data.message || "Failed to create album.");
        }
      } catch (error) {
        toast.error("Error creating album. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  };
  

  return (
    <>
      <div className="container-fluid">
        <div className="edit-profile">
          <div className="row">
            <div className="col-lg-12">
              <form className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <div className="title">Photo Albums</div>
                    <div>
                      <div className="controls">
                        <a
                          id="ossn-add-album"
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowModal(true)}
                        >
                        <FaPlus />
                          Add Album
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="module-contents">
                  {albums.length > 0 ? (
                      <div className="ossn-photos">
                        {albums.map((album) => (
                          <li key={album.id}>
                            <a
                              className="linkthumb"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleImageClick(album.id)} // Pass album.id here
                            >
                              <img
                                src={album.thumbnail || Nophotoalbum} // Use a default if no thumbnail
                                className="pthumb"
                                alt={album.album_name}
                              />
                              <div className="ossn-album-name">{album.album_name}</div>
                            </a>
                          </li>
                        ))}
                      </div>
                    ) : (
                      <h6 className="no-albums-message">
                        No albums available.
                      </h6>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Add Album Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <div className="title d-flex justify-content-between">
          Add New Album
          <div className="close-box" style={{ cursor: "pointer" }} onClick={() => setShowModal(false)}>
            X
          </div>
        </div>
        <hr />
        <form>
          <div className="mb-3">
            <label className="d-flex">Album Name</label>
            <input
              type="text"
              className="form-control"
              value={albumName}
              onChange={(e) => setAlbumName(e.target.value)}
            />
            {errors.albumName && <small className="text-danger d-flex">{errors.albumName}</small>}
          </div>
          <div className="ossn-privacy d-flex">
            <button
              type="button"
              className={`privacy-option-btn ${privacy === 0 ? "selected" : ""}`}
              onClick={() => setPrivacy(0)}
            >
              Public
            </button>
            <button
              type="button"
              className={`privacy-option-btn ${privacy === 1 ? "selected" : ""}`}
              onClick={() => setPrivacy(1)}
            >
              Friends
            </button>
          </div>
          {errors.privacy && <small className="text-danger d-flex">{errors.privacy}</small>}

          <hr />
          <div className="control d-flex justify-content-end">
            <div className="controls">
              <button
                type="button"
                onClick={handleAddAlbum}
                className="my-0 btn btn-primary btn-sm"
                disabled={loading}
              >
                {loading ? "Saving..." : "Save"}
              </button>
              <button
                type="button"
                onClick={() => setShowModal(false)}
                className="btn btn-default btn-sm ms-1 my-0"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default Photos;
