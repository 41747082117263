

import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios"; // For making the API call
import Friendsimg from "../../../assets/images/sidebar/friends.png";
import API from "../../../utils";
import messageFil from "../../../assets/images/message-filled.png";
import blockImg from "../../../assets/images/block.png";
import dotsImg from "../../../assets/images/dots.png";
import { toast } from "react-toastify";
import { FaSearch } from "react-icons/fa";

const UserFriends = () => {
  const [friendsData, setFriendsData] = useState([]);
  const [openDropdownId, setOpenDropdownId] = useState(null); // Tracks the open dropdown's ID
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  // Fetch friends data from the API
  const fetchFriendsData = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.relations}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.data.status) {
        setFriendsData(response.data.payload);
      }
    } catch (error) {
      console.error("Error fetching friends data:", error);
    }
  };

  // Block friend API call
  const handleBlockFriend = async (id) => {
    const token = localStorage.getItem("token");

    const formData = new FormData();
    formData.append("id", id);
    formData.append("status", 3);
    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.relationsUpdate}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.data.status === true) {
        toast.success(response.data.message);
      }
    } catch (error) {
      console.error("Error blocking friend:", error);
    }
  };

  // Remove friend API call
  const handleRemoveFriend = async (id) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(`${API.BASE_URL}${API.ENDPOINTS.relations}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.data.status === true) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error removing friend:", error);
    }
  };

  // Handle dropdown toggle
  const toggleDropdown = (id) => {
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };

  // Handle navigation to the friends list
  const handleFiendsList = () => {
    navigate("/friends");
  };

  // Close dropdown when clicking outside
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropdownId(null); // Close any open dropdown
    }
  };

  useEffect(() => {
    fetchFriendsData(); // Fetch data when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const getSetSearchTerm = (e) => setSearchTerm(e.target.value);

  const filteredFriends = useMemo(() => {
    return friendsData.filter((friend) => friend?.receiver?.details?.pet_name?.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [friendsData, searchTerm]);

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="edit-profile">
          <div className="row">
            <div className="col-lg-12">
              <form className="card">
                <div className="card-body">
                  <div className="frinds-div d-flex justify-content-between">
                    <div className="title">Friends</div>
                    <div className="d-flex align-items-center">
                      {/* <div>
                        <button className="mt-0 btn btn-primary mx-2" onClick={handleFiendsList}>
                          <img src={Friendsimg} alt="notification-icon" style={{ width: "24px", height: "24px" }} />
                        </button>
                      </div> */}

                      <form className="search-form freind">
                        {/* <div className="form-group">
                          <input
                            className="form-control-plaintext searchIcon"
                            type="text"
                            placeholder="Search..."
                          />
                        </div> */}
                        <div className="input-group">
                          <span className="input-group-text bg-white" id="basic-addon1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                            </svg>
                          </span>
                          <input
                            type="text"
                            name="searchfriends"
                            value={searchTerm}
                            onChange={getSetSearchTerm}
                            className="form-control ps-2 border-start-0"
                            placeholder="Search..."
                            aria-label="Search..."
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="friends-list-container" id="friends-list-container">
                    {/* {friendsData.map((friend) => ( */}
                    {filteredFriends.map((friend) => (
                      <div className="ossn-users-list-item" key={friend.id}>
                        <div className="friends-list-controls-menu" id={`users-list-controls-menu-${friend.id}`}>
                          <img
                            className="img-responsive"
                            src={dotsImg}
                            alt="Options"
                            onClick={() => toggleDropdown(friend.id)} // Pass the friend's ID
                            style={{ cursor: "pointer" }}
                          />
                          {openDropdownId === friend.id && ( // Show dropdown only for the selected friend
                            <div className="custom-dropdown" ref={dropdownRef}>
                              <div>
                                <a className="users-list-controls-menu-item" href="/messages">
                                  <div>Message</div>
                                  <img className="img-responsive icon" src={messageFil} alt="Message Icon" />
                                </a>
                                <a className="users-list-controls-menu-item" onClick={() => handleBlockFriend(friend.id)}>
                                  <div>Block</div>
                                  <img className="img-responsive icon" src={blockImg} alt="Block Icon" />
                                </a>
                                <a className="users-list-controls-menu-item" onClick={() => handleRemoveFriend(friend.id)}>
                                  <div>Remove from friends</div>
                                  <img className="img-responsive icon" src={blockImg} alt="Remove Icon" />
                                </a>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="user-item-container">
                          <img className="img-responsive" src={friend.receiver.profile_img} width="100" height="100" alt="User Avatar" />
                          <div className="uinfo">
                            <a className="ossn-output-user-url userlink" data-username={friend.receiver.pet_name}>
                              {friend.receiver.details.pet_name}
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserFriends;
