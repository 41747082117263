import React, { useEffect, useState } from 'react';

const EditModal = ({ showModal, handleClose, postContent, handleSave }) => {
    const [editedContent, setEditedContent] = useState(postContent);
    const [title, setTitle] = useState(""); // State for the title
    const [description, setDescription] = useState(""); // State for the description

    // Sync the content with the modal when postContent changes
    useEffect(() => {
        setEditedContent(postContent);
        setTitle(postContent?.title || "");
        setDescription(postContent?.description || "");
    }, [postContent]);

    if (!showModal) return null;

    // Handle save action
    const handleSaveClick = () => {
        const updatedData = {
            ...editedContent,
            title,
            description,
        };
        handleSave(updatedData); // Pass the updated data back
        handleClose(); // Close the modal
    };

    return (
        <div id="editModal" className="modal" style={{ display: "block" }}>
            <div className="modal-content">
                <div className="newsfeed-middle">
                    <div>
                        <div className='d-flex justify-content-between'>
                            <div className="widget-heading">Edit</div>
                            <div className="close" onClick={handleClose}>X</div>
                        </div>
                        <div className="widget-body">
                            {/* Title Field */}
                            <div className="form-group">
                                <label htmlFor="title">Title</label>
                                <input
                                    type="text"
                                    id="title"
                                    className="form-control"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </div>

                            {/* Description Field */}
                            <div className="form-group mt-2">
                                <label htmlFor="description">Description</label>
                                <textarea
                                    id="description"
                                    className="form-control"
                                    rows="4"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                ></textarea>
                            </div>

                            {/* Save and Close Buttons */}
                            <div className="form-actions">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleSaveClick}
                                >
                                    Upload
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditModal;
