import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const [login, setLogin] = useState(JSON.parse(localStorage.getItem("login")) || false);
  const [authenticated, setAuthenticated] = useState(JSON.parse(localStorage.getItem("authenticated")) || false);

  useEffect(() => {
    // Update authenticated state from localStorage
    const storedAuthenticated = JSON.parse(localStorage.getItem("authenticated")) || false;
    setAuthenticated(storedAuthenticated);
    // Update login state from localStorage
    setLogin(JSON.parse(localStorage.getItem("login")) || false);
  }, []);

  return login || authenticated ? <Outlet /> : <Navigate exact to={`/login`} />;
};

export default PrivateRoute;
