import React, { useState } from 'react';
import { toast } from 'react-toastify'; // Assuming you're using react-toastify for notifications
import API from "../../utils";
import axios from "axios";

const InviteFriends = () => {
  const { BASE_URL, ENDPOINTS } = API;
  const [emailAddresses, setEmailAddresses] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const user_id = localStorage.getItem('user_id');
  const token = localStorage.getItem("token");

  const validate = () => {
    const newErrors = {};
    if (!emailAddresses) {
      newErrors.emailAddresses = 'Email addresses are required';
    } else {
      const emails = emailAddresses.split(',').map(email => email.trim());
      if (emails.some(email => !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email))) {
        newErrors.emailAddresses = 'Please enter valid email addresses separated by commas';
      }
    }
    if (!subject) newErrors.subject = 'Subject is required';
    if (!message) newErrors.message = 'Message is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      console.log('Validation failed', errors);
      return;
    }

    if (!user_id) {
      toast.error('User ID is missing');
      return;
    }

    setLoading(true);

    try {
      const inviteForm = new FormData();
      inviteForm.append("user_by", user_id); // Add user_id correctly
      const emails = emailAddresses.split(',').map(email => email.trim());
      emails.forEach((email, index) => {
        inviteForm.append(`reciver_mail[${index}]`, email); // Append each email
      });
      inviteForm.append("subject", subject);
      inviteForm.append("message", message);

      const response = await axios.post(`${BASE_URL}${ENDPOINTS.inviteFriends}`, inviteForm, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.status) {
        toast.success("Invitation sent successfully");
        setEmailAddresses('');
        setSubject('');
        setMessage('');
      } else {
        toast.error(response.data.message || "Failed to send invitation.");
      }
    } catch (error) {
      toast.error("Error sending invitation. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="ossn-widget">
      <div className="widget-heading">Invite Friends</div>
      <div className="widget-contents">
        <form onSubmit={handleSubmit} className="ossn-form ossn-edit-form" encType="multipart/form-data">
          <fieldset>
            <div className="ossn-invite-friends">
              <p>
                To invite friends to join you on this network, enter their email addresses, a subject, and a brief message.
                They will receive an email containing your invitation.
              </p>
              <label>Email addresses (separated by a comma)</label>
              <input
                type="text"
                name="addresses"
                className="mb-2"
                placeholder="smith@example.com, john@example.com"
                value={emailAddresses}
                onChange={(e) => setEmailAddresses(e.target.value)}
              />
              {errors.emailAddresses && <p className="error">{errors.emailAddresses}</p>}

              <label>Subject</label>
              <input
                type="text"
                name="subject"
                placeholder="Enter a subject"
                className="mb-2"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
              {errors.subject && <p className="error">{errors.subject}</p>}

              <label>Message</label>
              <textarea
                name="message"
                rows="4"
                placeholder="Write a message"
                className="mb-2"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              {errors.message && <p className="error">{errors.message}</p>}

              <button type="submit" className="btn btn-primary btn-sm" disabled={loading}>
                {loading ? "Sending..." : "Invite Friends"}
              </button>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default InviteFriends;
