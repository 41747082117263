import React from 'react';

const ShareModal = ({ showModal, handleClose, postId }) => {
    if (!showModal) return null;

    return (
        <>
            <div
                className="ossn-halt ossn-light"
                style={{ height: '10357px', position: 'fixed', display: 'block' }}
            ></div>
            <div className="ossn-message-box" style={{ display: 'block' }}>
                <div className="title">
                    Share
                    <div className="close-box" onClick={handleClose}>X</div>
                </div>
                <div className="contents">
                    <div className="ossn-box-inner">
                        <div style={{ width: '100%', margin: 'auto' }}>
                            <form
                                action="https://app.xpets.com/action/post/share"
                                id="share-post-form"
                                className="ossn-form"
                                method="post"
                                encType="multipart/form-data"
                            >
                                <fieldset>
                                    <input type="hidden" name="ossn_ts" value="1732683242" />
                                    <input type="hidden" name="ossn_token" value="43d92f178962e05f6ff6f7476c7986598c26bcdb69c7d2406daa64bd32223391" />
                                    <div>
                                        <label>Select Type</label>
                                        <select className="ossn-dropdown-input" name="type" id="sharepost-select-type">
                                            <option disabled selected hidden>Select Type</option>
                                            <option value="newsfeed">Newsfeed</option>
                                            <option value="friend">Friend Profile</option>
                                            <option value="group">Group</option>
                                        </select>
                                    </div>
                                    <input type="hidden" name="guid" value={postId} />
                                    <input type="hidden" name="share_type" value="object" />
                                    <input type="submit" className="hidden" id="sharepost-cb" value="Save" />
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="control">
                    <div className="controls">
                        <a href="javascript:void(0);" onClick={() => document.getElementById('sharepost-cb').click()} className="btn btn-primary btn-sm">Share</a>
                        <a href="javascript:void(0);" onClick={handleClose} className="btn btn-default btn-sm">Cancel</a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShareModal;
