import React from 'react';

const ReportModal = ({ showModal, handleClose, postId }) => {
    if (!showModal) return null;

    return (
        <>
            <div
                className="ossn-halt ossn-light"
                style={{ height: '10357px', position: 'fixed', display: 'block' }}
            ></div>
            <div className="ossn-message-box" style={{ display: 'block' }}>
                <div className="title">
                    Report this
                    <div className="close-box" onClick={handleClose}>X</div>
                </div>
                <div className="contents">
                    <div className="ossn-box-inner">
                        <div style={{ width: '100%', margin: 'auto' }}>
                            <form className="ossn-form">
                                <fieldset>
                                    <div>
                                        <label>Reason</label>
                                        <textarea name="reason"></textarea>
                                    </div>
                                    <input type="hidden" name="guid" value={postId} />
                                    <input type="hidden" name="type" value="post" />
                                    <input type="submit" className="hidden" id="ossn-file-report" />
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="control">
                    <div className="controls">
                        <a href="javascript:void(0);" onClick={() => document.getElementById('ossn-file-report').click()} className="btn btn-primary btn-sm">Save</a>
                        <a href="javascript:void(0);" onClick={handleClose} className="btn btn-default btn-sm">Cancel</a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReportModal;
