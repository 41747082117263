// // store/store.js
// import { configureStore } from '@reduxjs/toolkit';
// import createSagaMiddleware from 'redux-saga';
// import chatReducer from './features/chat/chatSlice';
// import themeReducer from './features/theme/themeCustomizerSlice';
// import rootSaga from '../sagas'; // Your root saga file

// // Create the saga middleware
// const sagaMiddleware = createSagaMiddleware();

// // Configure the store with reducers and saga middleware
// const store = configureStore({
//   reducer: {
//     chat: chatReducer,
//     theme: themeReducer,
//     // Add other reducers here...
//   },
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware().concat(sagaMiddleware), // Add saga middleware to the store
// });

// // Run the root saga
// sagaMiddleware.run(rootSaga);

// export default store;





import { configureStore } from "@reduxjs/toolkit";
import chatReducer from './features/chat/chatSlice'
import userReducer from './features/user/userSlice'
import themeReducer from "./features/theme/themeCustomizerSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    chat: chatReducer,
    theme: themeReducer,
  },
});
export default store;