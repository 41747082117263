import React, { useEffect, useRef, useState } from 'react';
import Public from '../../../../assets/images/sidebar/public.png';
import Share from '../../../../assets/images/sidebar/share.png';
import Comment from '../../../../assets/images/sidebar/message-blank.png';
import Like from '../../../../assets/images/sidebar/heart_new.png';
import Menu from '../../../../assets/images/sidebar/dots.png';
import defalutimages from "../../../../assets/images/user/user_default.jpg";

const ConfirmationModal = ({ showModal, handleClose, handleConfirm }) => {
    if (!showModal) return null;

    return (
        <>
            <div id="confirmationModal" className="modal" style={{ display: 'block' }}>
                <div className="modal-content">
                    <div className="title">
                        Are you sure you want to delete this post?
                        <div className="close" onClick={handleClose}>X</div>
                    </div>
                    <div className="modal-buttons">
                        <button className="btn btn-primary btn-sm" onClick={handleConfirm}>Yes</button>
                        <button className="btn btn-default btn-sm" onClick={handleClose}>Cancel</button>
                    </div>
                </div>
            </div>
        </>
    );
};

const ShareModal = ({ showModal, handleClose, postId }) => {
    if (!showModal) return null;

    return (
        <>
            <div
                className="ossn-halt ossn-light"
                style={{ height: '10357px', position: 'fixed', display: 'block' }}
            ></div>
            <div className="ossn-message-box" style={{ display: 'block' }}>
                <div className="title">
                    Share
                    <div className="close-box" onClick={handleClose}>X</div>
                </div>
                <div className="contents">
                    <div className="ossn-box-inner">
                        <div style={{ width: '100%', margin: 'auto' }}>
                            <form
                                action="https://app.xpets.com/action/post/share"
                                id="share-post-form"
                                className="ossn-form"
                                method="post"
                                encType="multipart/form-data"
                            >
                                <fieldset>
                                    <input type="hidden" name="ossn_ts" value="1732683242" />
                                    <input type="hidden" name="ossn_token" value="43d92f178962e05f6ff6f7476c7986598c26bcdb69c7d2406daa64bd32223391" />
                                    <div>
                                        <label>Select Type</label>
                                        <select className="ossn-dropdown-input" name="type" id="sharepost-select-type">
                                            <option disabled selected hidden>Select Type</option>
                                            <option value="newsfeed">Newsfeed</option>
                                            <option value="friend">Friend Profile</option>
                                            <option value="group">Group</option>
                                        </select>
                                    </div>
                                    <input type="hidden" name="guid" value={postId} />
                                    <input type="hidden" name="share_type" value="object" />
                                    <input type="submit" className="hidden" id="sharepost-cb" value="Save" />
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="control">
                    <div className="controls">
                        <a href="javascript:void(0);" onClick={() => document.getElementById('sharepost-cb').click()} className="btn btn-primary btn-sm">Share</a>
                        <a href="javascript:void(0);" onClick={handleClose} className="btn btn-default btn-sm">Cancel</a>
                    </div>
                </div>
            </div>
        </>
    );
};

const ReportModal = ({ showModal, handleClose, postId }) => {
    if (!showModal) return null;

    return (
        <>
            <div
                className="ossn-halt ossn-light"
                style={{ height: '10357px', position: 'fixed', display: 'block' }}
            ></div>
            <div className="ossn-message-box" style={{ display: 'block' }}>
                <div className="title">
                    Report this
                    <div className="close-box" onClick={handleClose}>X</div>
                </div>
                <div className="contents">
                    <div className="ossn-box-inner">
                        <div style={{ width: '100%', margin: 'auto' }}>
                            <form className="ossn-form" >
                                <fieldset>
                                    <div>
                                        <label>Reason</label>
                                        <textarea name="reason"></textarea>
                                    </div>
                                    <input type="hidden" name="guid" value={postId} />
                                    <input type="hidden" name="type" value="post" />
                                    <input type="submit" className="hidden" id="ossn-file-report" />
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="control">
                    <div className="controls">
                        <a href="javascript:void(0);" onClick={() => document.getElementById('ossn-file-report').click()} className="btn btn-primary btn-sm">Save</a>
                        <a href="javascript:void(0);" onClick={handleClose} className="btn btn-default btn-sm">Cancel</a>
                    </div>
                </div>
            </div>
        </>
    );
};

const EditCommentModal = ({ showModal, handleClose, commentText, setCommentText, handleSave, commentId }) => {
    if (!showModal) return null;

    const handleFormSubmit = (e) => {
        e.preventDefault();
        handleSave();
    };

    return (
        <>
            <div
                className="ossn-halt ossn-light"
                style={{ height: '10357px', position: 'fixed', display: 'block' }}
            ></div>
            <div className="ossn-message-box" style={{ display: 'block' }}>
                <div className="title">
                    Edit
                    <div className="close-box" onClick={handleClose}>X</div>
                </div>
                <div className="contents">
                    <div className="ossn-box-inner">
                        <div style={{ width: '100%', margin: 'auto' }}>
                            <form className="ossn-form" onSubmit={handleFormSubmit}>
                                <fieldset>
                                    <div>
                                        <textarea
                                            id="comment-edit"
                                            name="comment"
                                            value={commentText}
                                            onChange={(e) => setCommentText(e.target.value)}
                                            style={{ width: '100%', height: '100px' }}
                                        ></textarea>
                                        <input type="hidden" name="guid" value={commentId} />
                                        <input type="submit" className="hidden" id="ossn-comment-edit-save" />
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="control">
                    <div className="controls">
                        <button className="btn btn-primary btn-sm" onClick={handleFormSubmit}>Save</button>
                        <button className="btn btn-default btn-sm" onClick={handleClose}>Cancel</button>
                    </div>
                </div>
            </div>
        </>
    );
};

const ReportPost = ({ postId, userData = {}, initialPostContent }) => {
    const [showModal, setShowModal] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const [showReportModal, setShowReportModal] = useState(false);
    const [showEditCommentModal, setShowEditCommentModal] = useState(false);
    const [commentText, setCommentText] = useState('');
    const [comments, setComments] = useState([]);
    const [attachment, setAttachment] = useState(null);
    const [attachmentPreview, setAttachmentPreview] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [postContent, setPostContent] = useState(initialPostContent);
    const [editedContent, setEditedContent] = useState(initialPostContent);
    const [likes, setLikes] = useState(0);
    const [isLiked, setIsLiked] = useState(false);
    const [editingCommentIndex, setEditingCommentIndex] = useState(null);
    const [editingCommentText, setEditingCommentText] = useState('');
    const dropdownRef = useRef();
    const [openDropdowns, setOpenDropdowns] = useState({});

    const toggleDropdown = (id) => {
        setOpenDropdowns((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpenDropdowns({});
            }
        };
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const { avatarUrl = 'https://app.xpets.com/avatar/emmadenney/small/da8dc0cdb2eb6354040b8f15d1204862.jpeg', name, username } = userData;

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const handleDeletePost = () => {
        console.log(`Post ${postId} deleted`);
        setShowModal(false);
    };

    const handleCommentChange = (e) => {
        setCommentText(e.target.value);
    };

    const handleCommentPost = () => {
        if (commentText.trim()) {
            const newComment = { user: 'User', text: commentText };
            setComments((prevComments) => [...prevComments, newComment]);
            setCommentText('');
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setAttachment(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setAttachmentPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleEditPost = () => {
        setIsEditing(true);
    };

    const handleSaveEdit = () => {
        setIsEditing(false);
        setPostContent(editedContent);
    };

    const handleLikePost = () => {
        setIsLiked(!isLiked);
        setLikes((prevLikes) => (isLiked ? prevLikes - 1 : prevLikes + 1));
    };

    const handleSharePost = () => {
        setShowShareModal(true);
    };

    const handleCloseShareModal = () => {
        setShowShareModal(false);
    };

    const handleEditComment = (index) => {
        setEditingCommentIndex(index);
        setEditingCommentText(comments[index].text);
        setShowEditCommentModal(true); // Open the edit comment modal
    };

    const handleSaveCommentEdit = () => {
        const updatedComments = [...comments];
        updatedComments[editingCommentIndex].text = editingCommentText;
        setComments(updatedComments);
        setEditingCommentIndex(null);
        setEditingCommentText('');
        setShowEditCommentModal(false); // Close the edit comment modal
    };

    const handleShowReportModal = () => setShowReportModal(true);
    const handleCloseReportModal = () => setShowReportModal(false);
    const handleCloseEditCommentModal = () => setShowEditCommentModal(false);

    return (
        <div className="ossn-wall-item ossn-wall-item-476" id={`activity-item-${postId}`}>
            <div className="row">
                <div className="meta">
                    <img className="user-icon-small user-img" src={avatarUrl || defalutimages} alt="User Avatar" />
                    <div className="post-menu">
                        <div className='dropdown' ref={dropdownRef}>
                            <a
                                role="button"
                                className="btn btn-link p-0"
                                onClick={() => toggleDropdown(`post-${postId}`)}
                            >
                                <img
                                    src={Menu || defalutimages}
                                    alt="Menu"
                                />
                            </a>
                            {openDropdowns[`post-${postId}`] && (
                                <ul className="dropdown-menu multi-level dropdown-menu-end" role="menu">
                                    <li>
                                        <a
                                            className="dropdown-item post-control-report ossn-report-this"
                                            href="javascript:void(0);"
                                            onClick={handleShowReportModal}
                                        >
                                            Report this
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="dropdown-item post-control-delete ossn-wall-post-delete"
                                            onClick={handleShowModal}
                                        >
                                            Delete
                                        </a>
                                    </li>
                                </ul>
                            )}
                        </div>
                    </div>
                    <div className="user">
                        <a className="ossn-output-user-url" href={`https://app.xpets.com/u/${username}`}>
                            {name}
                        </a>
                        <i className="fa fa-check-circle verified-user verified-title" title="Verified Profile"></i>
                    </div>
                    <div className="post-meta">
                        <span className="time-created ossn-wall-post-time" title="25/11/2024">
                            15 hours ago
                        </span>
                        <span className="time-created">
                            - <img width="20px" height="20px" src={Public} alt="Public" />
                        </span>
                    </div>
                </div>
                <div className="post-contents">
                    {isEditing ? (
                        <textarea
                            value={editedContent}
                            onChange={(e) => setEditedContent(e.target.value)}
                        />
                    ) : (
                        <p>{postContent}</p>
                    )}
                    {isEditing && (
                        <button className="btn btn-primary btn-sm" onClick={handleSaveEdit}>
                            Save
                        </button>
                    )}
                </div>
                <div className="comments-likes">
                    <div className="menu-likes-comments-share">
                        <li>
                            <a href="javascript:void(0);" className="post-control-like" onClick={handleLikePost}>
                                <img src={Like} alt="Like" /> <span>{likes}</span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" className="post-control-comment comment-post">
                                <img src={Comment} alt="Comment" />
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" className="post-control-shareobject share-object-select" onClick={handleSharePost}>
                                <img src={Share} alt="Share" />
                            </a>
                        </li>
                    </div>
                </div>
                <div className="comments-list">
                    {comments.map((comment, index) => (
                        <div className="comments-item" key={index}>
                            <div className="row">
                                <div className="col-md-1">
                                    <img className="comment-user-img" src="https://app.xpets.com/avatar/Peterparker/smaller/ad9fe0f1e9f6dd15edd313ce7fe3507f.jpeg" alt="User Avatar" />
                                </div>
                                <div className="col-md-10">
                                    <div className="comment-contents">
                                        <p>
                                            <a className="ossn-output-user-url owner-link" href={`https://app.xpets.com/u/${username}`}>
                                                {name}
                                            </a>
                                            <span className="comment-text">{comment.text}</span>
                                        </p>
                                        <div className="comment-metadata">
                                            <div className="time-created">37 minutes ago</div>
                                            <a className="ossn-like-comment" href="javascript:void(0);">Like it</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-1 ">
                                    <div className="ossn-comment-menu">
                                        <div className='dropdown' ref={dropdownRef}>
                                            <a
                                                role="button"
                                                className="btn btn-link p-0"
                                                onClick={() => toggleDropdown(`comment-${index}`)}
                                            >
                                                <img
                                                    src={Menu}
                                                    alt="Menu"
                                                />
                                            </a>
                                            {openDropdowns[`comment-${index}`] && (
                                                <ul className="dropdown-menu  multi-level dropdown-menu-end" role="menu">
                                                    <li>
                                                        <a
                                                            className="dropdown-item post-control-report ossn-report-this"
                                                            href="javascript:void(0);"
                                                            onClick={handleShowReportModal}
                                                        >
                                                            Report this
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item post-control-delete ossn-wall-post-delete"
                                                            onClick={handleShowModal}
                                                        >
                                                            Delete
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item post-control-edit"
                                                            onClick={() => handleEditComment(index)}
                                                        >
                                                            Edit
                                                        </a>
                                                    </li>
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="comments-item">
                        <div className="row">
                            <div className="col-md-1">
                                <img className="comment-user-img" src={avatarUrl} alt="User Avatar" />
                            </div>
                            <div className="col-md-11">
                                <div className="ossn-comment-attach-photo" onClick={() => document.getElementById('file-input').click()}>
                                    <i className="fa fa-camera"></i>
                                </div>
                                <div className="ossn-comment-attach-photo">
                                    <i className="fa fa-smile"></i>
                                </div>
                                <span
                                    id="comment-box-p607"
                                    className="comment-box"
                                    contentEditable
                                    onInput={(e) => setCommentText(e.currentTarget.textContent)}
                                    placeholder="Leave a comment..."
                                    suppressContentEditableWarning
                                    dangerouslySetInnerHTML={{ __html: commentText }}
                                ></span>
                                <button className="btn btn-primary btn-sm comment-post-btn" onClick={handleCommentPost}>
                                    Post
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <input type="file" id="file-input" style={{ display: 'none' }} onChange={handleFileChange} />
            {attachmentPreview && (
                <div className="attachment-preview">
                    <img src={attachmentPreview} alt="Attachment Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                </div>
            )}
            <ConfirmationModal
                showModal={showModal}
                handleClose={handleCloseModal}
                handleConfirm={handleDeletePost}
            />
            <ShareModal
                showModal={showShareModal}
                handleClose={handleCloseShareModal}
                postId={postId}
            />
            <ReportModal
                showModal={showReportModal}
                handleClose={handleCloseReportModal}
                postId={postId}
            />
            <EditCommentModal
                showModal={showEditCommentModal}
                handleClose={handleCloseEditCommentModal}
                commentText={editingCommentText}
                setCommentText={setEditingCommentText}
                handleSave={handleSaveCommentEdit}
            />
        </div>
    );
};

export default ReportPost;
