import React, { Fragment, useEffect, useRef, useState } from "react";
import { Tab, Nav, Toast } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import API from "../../utils";
import { toast } from "react-toastify";
import { FaPen } from "react-icons/fa6";
import changeProfile from "../../assets/images/change-photo.svg";
import UserCustomCalendar from "./usertabsComponents/dailyDairy";
import UserTimeLine from "./usertabsComponents/timeLine";
import UserEvents from "./usertabsComponents/events";
import UserVideoLog from "./usertabsComponents/videoLog";
import UserProfile from "./usertabsComponents/profile";
import UserFriends from "./usertabsComponents/friends";
import UserPhotos from "./usertabsComponents/photos";

const Userprofileview = () => {
    const location = useLocation();
    const { uprofileid } = useParams();
    const searchParams = new URLSearchParams(location.search);
    const activeTabFromUrl = searchParams.get("tab") || "userprofile";
    const tabs = [
        { key: "userprofile", title: "Furry Profile" },
        { key: "userdairy", title: "Daily Diary" },
        { key: "usertimeline", title: "Timeline" },
        { key: "userfriends", title: "Furry Friends" },
        { key: "userphotos", title: "Photo Book" },
        { key: "uservideo", title: "Video Log" },
        { key: "userevents", title: "Events" },
    ];
    const [activeTab, setActiveTab] = useState(
        tabs.some((tab) => tab.key === activeTabFromUrl) ? activeTabFromUrl : "userprofile"
    );

    useEffect(() => {
        if (tabs.some((tab) => tab.key === activeTabFromUrl)) {
            setActiveTab(activeTabFromUrl);
        } else {
            setActiveTab("userprofile");
        }
    }, [activeTabFromUrl]);

    const [showCoverControls, setShowCoverControls] = useState(false);
    const [coverImage, setCoverImage] = useState("");
    const [profileImage, setProfileImage] = useState("");
    const [userData, setUserData] = useState({
        name: "",
        age: "",
        location: "",
        coverImage: "",
        profileImage: "",
    });

    const [data, setData] = useState({});

    const coverInputRef = useRef(null);
    const profileInputRef = useRef(null);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    useEffect(() => {
        fetchUserData();
    }, [uprofileid]);

    const handleImageUpload = async (file, type) => {
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append(type, file);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.profileUpdate}`,
                formData,
                {
                    headers: {
                        Authorization: 'Bearer ' + token,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.data.status === true) {
                fetchUserData()
                toast.success("Profile updated successfully!");

                if (type === 'cover_img') {
                    setCoverImage(response.data.payload.cover_img);
                } else if (type === 'profile_img') {
                    setProfileImage(response.data.payload.profile_img);
                }
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error updating profile data:", error);
        }
    };

    const handleCoverChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            handleImageUpload(file, 'cover_img');
        }
    };

    const handleProfileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            handleImageUpload(file, 'profile_img');
        }
    };

    const fetchUserData = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.profile}/${uprofileid}`,
                {
                    headers: {
                        Authorization: 'Bearer ' + token,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.data.status === true) {
                setData(response.data.payload.user);
                const data = response.data.payload.user;
                setUserData({
                    name: data.name,
                    age: data.user_details.pet_age,
                    location: data.user_details.location,
                    coverImage: data.cover_img || "",
                    profileImage: data.profile_img || "",
                });
                setCoverImage(data.cover_img || "");
                setProfileImage(data.profile_img || "");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error fetching owner data:", error);
            // toast.error("Failed to fetch owner data.");
        }
    };

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="ossn-profile">
                    <div className="top-container">
                        <div
                            id="container"
                            className="profile-cover"
                            onMouseEnter={() => setShowCoverControls(true)}
                            onMouseLeave={() => setShowCoverControls(false)}
                        >
                            {showCoverControls && (
                                <div
                                    className="profile-cover-controls"
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                >
                                </div>
                            )}

                            <img
                                id="draggable"
                                className="profile-cover-img"
                                src={coverImage}
                                alt="Profile Cover"
                                style={{ left: 0, top: '-100px' }}
                            />
                        </div>
                        <div className="profile-photo" id="profile-icon">
                            <img src={profileImage} alt="Profile" height="170" width="170" />
                        </div>
                        <div className="user-fullname" id="profile-fullname" data-verified="0">
                            {userData.name}
                            <img
                                src="https://app.xpets.com/themes/xpets/images/orange-tick.png"
                                alt="Verified Tick"
                            />
                        </div>
                        <div className="pet-detail-container">
                            <div
                                className="pet-detail"
                                id="pet-detail-age"
                                style={{ border: '1px solid rgb(215, 230, 222)', background: 'rgba(247, 254, 250, 0.8)' }}
                            >
                                <img src="https://app.xpets.com/themes/xpets/images/gift.png" alt="Gift Icon" />
                                <div className="pet-age" id="pet-age">{userData.age} years old</div>
                            </div>
                            <div
                                className="pet-detail"
                                id="pet-detail-location"
                                style={{ border: '1px solid rgb(215, 230, 222)', background: 'rgba(247, 254, 250, 0.8)' }}
                            >
                                <img src="https://app.xpets.com/themes/xpets/images/pin.png" alt="Location Icon" />
                                <div className="profile-location" id="profile-location">{userData.location}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="tabination-div">
                    <Tab.Container activeKey={activeTab} onSelect={(key) => setActiveTab(key)}>
                        <div>
                            <div id="profile-hr-menu" className="profile-hr-menu d-block d-sm-none">
                                <ul>
                                    <li className="dropdown">
                                        <a
                                            onClick={toggleDropdown}
                                            aria-expanded={isDropdownOpen}
                                        >
                                            <i className="fa fa-bars fa-2x"></i>
                                        </a>
                                        {isDropdownOpen && (
                                            <ol className={`dropdown-menu`}>
                                                {tabs.map(({ key, title }) => (
                                                    <li key={key}>
                                                        <a
                                                            className={`dropdown-item menu-user-timeline-${key}`}
                                                            onClick={() => setActiveTab(key)}
                                                            href={`?tab=${key}`}
                                                        >
                                                            {title}
                                                        </a>
                                                    </li>
                                                ))}
                                            </ol>
                                        )}
                                    </li>
                                </ul>
                            </div>

                            <div className="d-none d-sm-block">
                                <Nav variant="tabs">
                                    {tabs.map(({ key, title }) => (
                                        <Nav.Item key={key}>
                                            <Nav.Link eventKey={key} className="tab-name">
                                                {title}
                                            </Nav.Link>
                                        </Nav.Item>
                                    ))}
                                </Nav>
                            </div>
                        </div>

                        <Tab.Content className="mt-3">
                            {tabs.map(({ key }) => (
                                <Tab.Pane eventKey={key} key={key}>
                                    {key === "usertimeline" && <UserTimeLine />}
                                    {key === "userprofile" && <UserProfile />}
                                    {key === "userdairy" && <UserCustomCalendar />}
                                    {key === "userfriends" && <UserFriends />}
                                    {key === "userphotos" && <UserPhotos />}
                                    {key === "uservideo" && <UserVideoLog activeTab={activeTab} />}
                                    {key === "userevents" && <UserEvents />}
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>
        </Fragment>
    );
};

export default Userprofileview;
