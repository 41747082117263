import React, { Fragment, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../common/Loader";
import API from "../../../utils";
import { getData, postData } from "../../../utils/axiosApiReq";
import InfiniteScroll from "react-infinite-scroll-component";
import plusImg from '../../../assets/images/plus.png'
import { FaPlus } from "react-icons/fa";

const VideoLog = ({ activeTab }) => {
  const { BASE_URL, ENDPOINTS } = API;
  const [loading, setLoading] = useState(false);

  const [videos, setVideos] = useState([]);
  const [loadingVideos, setLoadingVideos] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [perPageVideos, setPerPageVideos] = useState(10);

  const [isModalOpen, setIsModalOpen] = useState(false);

  // Fetch video log data
  const getVideoLog = async (per_page = 10, page = 1) => {
    try {
      setLoadingVideos(true);
      const response = await getData(ENDPOINTS.getvideolog, { per_page, page });
      const { status, message, payload } = response;
      if (status) {
        // Append new videos to the existing list
        setVideos((prevVideos) => [...prevVideos, ...payload]);
        // Determine if there is more data to fetch
        if (payload.length < per_page) {
          setHasMore(false); // No more data to fetch
        }
      } else {
        // Handle error if any
        console.error(message);
        toast.error(message || "Error fetching video log");
      }
    } catch (error) {
      toast.error(error || "Error fetching video log");
    } finally {
      setLoadingVideos(false);
    }
  };

  // Fetch initial video log on component mount
  useEffect(() => {
    // getVideoLog();
    getVideoLog(perPageVideos);
  }, []);

  // Handle fetching more videos on scroll
  const fetchMoreVideos = () => {
    if (!loadingVideos && hasMore) {
      setPage((prevPage) => prevPage + 1); // Increment page number
      getVideoLog(perPageVideos, page + 1); // Fetch next page of videos
      // getVideoLog(10, page + 1); // Fetch next page of videos
    }
  };

  const [newVideo, setNewVideo] = useState({
    title: "",
    description: "",
    file: null,
    visibility: "Public",
    // thumbnail: "",
    // videoUrl: "",
  });

  const toggleModal = () => {
    setErrors({});
    setNewVideo({ title: "", description: "", file: null, visibility: "Public" });
    setIsModalOpen(!isModalOpen);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewVideo({ ...newVideo, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedFormats = ["video/mp4", "video/mkv", "video/avi"];
      if (!allowedFormats.includes(file.type)) {
        setErrors({ ...errors, file: "Invalid video format. Only MP4, MKV, and AVI are allowed." });
        return;
      }
      setErrors({ ...errors, file: null }); // Clear file error if format is valid
      setNewVideo({ ...newVideo, file });
    }
  };

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!newVideo.title.trim()) newErrors.title = "Title is required.";
    if (!newVideo.description.trim()) newErrors.description = "Description is required.";
    if (!newVideo.file) newErrors.file = "Please select a video file.";
    if (newVideo.file && !["video/mp4", "video/mkv", "video/avi"].includes(newVideo.file.type)) {
      newErrors.file = "Invalid video format. Only MP4, MKV, and AVI are allowed.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const [isButtonLoading, setIsButtonLoading] = useState(false); // New state for button loading

  const handleAddVideo = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    if (!validateForm()) return;

    try {
      setIsButtonLoading(true); // Set but
      const formData = new FormData();
      formData.append("title", newVideo.title);
      formData.append("description", newVideo.description);
      formData.append("file", newVideo.file);
      formData.append("status", newVideo.visibility == "Public" ? 0 : 1);

      // API call
      const response = await postData(ENDPOINTS.addVideoLog, formData, true);
      const { status, message, payload } = response;
      if (status) {
        // getVideoLog(10, page);
        setVideos((prevVideos) => [payload, ...prevVideos]);
        setNewVideo({ title: "", description: "", file: null, visibility: "Public" });
        setIsModalOpen(false);
        toast.success(message || "Video uploaded successfully.");
      } else {
        toast.error(message || "Failed to upload video. Please try again.");
      }
    } catch (error) {
      toast.error(error.response?.data.message);
    } finally {
      setIsButtonLoading(false); // Reset button loading state
    }
  };
  const navigate = useNavigate();
  const handleVideoClick = (video, index) => {
    navigate(`/video-details/${index}`, { state: { video } });
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="edit-profile">
          <div className="row">
            <div className="col-lg-12">
              <form className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="title">Videos</div>
                    <div class="controls">
                      <a id="ossn-add-album" onClick={toggleModal}>
                        <FaPlus />
                        Add Video
                      </a>
                    </div>
                  </div>
                  <div id="videoScrollableDiv" className="video-list mt-4">
                    {activeTab === "video" && (
                      <InfiniteScroll
                        dataLength={videos.length}
                        next={fetchMoreVideos}
                        // next={() => setPage((prevPage) => prevPage + 1)}
                        // next={fetchMoreData}  // Trigger fetch when user scrolls to the bottom
                        hasMore={hasMore} // Whether there is more data to load
                        // loader={<h4>Loading...</h4>}
                        endMessage={<h6 className="d-none ">No more videos to load.</h6>} // Message when there are no more videos
                      // scrollableTarget="videoScrollableDiv"
                      >
                        <div className="video-list mt-4">
                          {videos.length > 0 ? (
                            videos.map((video, index) => (
                              <div key={video?.id} className="video-item mb-4 d-flex" onClick={() => handleVideoClick(video, video?.id)} style={{ cursor: "pointer" }}>
                                {video?.file && (
                                  <video
                                    style={{
                                      marginRight: "15px",
                                      width: "380px",
                                      height: "220px",
                                      objectFit: "cover",
                                    }}
                                  >
                                    <source src={video?.file} type="video/mp4" />
                                    Your browser does not support the video tag.
                                  </video>
                                )}
                                <div>
                                  <h5 className="video-title">{video?.title || "-"}</h5>
                                  <p>{video.description?.length > 400 ? `${video.description.substring(0, 400)}...` : video.description}</p>
                                  <div className="video-posted-by">
                                    Posted By <a href="#">{video.posted_by?.pet_name}</a>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <h6 className="no-video-message">No videos added yet.</h6>
                          )}
                        </div>
                      </InfiniteScroll>
                    )}
                    {/* {videos.length > 0 ? (
                      videos.map((video, index) => (
                        <div key={video?.id} className="video-item mb-4 d-flex" onClick={() => handleVideoClick(video, video?.id)} style={{ cursor: "pointer" }}>
                          {video?.file && (
                            <video style={{ marginRight: "15px", width: "380px", height: "220px", objectFit: "cover" }}>
                              <source src={video?.file} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          )}
                          <div>
                            <h5 className="video-title">{video?.title || "-"}</h5>
                            <p>{video.description}</p>
                            <p>
                              <strong>Posted By:</strong> {video?.status ? "Private" : "Public"}
                            </p>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>No videos added yet.</p>
                    )}
                    {loadingVideos && <p>Loading more videos...</p>}
                    {!hasMore && <p>No more videos to load.</p>} */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for adding videos */}
      <Modal show={isModalOpen} onHide={toggleModal}>
        <form onSubmit={handleAddVideo}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Video</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="videoTitle" className="d-flex">
                Video Title
              </label>
              <input type="text" className="form-control" id="videoTitle" name="title" value={newVideo.title} onChange={handleInputChange} placeholder="Enter video title" />
              {errors.title && <p className="error text-danger text-start">{errors.title}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="videoDescription" className="d-flex">
                Description
              </label>
              <textarea
                className="form-control"
                id="videoDescription"
                name="description"
                value={newVideo.description}
                onChange={handleInputChange}
                placeholder="Enter video description"
              ></textarea>
              {errors.description && <p className="error text-danger text-start">{errors.description}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="videoFile" className="d-flex">
                Select Video
              </label>
              <input type="file" className="form-control" id="videoFile" accept="video/*" onChange={handleFileChange} />
              {errors.file && <p className="error text-danger text-start">{errors.file}</p>}
            </div>
            <div className="form-group">
              <label className="d-flex">Visibility</label>
              <div className="d-flex">
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    id="publicOption"
                    name="visibility"
                    value="Public"
                    checked={newVideo.visibility === "Public"}
                    onChange={handleInputChange}
                    className="form-check-input"
                  />
                  <label className="form-check-label" htmlFor="publicOption">
                    Public
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    id="friendsOption"
                    name="visibility"
                    value="Friends"
                    checked={newVideo.visibility === "Friends"}
                    onChange={handleInputChange}
                    className="form-check-input"
                  />
                  <label className="form-check-label" htmlFor="friendsOption">
                    Friends
                  </label>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="primary" onClick={handleAddVideo}> */}
            <Button variant="primary" type="submit" disabled={isButtonLoading}>
              {isButtonLoading ? (
                <span>
                  <i className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></i>
                  Saving...
                </span>
              ) : (
                "Save"
              )}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </Fragment>
  );
};

export default VideoLog;
