import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loader = () => {
  return (
    <div
      className="ossn-page-loading-annimation"
    >
      <div className="ossn-page-loading-annimation-inner">
        <div className="ossn-loading"></div>
      </div>
    </div>
  );
};

export default Loader;
