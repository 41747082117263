import { createSlice } from '@reduxjs/toolkit';

// Helper to get user data from localStorage
const getUserFromLocalStorage = () => {
  const user = localStorage.getItem('user');
  return user ? JSON.parse(user) : null;
};

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userInfo: getUserFromLocalStorage(),
  },
  reducers: {
    setUser: (state, action) => {
      state.userInfo = action.payload;
      localStorage.setItem('user', JSON.stringify(action.payload));
    },
    clearUser: (state) => {
      state.userInfo = null;
      localStorage.removeItem('user');
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;

export const selectUserId = (state) => state.user.userInfo?.id;

export default userSlice.reducer;
