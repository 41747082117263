import React, { Fragment, useState, useEffect } from "react";
import man from "../../../assets/images/user/user_default.jpg";
import { Link, useNavigate } from "react-router-dom";
import SignOut from "../../../assets/images/sidebar/logout.png";
import defalutimages from "../../../assets/images/user/user_default.jpg";
import axios from "axios";
import API from "../../../utils";
import { toast } from "react-toastify";
import ConfirmationModalLogout from "./logoutmodel";

const UserMenu = () => {
  const [profile, setProfile] = useState("");
  const [userName, setUserName] = useState("");
  const [showModal, setShowModal] = useState(false); // State to control the modal visibility
  const navigate = useNavigate();

  const uid = localStorage.getItem("user_id");
  const fetchUserData = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${API.BASE_URL}${API.ENDPOINTS.profile}/${uid}`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status === true) {
        setUserName(response.data.payload.user.details.pet_name);
        setProfile(response.data.payload.user.profile_img || man);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching owner data:", error);
      // toast.error("Failed to fetch owner data.");
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const Logout = () => {
    localStorage.clear();
    navigate(`/login`);
  };

  const handleSignOutClick = () => {
    setShowModal(true); // Show the modal
  };

  const handleCloseModal = () => {
    setShowModal(false); // Hide the modal
  };

  const handleConfirmLogout = () => {
    setShowModal(false); // Hide the modal
    Logout(); // Call the logout function
  };

  return (
    <>
      <Fragment>
        <li className="onhover-dropdown">
          <Link to={`/users/userEdit`}>
            <div className="d-flex align-items-center newsfeed-uinfo user-header">
              <img
                className="user-header-img"
                src={profile || defalutimages}
                alt="header-user"
              />
              <div className="name">{userName}</div>
            </div>
          </Link>
          <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
            <Link to={`/users/userEdit`}>
              <li>Profile</li>
            </Link>
            <Link
              className="menu-topbar-dropdown-reported_contents"
              to={`/report/list`}
            >
              <li>Reported contents</li>
            </Link>
            <li>
              <a onClick={handleSignOutClick}>
                {"Sign Out"}{" "}
                <img src={SignOut} alt="Sign Out" className="logout-header" />
              </a>
            </li>
          </ul>
        </li>

      </Fragment>
      <ConfirmationModalLogout
        showModal={showModal}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirmLogout}
      />
    </>
  );
};

export default UserMenu;
