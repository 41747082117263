import React, { useEffect, useRef, useState } from "react";
import DateTime from "react-datetime";
import ReactCountryDropdown from "react-country-dropdown";
import API from "../../../../utils";
import { postData } from "../../../../utils/axiosApiReq";
import Loader from "../../../common/Loader";
import { toast } from "react-toastify";
import axios from "axios";
import { FaTrash } from "react-icons/fa6";

const AddEventModal = ({ showModal, handleClose, handleAddEvent, getEvents }) => {
  const { BASE_URL, ENDPOINTS } = API;
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    details: "",
    country: "",
    start_time: "",
    end_time: "",
    location: "",
    date: "",
    image: null,
    // event_cost: "", // allowed_comments: "",// is_finished: "",
  });

  const [guid] = useState(430);
  const [errors, setErrors] = useState({});
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    setErrors({});
    setFormData({ name: "", details: "", country: "", start_time: "", end_time: "", location: "", date: "", image: null });
  }, [showModal])
  

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle file upload
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Validate file format
      if (!["image/jpeg", "image/png", "image/jpg"].includes(file.type)) {
        setErrors((prevErrors) => ({ ...prevErrors, image: "Image must be in JPG, JPEG, or PNG format" }));
        return; // Don't update formData if the file is invalid
      }

      setFormData((prev) => ({ ...prev, image: file }));
      setImagePreview(URL.createObjectURL(file));

      // Clear image error if the file is valid
      setErrors((prevErrors) => {
        const { image, ...rest } = prevErrors;
        return rest;
      });
    }
  };
  /*const handleFileChange = (e) => {
    // setFormData((prev) => ({...prev,image: e.target.files[0],}));
    const file = e.target.files[0];
    if (file) {
      setFormData((prev) => ({ ...prev, image: file }));
      setImagePreview(URL.createObjectURL(file));
    }
  };*/

  // Remove uploaded image
  const handleRemoveImage = () => {
    setFormData((prev) => ({
      ...prev,
      image: null,
    }));
    setImagePreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset file input
    }
  };

  // Handle date changes
  const handleDateChange = (date) => {
    setFormData((prev) => ({
      ...prev,
      date: date,
      //   date: date.format("MM-DD-YYYY"), // Format date as needed
    }));
  };

  // Handle country change
  const handleCountryChange = (country) => {
    setFormData((prev) => ({ ...prev, country }));
  };

  // Validation
  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Event name is required";
    if (!formData.details) newErrors.details = "Event description is required";
    if (!formData.country) newErrors.country = "Country is required";
    if (!formData.start_time) newErrors.start_time = "Start time is required";
    if (!formData.end_time) newErrors.end_time = "End time is required";
    if (!formData.location) newErrors.location = "Location is required";
    // if (!formData.image) newErrors.image = "Image is required";
    if (!formData.image) {
      newErrors.image = "Image is required";
    } else if (formData.image && !["image/jpeg", "image/png", "image/jpg"].includes(formData.image.type)) {
      newErrors.image = "Image must be in JPG, JPEG, or PNG format";
    }
    if (!formData.date) {
      newErrors.date = "Date is required";
    } else {
      // Normalize both the current date and the selected date to 'yyyy-mm-dd' format
      const selectedDate = new Date(formData.date);
      const currentDate = new Date();

      // Set both dates to midnight to ignore the time portion
      selectedDate.setHours(0, 0, 0, 0);
      currentDate.setHours(0, 0, 0, 0);

      // Compare only the date parts
      if (selectedDate < currentDate) {
        newErrors.date = "Date must be today or in the future";
      }
    }
    return newErrors;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("click", errors);
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      console.log("if");
    } else {
      console.log("else", errors);

      setErrors({});
      // Add your submission logic here
      try {
        setLoading(true);
        const eventFormData = new FormData();
        eventFormData.append("name", formData.name);
        eventFormData.append("details", formData.details);
        eventFormData.append("country", formData.country);
        eventFormData.append("start_time", formData.start_time);
        eventFormData.append("end_time", formData.end_time);
        eventFormData.append("location", formData.location);
        eventFormData.append("date", formData.date.toISOString().split("T")[0]);
        eventFormData.append("image", formData.image);
        const response = await postData(ENDPOINTS.addevent, eventFormData, true);
        const { status, message, payload } = response;
        if (status) {
          getEvents()
          toast.success(message || "Event created successfullt.");
          setFormData({ name: "", details: "", country: "", start_time: "", end_time: "", location: "", date: "", image: null });
          setImagePreview(null);
          handleClose();
        } else {
          toast.error(message || "Failed to create event.");
        }
      } catch (error) {
        toast.error(error.response?.data.message);
      } finally {
        setLoading(false);
      }
      //   handleAddEvent(formData);
    }
  };

  if (!showModal) return null;

  if (loading) {
    return <Loader />;
  }
  return (
    <div id="editModal" className="modal" style={{ display: "block" }}>
      <div className="modal-content">
        <div className="newsfeed-middle">
          <div>
            <div className="d-flex justify-content-between">
              <div className="widget-heading">Edit</div>
              <div className="close" onClick={handleClose}>
                X
              </div>
            </div>
            <div className="widget-body">
              <form onSubmit={handleSubmit} className="ossn-form" encType="multipart/form-data">
                <fieldset>
                  {/* Event name */}
                  <div>
                    <label>Event Name</label>
                    <input type="text" name="name" value={formData.name} onChange={handleChange} />
                    {errors.name && <p className="error">{errors.name}</p>}
                  </div>

                  {/* Event Description */}
                  <div>
                    <label>Event Description</label>
                    <textarea name="details" value={formData.details} onChange={handleChange} />
                    {errors.details && <p className="error">{errors.details}</p>}
                  </div>

                  {/* Country */}
                  <div id="addEventModal_Country">
                    <label>Country</label>
                    <ReactCountryDropdown defaultCountry="IN" onSelect={(country) => handleCountryChange(country.name)} className="form-control"/>
                    {errors.country && <p className="error">{errors.country}</p>}
                  </div>

                  {/* Start Time */}
                  <div className="time-picker-start">
                    <label>Start Time</label>
                    <input type="time" name="start_time" value={formData.start_time} onChange={handleChange} className="form-control" />
                    {errors.start_time && <p className="error">{errors.start_time}</p>}
                  </div>

                  {/* End Time */}
                  <div className="time-picker-end">
                    <label>End Time</label>
                    <input type="time" name="end_time" value={formData.end_time} onChange={handleChange} className="form-control" />
                    {errors.end_time && <p className="error">{errors.end_time}</p>}
                  </div>

                  {/* Location */}
                  <div>
                    <label>Location</label>
                    <input type="text" name="location" value={formData.location} onChange={handleChange} />
                    {errors.location && <p className="error">{errors.location}</p>}
                  </div>

                  {/* Date */}
                  <div>
                    <label>Date</label>
                    <DateTime
                      value={formData.date}
                      onChange={handleDateChange}
                      dateFormat="MM/DD/YYYY"
                      timeFormat={false}
                      inputProps={{ readOnly: true }}
                      isValidDate={(currentDate) => {
                        return currentDate.isSameOrAfter(new Date(), "day"); // Allow only today or future dates
                      }}
                    />
                    {/* <DateTimePicker onChange={handleDateChange} value={formData.date} disableClock={true} minDate={new Date()} /> */}
                    {errors.date && <p className="error">{errors.date}</p>}
                  </div>

                  {/* Image Upload */}
                  <div>
                    <label>Image</label>
                    <input type="file" name="image" onChange={handleFileChange} ref={fileInputRef} />
                    {errors.image && <p className="error">{errors.image}</p>}
                    {imagePreview && (
                      <div className="image-preview">
                        <img src={imagePreview} alt="Preview" className="w-100" />
                        <button type="button" onClick={handleRemoveImage} className="btn btn-light">
                        <FaTrash />
                        </button>
                      </div>
                    )}
                  </div>

                  {/* Submit Button */}
                  <div className="mt-3">
                    {/* <input type="submit" value="Save" className="btn btn-primary" /> */}
                    <button type="submit" value="Save" className="btn btn-primary">
                      Save
                    </button>
                  </div>

                  {/* <div>
                    <label>Price (if any)</label>
                    <input type="text" name="event_cost" value={formData.event_cost} onChange={handleChange} />
                  </div>

                  <div>
                    <label>Allow Comments & Likes</label>
                    <select name="allowed_comments" value={formData.allowed_comments} onChange={handleChange}>
                      <option value="1">Allowed</option>
                      <option value="0">Not Allowed</option>
                    </select>
                  </div>

                  <div>
                    <label>Close the Event? (Finished)</label>
                    <select name="is_finished" value={formData.is_finished} onChange={handleChange}>
                      <option value="no">No</option>
                      <option value="yes">Yes</option>
                    </select>
                  </div> */}
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEventModal;
