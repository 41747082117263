import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import man from "../../../assets/images/user/user_default.jpg";
import Friendsimg from "../../../assets/images/plus.png";
import API from '../../../utils';
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";

const FriendsList = () => {
  const token = localStorage.getItem('token');
  const [activeTab, setActiveTab] = useState("requests");
  const [friendRequests, setFriendRequests] = useState([]);
  const [friendSuggestions, setFriendSuggestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [offset, setOffset] = useState(1);
  const [limit] = useState(10);
  const [hasMore, setHasMore] = useState(true);

  // Fetch friend requests
  const fetchFriendsData = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.friendRequests}`, {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.data.status) {
        setFriendRequests(response.data.payload);
      }
    } catch (error) {
      console.error("Error fetching friends data:", error);
    }
  };

  // Fetch friend suggestions
  const fetchFriendSuggestions = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.friendSuggestions}`, {
        params: { offset, limit },
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.status) {
        const newSuggestions = response.data.payload;
        setFriendSuggestions((prevSuggestions) => [
          ...prevSuggestions,
          ...newSuggestions,
        ]);
        setHasMore(newSuggestions.length === limit);
        setOffset((prevOffset) => prevOffset + 1);
      }
    } catch (error) {
      console.error("Error fetching friend suggestions:", error);
    }
  };

  const handleAddFriends = async (id) => {
    const token = localStorage.getItem('token');

    const formData = new FormData();
    formData.append("receive_by", id);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.relations}`,
        formData,
        {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.data.status === true) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error updating profile data:", error);
    }
  };


  // Handle suggestion reject request
  const handleSuggetionRejectRequest = async (id) => {
    debugger
    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.removeSuggestion}/${id}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      if (response.data.status) {
        setFriendSuggestions((prevSuggestions) =>
          prevSuggestions.filter((suggestion) => suggestion.id !== id)
        );
        toast.success("Suggestion removed!");
      }
    } catch (error) {
      toast.error("Failed to remove suggestion.");
    }
  };

  // Handle remove suggestion
  const handleRemoveSuggestion = (id) => {
    setFriendSuggestions((prevSuggestions) =>
      prevSuggestions.filter((suggestion) => suggestion.id !== id)
    );
  };
  const handleAcceptRequest = async (id) => {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('id', id);
    formData.append('status', 1);

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.followRequests}`, formData, {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.status) {
        toast.success(response.data.message);
        setFriendRequests((prevRequests) =>
          prevRequests.filter((request) => request.id !== id)
        );

        // fetchFriendsData();
      } else {
        console.log('Failed to update the friend request. Please try again.');
      }
    } catch (error) {
      console.error("Error updating friend request:", error);
    }
  };
  // Fetch data based on active tab
  useEffect(() => {
    if (activeTab === "requests") {
      fetchFriendsData();
    } else if (activeTab === "suggestions") {
      fetchFriendSuggestions();
    }
  }, [activeTab]);

  // Filtered suggestions based on search term
  const filteredSuggestions = friendSuggestions.filter((suggestion) =>
    suggestion?.user_details?.pet_name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="ossn-layout-module px-0 bg-none" style={{ backgroundColor: 'rgb(240, 240, 240, 0%)', boxShadow: 'none' }}>
          <div className="row">
            <div className="col-lg-12">
              <form className="card" onSubmit={(e) => e.preventDefault()}>
                <div className="card-body">
                  {/* Tabs */}
                  <ul className="nav nav-tabs">
                    <li
                      className={`nav-item ${activeTab === "requests" ? "tab-active" : "not-active"}`}
                      onClick={() => setActiveTab("requests")}
                    >
                      <a className="nav-link">Friend Requests</a>
                    </li>
                    <li
                      className={`nav-item ${activeTab === "suggestions" ? "tab-active" : "not-active"}`}
                      onClick={() => setActiveTab("suggestions")}
                    >
                      <a className="nav-link">Friend Suggestions</a>
                    </li>
                  </ul>

                  {/* Tab Content */}
                  <div className="tab-content mt-3">
                  {activeTab === "requests" && (
                      <div className="tab-pane active">
                        {friendRequests.length === 0 ? (
                          <p>No friend requests available.</p>
                        ) : (
                          <div className="row">
                            {friendRequests.map((request) => (
                              <div key={request.id} className="col-lg-4 col-md-4 col-sm-4 p-0 m-0 d-flex align-items-center">
                                <div className="card text-center  m-0" style={{ position: "relative" }}>
                                  <img src={request.receiver.profile_img || man} alt="Default" className="card-img-top" style={{ width: "100px", height: "100px", objectFit: "cover", borderRadius: "50%", margin: "10px auto" }} />
                                  <div className="card-body">
                                    <h6 className="card-title">{request?.receiver?.user_details?.pet_name || ""}</h6>
                                    <div>
                                      <button
                                        className="btn btn-success btn-sm mx-2"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleAcceptRequest(request.id);
                                        }}
                                      >
                                        Accept
                                      </button>
                                      <button
                                        className="btn btn-danger btn-sm"
                                        // onClick={(e) => {
                                        //   e.preventDefault();
                                        //   handleRejectRequest(request.id);
                                        // }}
                                      >
                                        Reject
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    )}

                    {activeTab === "suggestions" && (
                      <>
                        <div className="mb-3 row">
                          <input
                            type="text"
                            className="form-control w-25"
                            placeholder="Search friends..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />
                        </div>
                        <InfiniteScroll
                          dataLength={filteredSuggestions.length}
                          next={fetchFriendSuggestions}
                          hasMore={hasMore}
                          style={{overflow:'none'}}
                          loader={
                            <h6 className="text-center">
                              <Spinner animation="border" role="status" className="content-loader">
                                <span className="visually-hidden">Loading...</span>
                              </Spinner>
                            </h6>
                          } 
                          endMessage={<p className="d-none">No more posts to load</p>} 
                        >
                          <div className="row m-0">
                            {filteredSuggestions.map((suggestion) => (
                              <div key={suggestion.id} className="col-lg-3 col-md-4 col-sm-6 mb-3 d-flex flex-column ">
                                <div className="card text-center" style={{ position: "relative" }}>
                                  <img
                                    src={suggestion.profile_img || man}
                                    alt="Default"
                                    className="card-img-top"
                                    style={{ width: "100px", height: "100px", objectFit: "cover", borderRadius: "50%", margin: "10px auto" }}
                                  />
                                  <button
                                    className="mt-0 btn btn-danger btn-sm"
                                    style={{ position: "absolute", top: "5px", right: "5px", borderRadius: "50%", padding: "5px 7px" }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleSuggetionRejectRequest(suggestion.id)
                                    }}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </button>
                                  <div className="card-body">
                                    <h6 className="card-title">{suggestion?.user_details?.pet_name || ""}</h6>
                                    {suggestion.request_sent ? (
                                      <button
                                        className="btn btn-danger"
                                        onClick={() => handleSuggetionRejectRequest(suggestion.id)}
                                      >
                                        Cancel
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-primary"
                                        onClick={() => handleAddFriends(suggestion.id)}
                                      >
                                        Add Friend
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </InfiniteScroll>
                      </>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FriendsList;
