import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";

import { ChatContext } from "./ChatContext";
import { FaTrashCan, FaEllipsisVertical } from "react-icons/fa6";
import { RxPaperPlane } from "react-icons/rx"; //
import { FaPaperclip, FaSmile, FaBell, FaTrash } from "react-icons/fa";
import EmojiPicker from "emoji-picker-react"; // Install `emoji-picker-react`
import DeleteModal from "./DeleteModal";
import paperClip from "../../assets/images/themes/paper-clip.png";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import errImg from '../../assets/images/themes/fozzy.jpg'
import Loader from "../common/Loader";
var images = require.context("../../assets/images", true);

const ChatUI = () => {
  const { chatType, loading, error, sendingMessage, errorSendingMessage } = useSelector((state) => state.chat);

  const { userInfo } = useSelector((state) => state.user);
  const [petErrImg, setPetErrImg] = useState(errImg);
  const {
    selectedUserChat,
    setSelectedUserChat,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    openDeleteModal,
    closeDeleteModal,
    message,
    setMessage,
    selectedFiles,
    setSelectedFiles,
    showEmojiPicker,
    setShowEmojiPicker,
    fileInputRef,
    activeTab,
    setActiveTab,
    searchTerm,
    setSearchTerm,

    handleMessageChange,
    handleEmojiSelect,
    handleFileSelect,
    removeFile,
    handleSubmit,

    //redux
    handleSelectChat,
    groups,
    friends,
    filteredFriends,
    filteredGroups,
    selectedChat,
    messages,
  } = useContext(ChatContext);

  // Create a ref for the message container
  const messageContainerRef = useRef(null);
  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const widgetContent = (profile_img = "https://app.xpets.com/avatar/fozzy/smaller/6c6ae6278cdea38c431a44706825e4b2.jpeg", name = "", lastMessage = "") => (
    <div className="widget-contents" style={{ marginTop: 0 }}>
      <div id="get-recent" style={{ display: "none" }}></div>
      <div className="messages-from">
        <div className="inner">
          <div
            data-guid="277"
            className="ossn-recent-message-item d-flex flex-row user-item ossn-recent-message-status-offline"
            onClick={() => console.log("Redirecting to messages/message/fozzy")}
          >
            <div className="msg-flex-c1">
              <img className="image user-icon-smaller" src={profile_img || "https://app.xpets.com/avatar/fozzy/smaller/6c6ae6278cdea38c431a44706825e4b2.jpeg"} alt="User Avatar" />
              <span className="ossn-inmessage-status-circle"></span>
            </div>
            <div className="msg-flex-c2 data">
              <div className="name">{name}</div> {/* Dynamic Name */}
              <div className="reply">
                <i className="fa fa-reply"></i>
                <div className="reply-text">You: {lastMessage}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg"];
  const videoExtensions = ["mp4", "mkv", "avi", "mov"];
  const documentExtensions = ["pdf", "doc", "docx", "ppt", "txt"];

  if(loading){
    return <Loader/>
  }

  return (
    <>
      <div className="mainDiv" id="messages">
        <div className="container-fluid">
          <div className="ossn-layout-contents">
            <div className="ossn-system-messages">
              <div className="row">
                <div className="col-md-11">
                  <div className="ossn-system-messages-inner"></div>
                </div>
              </div>
            </div>
            <div>
              <div className="col-md-12">
                <div className="ossn-messages">
                  <div className="row g-0">
                    <div className="col-md-4" style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                      <div className="search-users-messages-container">
                        <div className="search-users-messages-section">
                          <img src="https://app.xpets.com/themes/xpets/images/search.png" />
                          {/* <input type="text" id="message-user-search" placeholder="Search friends" className="form-control" /> */}
                          <input type="text" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search friends" className="form-control" />
                          <div id="message-user-search-results" className="dropdown-menu"></div>
                        </div>
                      </div>
                      <div className="ossn-widget messages-recent">
                        {/* <div className="widget-heading">
                          Inbox (0)
                          <span className="d-inline d-sm-none ossn-recent-messages-toggle">
                            <i className="fas fa-angle-down"></i>
                          </span>
                        </div> */}

                        {/* Tab Navigation */}
                        <div className="button-group">
                          <button className={activeTab === "friends" ? "group-button active" : "group-button"} onClick={() => setActiveTab("friends")}>
                            Friends
                          </button>
                          <button className={activeTab === "groups" ? "group-button active" : "group-button"} onClick={() => setActiveTab("groups")}>
                            Groups
                          </button>
                        </div>

                        {/* Tab Content */}
                        <div className="tab-content">
                          {activeTab === "friends" && (
                            <div className="friends-tab">
                              {/* {widgetContent('JOHNN')} */}
                              {filteredFriends && filteredFriends.length > 0 ? (
                                filteredFriends.map((friend) => (
                                  <Link to={`/messages/${friend?.id}`} state={{ friend }} key={friend.id}>
                                    {/* // <div key={friend.id} onClick={() => handleSelectChat(friend)}> */}
                                    {widgetContent(friend?.profile_img, friend?.details?.pet_name, friend?.last_message)}
                                    {/* // </div> */}
                                  </Link>
                                ))
                              ) : (
                                <h4 className="text-center mt-4">No friends found</h4>
                              )}
                            </div>
                          )}
                          {activeTab === "groups" && (
                            <div className="groups-tab">
                              {filteredGroups && filteredGroups.length > 0 ? (
                                filteredGroups.map((group) => (
                                  <Link to={`/messages/${group?.id}`} state={{ group }} key={group.id}>
                                    {/* <div key={group.id} onClick={() => handleSelectChat(group)}> */}
                                    {widgetContent(group?.image, group?.name, group?.last_message)}
                                    {/* </div> */}
                                  </Link>
                                ))
                              ) : (
                                <h4 className="text-center mt-4">No groups found</h4>
                              )}
                              {/* {widgetContent("SINGH")} */}
                            </div>
                          )}
                        </div>
                        {/* <div className="widget-contents">
                          <div id="get-recent" style={{ display: "none" }}></div>
                          <div className="messages-from">
                            <div className="inner">
                              <div
                                data-guid="277"
                                className="ossn-recent-message-item d-flex flex-row user-item  ossn-recent-message-status-offline"
                                onclick="Ossn.redirect('messages/message/fozzy');"
                              >
                                <div className="msg-flex-c1">
                                  <img className="image user-icon-smaller" src="https://app.xpets.com/avatar/fozzy/smaller/6c6ae6278cdea38c431a44706825e4b2.jpeg" />
                                  <span className="ossn-inmessage-status-circle"></span>
                                </div>
                                <div className="msg-flex-c2 data">
                                  <div className="name">digger</div>
                                  {/* <!-- <div className="time time-created">10 minutes ago </div> --> *}
                                  <div className="reply">
                                    <i className="fa fa-reply"></i>
                                    <div className="reply-text">You: 😇</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className="col-md-8">
                      {!selectedUserChat ? (
                        <div className="h-100 d-flex align-items-center justify-content-center ossn-messages-select-conv">
                          <i className="fa fa-envelope"></i>
                        </div>
                      ) : (
                        <>
                          <div className="ossn-widget messages-with ossn-inmessage-status-offline" id="message-with-user-widget" data-guid="277">
                            <div className="widget-heading">
                              <img
                                src={chatType === 1 ? selectedChat?.profile_img || petErrImg : chatType === 2 ? selectedChat?.image || petErrImg : petErrImg}
                                className="user-icon-smaller"
                                style={{ width: "32px", height: "32px" }}
                              />
                              <span className="ossn-inmessage-status-circle" style={{ marginLeft: "18px" }}></span>
                              <span className="headingname">{chatType === 1 ? selectedChat?.details?.pet_name : chatType === 2 ? selectedChat?.name : `${chatType}`} </span>
                              <a data-guid="277" className="ossn-message-delete-conversation" href="javascript:void(0);">
                                {/* <i className="fas fa-trash-alt"></i> */}
                                <FaTrashCan />
                              </a>
                            </div>
                            <div className="widget-contents">
                              {/* <script>
                                {`Ossn.SendMessage(277);
                              $(document).ready(function () {
                                setInterval(function () {
                                  Ossn.getMessages('fozzy', '277');
                                }, 5000);
                                Ossn.message_scrollMove(277);
                              });`}
                              </script> */}
                              <div className="message-with">
                                <div className="message-inner" id="message-append-277" ref={messageContainerRef} data-guid="277">
                                  {messages && messages.length > 0 ? (
                                    messages.map((msg) => (
                                      <div className="row" id={`message-item-${msg.id}`} key={msg.id}>
                                        {/* Check if the message is sent by the current user */}
                                        {/* {console.log('msg.sender_id === userInfo?.id',msg.sender_id, userInfo?.id, msg.sender_id === userInfo?.id)} */}
                                        {Number(msg.sender_id) === userInfo?.id ? (
                                          <div className={`col-md-12 pull-right`}>
                                            <div className="message-row">
                                              <div className={`message-box-sent ${msg.is_read === "0" ? "unread" : ""}`}>
                                                {msg.file &&
                                                  msg.file.length > 0 &&
                                                  msg.file.map((fileUrl, index) => {
                                                    // Extract the file extension to handle different file types
                                                    const fileExtension = fileUrl.split(".").pop().toLowerCase();
                                                    // Define the file types that are considered images, videos, and documents
                                                    // const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg"];
                                                    // const videoExtensions = ["mp4", "mkv", "avi", "mov"];
                                                    // const documentExtensions = ["pdf", "doc", "docx", "ppt", "txt"];

                                                    // Determine the content type based on the file extension
                                                    const isImage = imageExtensions.includes(fileExtension);
                                                    const isVideo = videoExtensions.includes(fileExtension);
                                                    const isDocument = documentExtensions.includes(fileExtension);

                                                    return (
                                                      <div key={index} className="file-preview">
                                                        {/* Display image */}
                                                        {isImage && <img src={fileUrl} alt={`file-${index}`} style={{ maxWidth: "200px", maxHeight: "200px" }} />}

                                                        {/* Display video */}
                                                        {isVideo && (
                                                          <video controls style={{ maxWidth: "300px" }}>
                                                            <source src={fileUrl} type={`video/${fileExtension}`} />
                                                            Your browser does not support the video tag.
                                                          </video>
                                                        )}

                                                        {/* Display document link */}
                                                        {isDocument && (
                                                          <a href={fileUrl} download className="file-link">
                                                            Download {fileExtension.toUpperCase()} file
                                                          </a>
                                                        )}

                                                        {/* If file type is unknown, show a generic download link */}
                                                        {!isImage && !isVideo && !isDocument && (
                                                          <a href={fileUrl} download className="file-link">
                                                            Download file
                                                          </a>
                                                        )}
                                                      </div>
                                                    );
                                                  })}
                                                {msg.message && <span>{msg.message || "Message was deleted"}</span>}

                                                <a className="ossn-message-delete" onClick={() => openDeleteModal(msg.id)}>
                                                  <FaEllipsisVertical style={{ color: "white" }} />
                                                </a>
                                              </div>
                                              <img src={userInfo?.profile_img || petErrImg} alt={`${msg?.sender?.name}'s profile`} className="message-sender-avatar" />
                                            </div>
                                            <div className="time-created">
                                              {new Date(msg.created_at).toLocaleTimeString("en-US", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                              })}
                                            </div>
                                          </div>
                                        ) : (
                                          /* Message is received by the current user */
                                          <div className="col-md-12 pull-left">
                                            <div className="message-row">
                                              <img src={selectedChat?.profile_img || petErrImg} alt={`${msg?.sender?.name}'s profile`} className="message-sender-avatar" />
                                              <div className="message-box-recieved text">
                                                {msg.file &&
                                                  msg.file.length > 0 &&
                                                  msg.file.map((fileUrl, index) => {
                                                    // Extract the file extension to handle different file types
                                                    const fileExtension = fileUrl.split(".").pop().toLowerCase();

                                                    // Determine the content type based on the file extension
                                                    const isImage = imageExtensions.includes(fileExtension);
                                                    const isVideo = videoExtensions.includes(fileExtension);
                                                    const isDocument = documentExtensions.includes(fileExtension);

                                                    return (
                                                      <div key={index} className="file-preview">
                                                        {/* Display image */}
                                                        {isImage && <img src={fileUrl} alt={`file-${index}`} style={{ maxWidth: "200px", maxHeight: "200px" }} />}

                                                        {/* Display video */}
                                                        {isVideo && (
                                                          <video controls style={{ maxWidth: "300px" }}>
                                                            <source src={fileUrl} type={`video/${fileExtension}`} />
                                                            Your browser does not support the video tag.
                                                          </video>
                                                        )}

                                                        {/* Display document link */}
                                                        {isDocument && (
                                                          <a href={fileUrl} download className="file-link">
                                                            Download {fileExtension.toUpperCase()} file
                                                          </a>
                                                        )}

                                                        {/* If file type is unknown, show a generic download link */}
                                                        {!isImage && !isVideo && !isDocument && (
                                                          <a href={fileUrl} download className="file-link">
                                                            Download file
                                                          </a>
                                                        )}
                                                      </div>
                                                    );
                                                  })}
                                                {msg.message && <span>{msg.message || "Message was deleted"}</span>}
                                                <a
                                                  className="ossn-message-delete"
                                                  data-id={msg.id}
                                                  data-href={`https://app.xpets.com/action/message/delete?id=${msg.id}`}
                                                  onClick={() => openDeleteModal(msg.id)}
                                                >
                                                  <FaEllipsisVertical style={{ color: "white" }} />
                                                </a>
                                              </div>
                                            </div>
                                            <div className="time-created">
                                              {new Date(msg.created_at).toLocaleTimeString("en-US", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                              })}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    ))
                                  ) : (
                                    <div className="h-100 d-flex align-items-center justify-content-center ossn-messages-select-conv">
                                      <i className="fa fa-envelope"></i>
                                    </div>
                                  )}

                                  {/* <div className="row" id="message-item-42">
                                    <div className="col-md-12 pull-right">
                                      <div className="message-row">
                                        <div className="message-box-sent text ossn-message-deleted">
                                          <span>
                                            <i className="fa fa-times-circle"></i>Message was deleted
                                          </span>
                                          <a
                                            className="ossn-message-delete"
                                            data-id="42"
                                            onClick={() => openDeleteModal(42)}
                                            data-href="https://app.xpets.com/action/message/delete?id=42&ossn_ts=1732768288&ossn_token=7965d1b8c316c19f01a7c028ee2ee6ab0b5d90bbb0182d11e0441aa7683667a8"
                                          >
                                            {/* <i
                                            className="fa-solid fa-ellipsis-vertical"
                                            style={{ color: 'white' }}
                                          ></i> *}
                                            <FaEllipsisVertical style={{ color: "white" }} />
                                          </a>
                                        </div>
                                        <img src="https://app.xpets.com/avatar/Peterparker/smaller/ad9fe0f1e9f6dd15edd313ce7fe3507f.jpeg" />
                                      </div>
                                      <div className="time-created">16 hours ago</div>
                                    </div>
                                  </div>
                                  <div className="row" id="message-item-24">
                                    <div className="col-md-12 pull-right">
                                      <div className="message-row">
                                        <div className="message-box-sent text">
                                          <span>😇</span>
                                          <a
                                            className="ossn-message-delete"
                                            data-id="24"
                                            data-href="https://app.xpets.com/action/message/delete?id=24&ossn_ts=1732768288&ossn_token=7965d1b8c316c19f01a7c028ee2ee6ab0b5d90bbb0182d11e0441aa7683667a8"
                                          >
                                            <FaEllipsisVertical style={{ color: "white" }} />
                                          </a>
                                        </div>
                                        <img src="https://app.xpets.com/avatar/Peterparker/smaller/ad9fe0f1e9f6dd15edd313ce7fe3507f.jpeg" />
                                      </div>
                                      <div className="time-created">17 hours ago</div>
                                    </div>
                                  </div>
                                  <div className="row" id="message-item-21">
                                    <div className="col-md-12 pull-right">
                                      <div className="message-row">
                                        <div className="message-box-sent text">
                                          <span>😂 😂</span>
                                          <a
                                            className="ossn-message-delete"
                                            data-id="21"
                                            data-href="https://app.xpets.com/action/message/delete?id=21&ossn_ts=1732768288&ossn_token=7965d1b8c316c19f01a7c028ee2ee6ab0b5d90bbb0182d11e0441aa7683667a8"
                                          >
                                            <FaEllipsisVertical style={{ color: "white" }} />
                                          </a>
                                        </div>
                                        <img src="https://app.xpets.com/avatar/Peterparker/smaller/ad9fe0f1e9f6dd15edd313ce7fe3507f.jpeg" />
                                      </div>
                                      <div className="time-created">18 hours ago</div>
                                    </div>
                                  </div>
                                  <div className="row" id="message-item-12">
                                    <div className="col-md-12 pull-left">
                                      <div className="message-row">
                                        <img src="https://app.xpets.com/avatar/fozzy/smaller/6c6ae6278cdea38c431a44706825e4b2.jpeg" />
                                        <div className="message-box-recieved text">
                                          <span>Hello</span>
                                          <a
                                            className="ossn-message-delete"
                                            data-id="12"
                                            data-href="https://app.xpets.com/action/message/delete?id=12&ossn_ts=1732768288&ossn_token=7965d1b8c316c19f01a7c028ee2ee6ab0b5d90bbb0182d11e0441aa7683667a8"
                                          >
                                            <FaEllipsisVertical style={{ color: "white" }} />
                                          </a>
                                        </div>
                                      </div>
                                      <div className="time-created">2 weeks ago</div>
                                    </div>
                                  </div> */}
                                </div>
                              </div>

                              {/* Emoji Picker */}
                              {/* {showEmojiPicker && <EmojiPicker onEmojiClick={handleEmojiSelect} className="emoji-picker" />} */}
                              <div style={{ position: "relative" }}>
                                {showEmojiPicker && (
                                  <div className="emoji-picker-container">
                                    <EmojiPicker onEmojiClick={handleEmojiSelect} />
                                  </div>
                                )}

                                <form className="ossn-form message-form-form" onSubmit={handleSubmit}>
                                  <fieldset>
                                    <div className="message-container">
                                      <div className="messagesend-box">
                                        <textarea name="message" placeholder="Type your message. . ." value={message} onChange={handleMessageChange}></textarea>
                                        <div className="ossn-message-icon-attachment m-0" onClick={() => fileInputRef.current.click()}>
                                          {/* <FaPaperclip className="" style={{color:'#005248'}}/> */}
                                          <img src={paperClip} />
                                        </div>
                                      </div>

                                      {/* File Input (Hidden) */}
                                      <input type="file" ref={fileInputRef} className="d-none" multiple onChange={handleFileSelect} />
                                      {/* Submit Button */}
                                      <div className="submit-container">
                                        {sendingMessage ? (
                                          <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                          </Spinner>
                                        ) : (
                                          <button type="submit" className="btn-send-message-btn"></button>
                                        )}
                                      </div>
                                    </div>

                                    {/* Controls */}
                                    <div className="controls">
                                      <div className="ossn-message-attach-photo" onClick={() => setShowEmojiPicker((prev) => !prev)}>
                                        {/* <FaSmile /> */}
                                        <i className="fa fa-smile"></i>
                                      </div>
                                      {/* <div className="ossn-message-pling">
                                        {/* <FaBell /> *}
                                        <i className="fa fa-bell"></i>
                                      </div> */}
                                    </div>

                                    {/* List selected files */}
                                    {selectedFiles.length > 0 && (
                                      <div className="file-list">
                                        {selectedFiles.map((file, index) => (
                                          <div key={index} className="file-item">
                                            {file.name}
                                            <button type="button" className="remove-file btn btn-light mt-0" onClick={() => removeFile(index)}>
                                              <FaTrash />
                                            </button>
                                          </div>
                                        ))}
                                      </div>
                                    )}
                                  </fieldset>
                                </form>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <audio id="ossn-chat-sound" src="https://app.xpets.com/components/OssnMessages/sound/pling.mp3" preload="auto"></audio>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* DELETE MODAL */}
      {isDeleteModalOpen && <DeleteModal closeDeleteModal={closeDeleteModal} />}
    </>
  );
};
export default ChatUI;
