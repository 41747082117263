import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGroupsAndFriends } from "../../store/features/chat/chatSlice";
import { Link } from "react-router-dom";

const ThemeCustomizer = () => {
  const dispatch = useDispatch();
  const { groups, friends, selectedChat, chatType, messages, loading, error } = useSelector((state) => state.chat);

  useEffect(() => {
    dispatch(fetchGroupsAndFriends()); // Fetch friends and groups on component mount
  }, [dispatch]);

  const [isChatOpen, setIsChatOpen] = useState(false);

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  const widgetContent = (profile_img = "https://app.xpets.com/avatar/fozzy/smaller/6c6ae6278cdea38c431a44706825e4b2.jpeg", name = "", lastMessage = "") => (
    <div className="widget-contents" style={{ marginTop: 0, paddingTop:0 }}>
      <div id="get-recent" style={{ display: "none" }}></div>
      <div className="messages-from">
        <div className="">
          <div data-guid="277" className="d-flex align-items-center p-2">
            <div className="d-flex align-items-center me-2">
              <img
                className="rounded"
                src={profile_img || "https://app.xpets.com/avatar/fozzy/smaller/6c6ae6278cdea38c431a44706825e4b2.jpeg"}
                alt="User Avatar"
                style={{ width: "32px", height: "32px" }}
              />
            </div>
            <div className="flex-grow-1 d-flex justify-content-between align-items-center">
              <div className="">{name}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div className="ossn-chat-base d-none d-md-block">
        <div className="ossn-chat-bar">
          {isChatOpen && (
            <>
              <div className="friends-list" style={{ display: "block" }}>
                <div className="ossn-chat-tab-titles justify-content-between d-flex">
                  <div className="text">Chat</div>
                  <span className="close-button m-0 f-16" onClick={toggleChat}>
                    &times;
                  </span>
                </div>
                <div className="data">
                  {friends && friends.length > 0 ? (
                    friends.map((friend) => (
                      <Link to={`/messages/${friend?.id}`} state={{ friend }} key={friend.id}>
                        {widgetContent(friend?.profile_img, friend?.details?.pet_name, friend?.last_message)}
                      </Link>
                    ))
                  ) : (
                    <div className="ossn-chat-none">No friends online</div>
                  )}
                </div>
              </div>
              <div className="inner friends-tab ossn-minichat-list-open">
                <div className="ossn-chat-tab">
                  <div className="ossn-chat-inner-text ossn-chat-online-friends-count">
                    Chat (<span>0</span>)
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="ossn-chat-base d-none d-md-block">
            <div className="inner friends-tab" onClick={toggleChat}>
              <div className="ossn-chat-tab">
                <div className="ossn-chat-inner-text ossn-chat-online-friends-count">
                  Chat
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThemeCustomizer;
