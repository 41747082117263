import React, { useEffect, useState } from 'react';
import penBig from "../../../assets/images/pen-big.png"
import API from '../../../utils';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Nophotoalbum from "../../../assets/images/nophoto-album.png";
const NewsfeedRight = () => {
  const [friendsData, setFriendsData] = useState({
    pet_name: '',
    profile_img: '',
  });
  const [profileData, setProfileData] = useState({
    favouriteTreat: "",
    favourite_treat: "",
    hobbies: "",
  });
  const [albums, setAlbums] = useState([]);
  useEffect(() => {
    fetchUserData();
    fetchFriendsData();
  }, []);

  const fetchFriendsData = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.relations}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.data.status) {
        setFriendsData(response.data.payload);
      }
    } catch (error) {
      console.error("Error fetching friends data:", error);
    }
  };
  const navigate = useNavigate();
  const handleImageClick = (albumId) => {
    navigate(`/album/view/${albumId}`);
  };

  const fetchUserData = async () => {
    const token = localStorage.getItem('token');
    const uid = localStorage.getItem('user_id');

    try {
      const response = await axios.get(
        `${API.BASE_URL}${API.ENDPOINTS.profile}/${uid}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.data.status === true) {
        const data = response.data.payload.user;
        setProfileData({
          favouriteTreat: data.user_details.favourite_treat || "",
          favourite_treat: data.user_details.favourite_treat || "",
          hobbies: data.user_details.hobbies || "",

        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching owner data:", error);
    }
  };
  const { BASE_URL, ENDPOINTS } = API;
  useEffect(() => {
    const fetchAlbums = async () => {
      try {
        const response = await axios.get(`${BASE_URL}${ENDPOINTS.albumlist}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (response.data.status) {
          setAlbums(response.data.payload); // Store albums in state
        } else {
          toast.error("Failed to fetch albums.");
        }
      } catch (error) {
      }
    };

    fetchAlbums();
  }, [BASE_URL, ENDPOINTS]);
  return (
    <>
      <div className="ossn-widget birthdays">
        <div className="widget-heading">
          <span>Video call</span>
        </div>
        <div className='widget-contents'>
          <div class="ossn-profile-module-hangout start-google-meet text-center">
            <a target="_blank" href="https://meet.google.com/" class="">Start Google Meet</a>
          </div>

        </div>

      </div>

      <div className="ossn-widget hashtag-trending">
        <div className='d-flex justify-content-between'>
          <div className="widget-heading">About Me</div>

          <div class="about-edit-btn" id="about-edit-btn">
            <img src={penBig} />
          </div>
        </div>
        <div className="widget-contents">

          <div class="about-me-section">
            <li>
              {profileData.aboutPet}</li>
            <li>
              <div class="about-me-section-title">
                Favourite Treat:
              </div>
              <div class="about-me-section-text">
                {profileData.favourite_treat}  </div>
            </li>
            <li>
              <div class="about-me-section-title">
                Hobbies:
              </div>
              <div class="about-me-section-text">
                {profileData.hobbies} </div>
            </li>
          </div>
        </div>
      </div>



      <div className="ossn-widget">
        <div className="widget-heading">Friends</div>
        <div className='friends-list-containers'>
          {friendsData.length > 0 ? (
            friendsData.map((friend) => (
              <div className="widget-contents">
                <div className="ossn-profile-module-friends">
                  <div className="user-image">
                    <img src={friend.receiver.profile_img} alt="User Avatar" height={"50px"} width={"50px"} title={friendsData.pet_name} />
                    <div className="user-name mx-2">
                      {friend.receiver.details.pet_name}
                      <img src="https://app.xpets.com/themes/xpets/images/orange-tick.png" alt="verified" />
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <h6 className="no-friends-message">No friends found.</h6>
          )}
        </div>
      </div>


      <div class="ossn-widget">
        <div class="widget-heading">Photo Albums</div>
        <div className='friends-list-containers'>
          <div className="module-contents">
            {albums.length > 0 ? (
              <div className="ossn-photos">
                {albums.map((album) => (
                  <li key={album.id} className='albums_photo_id'>
                    <a
                      className="linkthumb"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleImageClick(album.id)} // Pass album.id here
                    >
                      <img
                        src={album.thumbnail || Nophotoalbum} // Use a default if no thumbnail
                        className="pthumb"
                        alt={album.album_name}
                      />
                      <div className="ossn-album-name">{album.album_name}</div>
                    </a>
                  </li>
                ))}
              </div>
            ) : (
              <h6 className="no-albums-message">
                No albums available.
              </h6>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsfeedRight;
