import React, { useState } from "react";
import { Tab, Nav, Row, Col, Container } from "react-bootstrap";
import AddGroup from "./tab/addgroup";
import AllGroupSearch from "./tab/allgroupsearch";
import MyGroup from "./tab/mygroups";
import GroupInvites from "./tab/groupInvites";
import Friends from '../../assets/images/sidebar/friends.png';
import Groupsinvitetion from '../../assets/images/sidebar/invite-friends.png';
import Groups from '../../assets/images/sidebar/groups.png';
import AddGroups from '../../assets/images/sidebar/plus.png';

const Packchatindex = () => {
  // State to manage the active tab content dynamically
  const [activeTab, setActiveTab] = useState("1");

  // Function to dynamically render content based on active tab ID
  const renderTabContent = () => {
    switch (activeTab) {
      case "1":
        return <MyGroup />;
      case "2":
        return <GroupInvites />;
      case "3":
        return <AllGroupSearch />;
      case "4":
        return <AddGroup />;
      default:
        return null;
    }
  };

  return (
      <div className="row ossn-layout-newsfeed">
        <Tab.Container activeKey={activeTab} onSelect={(key) => setActiveTab(key)}>
          <Row className="m-0">
            <Col sm={3}>
              <div className="search-options">
                <div className="ossn-menu-search">
                  {/* <div className="title">Result Type</div> */}
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="1">
                        <div className="text">
                          <img
                            className="side-menu-icon"
                            src={Friends}
                            alt="My Groups"
                          />
                          My Groups
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="2">
                        <div className="text">
                          <img
                            className="side-menu-icon"
                            src={Groupsinvitetion}
                            alt="Groups Invites"
                          />
                          Groups Invites
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="3">
                        <div className="text">
                          <img
                            className="side-menu-icon"
                            src={Groups}
                            alt="All Groups"
                          />
                          All Groups
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="4">
                        <div className="text">
                          <img
                            className="side-menu-icon"
                            src={AddGroups}
                            alt="Add Groups"
                          />
                          Add Groups
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </div>
            </Col>
            <Col sm={9}>
              <div className="search-results-container">
                <div className="ossn-search-page">
                  <div className="search-data">
                    {renderTabContent()}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Tab.Container>
      </div>
  );
};

export default Packchatindex;
