import React from "react";
import ReactDOM from "react-dom/client";
// import "./index.scss";
import "../src/i18n";
import * as serviceWorker from "./serviceWorker";
import "./assets/css/index.css";
import "./assets/css/style.css";
import "./assets/css/style1.css";
// import "./assets/css/temp.css";
// ** Import custom components for redux **
import { Provider } from "react-redux";
// import store from "./store";
import store from "./store/store";
import MainRoutes from "./routes";

const Root = () => {
  return (
    <div className="App">
      <Provider store={store}>
        <MainRoutes />
      </Provider>
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);

serviceWorker.unregister();
