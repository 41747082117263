import React, { useState, Fragment, useEffect, useCallback } from 'react';
import { MENUITEMS } from '../../../components/common/sidebar-component/menu';
import { Search } from 'react-feather';

const SearchHeader = () => {
    const mainmenu = MENUITEMS;
    const [searchValue, setSearchValue] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchOpen, setSearchOpen] = useState(false);

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            // Clear search on Escape key press
            setSearchOpen(false);
            setSearchValue('');
            setSearchResults([]);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', escFunction, false);
        return () => {
            document.removeEventListener('keydown', escFunction, false);
        };
    }, [escFunction]);

    const handleSearchKeyword = (keyword) => {
        setSearchValue(keyword);
        if (keyword) {
            addFix();
            const items = [];
            mainmenu?.forEach((item) => {
                if (item.title.toLowerCase().includes(keyword.toLowerCase()) && item.type === 'link') {
                    items.push(item);
                }
                if (item.children) {
                    item.children.forEach((subItem) => {
                        if (subItem.title.toLowerCase().includes(keyword.toLowerCase()) && subItem.type === 'link') {
                            subItem.icon = item.icon;
                            items.push(subItem);
                        }
                        if (subItem.children) {
                            subItem.children.forEach((suSubItem) => {
                                if (suSubItem.title.toLowerCase().includes(keyword.toLowerCase())) {
                                    suSubItem.icon = item.icon;
                                    items.push(suSubItem);
                                }
                            });
                        }
                    });
                }
            });
            checkSearchResultEmpty(items);
            setSearchResults(items);
        } else {
            removeFix();
            setSearchResults([]);
        }
    };

    const checkSearchResultEmpty = (items) => {
        if (!items.length) {
            document.querySelector('.empty-menu').classList.add('is-open');
        } else {
            document.querySelector('.empty-menu').classList.remove('is-open');
        }
    };

    const addFix = () => {
        document.querySelector('.Typeahead-menu').classList.add('is-open');
        document.body.classList.add('offcanvas');
    };

    const removeFix = () => {
        setSearchValue('');
        setSearchResults([]);
        document.querySelector('.Typeahead-menu').classList.remove('is-open');
        document.body.classList.remove('offcanvas');
    };

    const toggleBtn = () => {
        setSearchOpen(!searchOpen);
        document.querySelector('.searchIcon').classList.toggle('open');
    };

    return (
        <Fragment>
            <div>
                <form className="search-form">
                    <div className="form-group">
                        <input
                            className="form-control-plaintext searchIcon"
                            type="text"
                            placeholder="Search..."
                            value={searchValue}
                            onChange={(e) => handleSearchKeyword(e.target.value)}
                        />
                        <span className="d-sm-none mobile-search" onClick={toggleBtn}>
                            <Search />
                        </span>
                        <div className="Typeahead-menu custom-scrollbar" id="search-outer">
                            {searchResults.length > 0 ? (
                                searchResults.map((data, index) => (
                                    <div className="ProfileCard u-cf" key={index}>
                                        <div className="ProfileCard-avatar">
                                            <data.icon />
                                        </div>
                                        <div className="ProfileCard-details">
                                            <div className="ProfileCard-realName">
                                                <a
                                                    href={`${process.env.PUBLIC_URL}${data.path}`}
                                                    className="realname"
                                                    onClick={removeFix}
                                                >
                                                    {data.title}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="Typeahead-menu empty-menu">
                            <div className="tt-dataset tt-dataset-0">
                                <div className="EmptyMessage">
                                    {'Oops!! There are no results found.'}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Fragment>
    );
};

export default SearchHeader;
