import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import logo from "../assets/images/logo/logo.png";
import man from "../assets/images/user/user_default.jpg";
import auth from "../assets/images/auth/left-image.png";
import Divider from "../assets/icoicon/divider.svg";
import Facebook from "../assets/icoicon/facebook.svg";
import Google from "../assets/icoicon/google.svg";
import Twitter from "../assets/icoicon/twitter.svg";
import axios from "axios";
import API from "../utils";
import { Spinner } from "react-bootstrap";
import Loader from "../components/common/Loader";

const ForgetPwd = () => {
  const { BASE_URL, ENDPOINTS } = API;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [err, setErr] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    setErr(null);
    const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    if (!email.trim()) {
      setErr("Email is required");
      return;
    } else if (!emailPattern.test(email.trim())) {
      setErr("Please enter a valid email address");
      return;
    }
  
    setLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}${ENDPOINTS.forgotpassword}`, { email });
      const { status, message } = response.data;
      if (status) {
        toast.success(message || "Mail sent successfully");
        navigate(`/login`);
      } else {
        toast.error(message || "Failed to send mail");
      }
    } catch (error) {
      const { data } = error.response || {};
      if (data && data.message && data.message.email) {
        // Show each email error message in a toast
        data.message.email.forEach((msg) => toast.error(msg));
      } else {
        toast.error(error.response?.data.message || "Something went wrong");
      }
    } finally {
      setLoading(false);
    }
  };
  
  
  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   setErr(null);
  //   const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
  //   if (!email.trim()) {
  //     setErr("Email is required");
  //     return;
  //   } else if (!emailPattern.test(email.trim())) {
  //     setErr("Please enter a valid email address");
  //     return;
  //   }

  //   setLoading(true);
  //   try {
  //     const response = await axios.post(`${BASE_URL}${ENDPOINTS.forgotpassword}`, { email });
  //     const { status, message } = response.data;
  //     if (status) {
  //       toast.success(message || "Mail sent successful");
  //       navigate(`/login`);
  //     } else {
  //       toast.error(message || "Failed to send mail");
  //     }
  //   } catch (error) {
  //     const { data } = error.response || {};
  //     if (data && data.message && data.message.email) {
  //       // Display the specific email error
  //       data.message.email.forEach((msg) => setErr(msg));
  //     } else {
  //       toast.error(error.response?.data.message);
  //     }
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <div className="ossn-layout-startup">
        <div className="container-fluid">
          <div className="ossn-home-container">
            <div className="row">
              <div className="col-center ossn-page-contents">
                <div className="row">
                  <div className="col-md-6 home-left-contents">
                    <img className="img-responsive" src={auth} alt="Welcome" />
                  </div>
                  <div className="col-md-6 home-right-contents">
                    <div className="logo">
                      <img src={logo} alt="Logo" />
                    </div>
                    <div className="ossn-widget-reset">
                      <div className="widget-heading-reset">Reset your password</div>
                      <div className="widget-contents-reset">
                        <form className="ossn-form ossn-reset-login" onSubmit={handleSubmit}>
                          <fieldset>
                            <input type="hidden" name="ossn_ts" value="1732513177" />
                            <input type="hidden" name="ossn_token" value="d2d10ff6744a39b372909d149cff21a4a2486608887d8901286b706c9d803ad9" />
                            <div className="reset-notice">Enter the email address you used when joined and we’ll send reset instructions to reset your password.</div>
                            <input type="text" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            {err && <div className="error text-start mb-2">{err}</div>}
                            <div>
                              <input type="submit" style={{ width: "100%" }} className="btn btn-primary btn-sm" value="Send Reset Instructions" />
                            </div>
                            <div
                              style={{
                                marginTop: "10px",
                                textAlign: "center",
                              }}
                            >
                              <span>Back to log in page? </span>
                              <Link to="/login" className="loginlink">
                                Back now
                              </Link>
                            </div>
                          </fieldset>
                        </form>
                      </div>
                    </div>
                    <footer>
                      <div class="footer-contents">
                        <div class="ossn-footer-menu">
                          <a class="menu-footer-a_copyrights" href="/">
                            © COPYRIGHT xpets
                          </a>
                          <a class="menu-footer-about" href="/about-us">
                            About
                          </a>
                          <a class="menu-footer-site" href="/terms-and-conditions">
                            Terms and Conditions
                          </a>
                          <a class="menu-footer-privacy" href="/privacy-policy">
                            Privacy
                          </a>
                        </div>
                      </div>
                    </footer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>                    
        <ToastContainer />
      </div>
    </>
  );
};

export default ForgetPwd;
