import React from 'react';
import penBig from "../../../assets/images/pen-big.png"
const NewsfeedRight = () => {
  return (
    <>
      <div className="ossn-widget birthdays">
        <div className="widget-heading">
          <span>Video call</span>
        </div>
        <div className='widget-contents'>                
          <div class="ossn-profile-module-hangout start-google-meet text-center">
            <a target="_blank" href="https://meet.google.com/" class="">Start Google Meet</a>
            </div>

        </div>

      </div>

      <div className="ossn-widget hashtag-trending">
        <div className='d-flex justify-content-between'>
          <div className="widget-heading">About Me</div>

          <div class="about-edit-btn" id="about-edit-btn">
            <img src={penBig} />
          </div>
        </div>
        <div className="widget-contents">

          <div class="about-me-section">
            <li>
              I love walking with my owner </li>
            <li>
              <div class="about-me-section-title">
                Favourite Treat:
              </div>
              <div class="about-me-section-text">
                Biscuit                      </div>
            </li>
            <li>
              <div class="about-me-section-title">
                Hobbies:
              </div>
              <div class="about-me-section-text">
                I love walking                      </div>
            </li>
          </div>
        </div>
      </div>



      <div class="ossn-widget">
        <div class="widget-heading">Friends</div>
        <div class="widget-contents">
          <div class="ossn-profile-module-friends"><a href="https://app.xpets.com/u/fozzy">
            <div class="user-image">
              <img src="https://app.xpets.com/avatar/fozzy/small/7f65f52544104f58e130335c0ede8407.jpeg" title="digger" />
              <div class="user-name mx-2 ">digger<img src="https://app.xpets.com/themes/xpets/images/orange-tick.png" /></div>
            </div>
          </a></div>	</div>
      </div>


      <div class="ossn-widget">
        <div class="widget-heading">Photo Albums</div>
        <div class="widget-contents">
          <div class="ossn-profile-module-albums"><h3>No Albums</h3></div>	</div>
      </div>
    </>
  );
};

export default NewsfeedRight;
