import React, { useEffect, useRef, useState } from "react";
import Share from "../../../assets/images/sidebar/share.png";
import Comment from "../../../assets/images/sidebar/message-blank.png";
import Like from "../../../assets/images/sidebar/heart_new.png";
import FilledHeart from "../../../assets/images/filled_heart.svg";
import Menu from "../../../assets/images/sidebar/dots.png";
import defalutimages from "../../../assets/images/user/user_default.jpg";
import axios from "axios";
import API from "../../../utils";
import { toast } from "react-toastify";
import ReactPlayer from "react-player";
import EditModal from "./modals/postModals/editModal";
import EditCommentModal from "./modals/postModals/editCommentModal";
import ReportModal from "./modals/postModals/reportModal";
import ShareModal from "./modals/postModals/shareModal";
import ConfirmationModal from "./modals/postModals/confirmationModal";
import ValidationModal from "./modals/postModals/validationModal";

const Post = ({ postId, userData = {}, fetchData }) => {
  const { BASE_URL, ENDPOINTS } = API;
  const token = localStorage.getItem("token");
  const [showComments, setShowComments] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showEditCommentModal, setShowEditCommentModal] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [comments, setComments] = useState([]);
  const [attachment, setAttachment] = useState(null);
  const [attachmentPreview, setAttachmentPreview] = useState(null);
  const [editingCommentIndex, setEditingCommentIndex] = useState(null);
  const [editingCommentText, setEditingCommentText] = useState("");
  const [isLiked, setIsLiked] = useState(userData.is_liked_by_user !== null); 
  const [likes, setLikes] = useState(userData.likes); 
  const [showValidationModal, setShowValidationModal] = useState(false); // Add this state
  const videoContainerRefs = useRef([]);
  const dropdownRef = useRef();
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [activeSection, setActiveSection] = useState(null);
const commentRefs = useRef({});
  const toggleDropdown = (id) => {
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };



  const handleCommentImageClick = async () => {
    try {
      const response = await axios.get(
        `${API.BASE_URL}${API.ENDPOINTS.likesComments}?post_id=${postId}&type=comment`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status) {
        setComments(response.data.payload);
        setShowComments((prev) => !prev);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdowns({});
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const {
    avatarUrl = defalutimages,
    username,
    name,
    postedId,
    title,
    updated_at,
    comment,
    image_links = [],
    video_links = [],
    sharedPost,
  } = userData;

  

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleDeletePost = async () => {
    const token = localStorage.getItem('token');
    // setLoading(true); 
    try {
      const response = await axios.delete(
      `${BASE_URL}${ENDPOINTS.posts}/${postId}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    

      if (response.data.status) {
      fetchData()
        toast.success(response.data.message);
        handleCloseModal()
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching owner data:", error);
      // toast.error("Failed to fetch owner data.");
    } finally {
      // setLoading(false); 
    }
};

  const handleCommentPost = async () => {

    if (!commentText.trim()) {
      setShowValidationModal(true); 
      return;
    }

      const formData = new FormData();
      formData.append("post_id", postId);
      formData.append("type", "comment");
      formData.append("comment", commentText);

      try {
        const response = await axios.post(
          `${API.BASE_URL}${API.ENDPOINTS.likesComments}`,
          formData,
          {
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.status) {
          toast.success("Comment posted successfully!");
          fetchData();
          setCommentText("");
        } else {
          toast.error(response.data.message || "Failed to post the comment.");
        }
      } catch (error) {
        console.error("Error posting comment:", error);
        toast.error("Error posting comment. Please try again.");
      }
    
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAttachment(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setAttachmentPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLikePost = async (e) => {
    e.preventDefault();
    try {
      if (isLiked) {
        // Unlike the post
        const response = await axios.delete(
          `${API.BASE_URL}${API.ENDPOINTS.likesComments}/${userData.is_liked_by_user}`,
          {
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.status) {
          setIsLiked(false);
          setLikes((prevLikes) => prevLikes - 1);
          toast.success("Post unliked successfully");
          fetchData()
        } else {
          toast.error(response.data.message);
        }
      } else {
        const formData = new FormData();
        formData.append("post_id", postId);
        formData.append("type", "like");
        const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.likesComments}`, formData, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.data.status) {
          setIsLiked(true);
          setLikes((prevLikes) => prevLikes + 1); 
          toast.success(response.data.message);
          fetchData() 
        } else {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      console.error("Error updating settings:", error);
      toast.error("Error updating settings.");
    }
  };

  const handleSharePost = () => {
    setShowShareModal(true);
  };

  const handleCloseShareModal = () => {
    setShowShareModal(false);
  };


  const handleEmojiClick = (emoji) => {
    const commentBox = commentRefs.current[postId];
    if (commentBox) {
      const newText = commentBox.textContent + emoji;
      commentBox.textContent = newText;
      setCommentText(newText);
    }
  };

  const handleEditPost = () => {
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const handleEditComment = (index) => {
    setEditingCommentIndex(index);
    setEditingCommentText(comments[index].text);
    setShowEditCommentModal(true);
  };

  const handleSaveCommentEdit = () => {
    const updatedComments = [...comments];
    updatedComments[editingCommentIndex].text = editingCommentText;
    setComments(updatedComments);
    setEditingCommentIndex(null);
    setEditingCommentText("");
    setShowEditCommentModal(false);
  };

  const handleShowReportModal = () => setShowReportModal(true);
  const handleCloseReportModal = () => setShowReportModal(false);
  const handleCloseEditCommentModal = () => setShowEditCommentModal(false);
  const handleCloseValidationModal = () => setShowValidationModal(false);

  const toggleSection = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };

  
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const videoContainer = entry.target;
            const videoPlayer = videoContainer.querySelector('video');
            if (videoPlayer) {
              videoPlayer.currentTime = 0; 
              videoPlayer.play();
            }
          } else {
            const videoContainer = entry.target;
            const videoPlayer = videoContainer.querySelector('video');
            if (videoPlayer) {
              videoPlayer.pause();
            }
          }
        });
      },
      { threshold: 0.1 }
    );

    videoContainerRefs.current.forEach((container) => {
      if (container) {
        observer.observe(container);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [video_links]);

  return (
    <div
      className="ossn-wall-item ossn-wall-item-476"
      id={`activity-item-${postId}`}
    >
      <div className="row">
        <div className="meta">

          <img
            className="user-icon-small user-img"
            src={avatarUrl || defalutimages}
            alt="User Avatar"
          />
          <div className="post-menu">
            <div className="dropdown" ref={dropdownRef}>
              <a
                role="button"
                className="btn btn-link p-0"
                onClick={() => toggleDropdown(`post-${postId}`)}
              >
                <img src={Menu} alt="Menu" />
              </a>
              {openDropdowns[`post-${postId}`] && (
                <ul
                  className="dropdown-menu multi-level dropdown-menu-end"
                  role="menu"
                  style={{
                    position: "absolute",
                    inset: "0px 0px auto auto",
                    margin: "0px",
                    transform: "translate(0px, 42px)",
                  }}
                >
                
                    <li>
                      <a
                        className="dropdown-item post-control-report"
                        onClick={handleEditPost}
                      >
                        Edit
                      </a>
                    </li>
                  
                  <li>
                    <a
                      className="dropdown-item post-control-report ossn-report-this"
                      onClick={handleShowReportModal}
                    >
                      Report this
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item post-control-delete ossn-wall-post-delete"
                      onClick={handleShowModal}
                    >
                      Delete
                    </a>
                  </li>
                </ul>
              )}
            </div>
          </div>
          <div className="user d-flex   align-items-center">
            <a className="ossn-output-user-url">{name}</a>
            <i
              className="fa fa-check-circle verified-user verified-title"
              title="Verified Profile"
            ></i>
            {sharedPost !== null && (
              <div className="ossn-wall-item-type ms-1">Shared a post</div>
            )}
          </div>
          <div className="post-meta">
            <span className="time-created ossn-wall-post-time">
              {updated_at}
            </span>
          </div>
        </div>
        {sharedPost === null && (
          <div className="meta">
            <div className="post-contents">
              <p>{title}</p>
            </div>
            {Array.isArray(image_links) && image_links.length > 0 && (
              <div className="post-images">
                {image_links.map((image, index) => (
                  <img
                    key={index}
                    src={image || ""}
                    alt={`Post Image ${index}`}
                    style={{
                      maxWidth: "100%",
                      width: "100%",
                      margin: "5px 0",
                    }}
                    onError={(e) => (e.target.src = "default_image_url_here")}
                  />
                ))}
              </div>
            )}
            
           {Array.isArray(video_links) && video_links.length > 0 && (
              <div className="post-videos">
                {video_links.map((video, index) => (
                  <div
                    key={index}
                    style={{ margin: "10px 0" }}
                    ref={(el) => (videoContainerRefs.current[index] = el)}
                  >
                    <ReactPlayer
                      url={video || ""}
                      controls
                      playing
                      loop
                      muted
                      width="100%"
                      height="300px"
                      style={{ maxWidth: "100%", maxHeight: "300px" }}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        {sharedPost && (
          <div className="post-share-wall-item">
            <div className="ossn-shared-wrapper">
              <div className="row">
                <div className="meta">
                  <img
                    className="user-icon-small user-img"
                    src={avatarUrl || defalutimages}
                    alt="User Avatar"
                  />

                  <div className="user">
                    <a className="ossn-output-user-url">
                      {sharedPost.userData.name}{" "}
                      <i
                        className="fa fa-check-circle verified-user verified-title"
                        title="Verified Profile"
                      ></i>
                    </a>
                  </div>
                  <div className="post-meta">
                    <span
                      className="time-created ossn-wall-post-time"
                      title={sharedPost.userData.updated_at}
                    >
                      {sharedPost.userData.updated_at}
                    </span>
                  </div>
                  <div className="post-contents">
                    <p>{sharedPost.userData.title}</p>
                    {Array.isArray(sharedPost.userData.image_links) &&
                      sharedPost.userData.image_links.length > 0 && (
                        <div className="post-images">
                          {sharedPost.userData.image_links.map(
                            (image, index) => (
                              <img
                                key={index}
                                src={image || ""}
                                alt={`Shared Post Image ${index}`}
                                style={{
                                  maxWidth: "100%",

                                  margin: "5px 0",
                                }}
                                onError={(e) =>
                                  (e.target.src = "default_image_url_here")
                                }
                              />
                            )
                          )}
                        </div>
                      )}
  {Array.isArray(sharedPost.userData.video_links) &&
                      sharedPost.userData.video_links.length > 0 && (
                        <div className="post-videos">
                          {sharedPost.userData.video_links.map(
                            (video, index) => (
                              <div
                                key={index}
                                style={{ margin: "10px 0" }}
                                ref={(el) =>
                                  (videoContainerRefs.current[index] = el)
                                }
                              >
                                <ReactPlayer
                                  url={video || ""}
                                  controls
                                  playing
                                  loop
                                  muted
                                  width="100%"
                                  height="300px"
                                  style={{
                                    maxWidth: "100%",
                                  }}
                                />
                              </div>
                            )
                          )}
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="comments-likes">
          <div className="menu-likes-comments-share">
            <li>
              <a className="post-control-like" onClick={handleLikePost}>
                <img src={isLiked ? FilledHeart : Like} alt="Like" /> <span>{likes}</span>
              </a>
            </li>
            <li>
              <a
                className="post-control-comment comment-post"
                onClick={handleCommentImageClick}
              >
                <img src={Comment} alt="Comment" />
                <span>{comment}</span>
              </a>
            </li>
            <li>
              <a
                className="post-control-shareobject share-object-select"
                onClick={handleSharePost}
              >
                <img src={Share} alt="Share" />
              </a>
            </li>
          </div>
        </div>

        {showComments && (
          <div className="comments-list">
            {comments.map((comment, index) => (
              <div className="comments-item" key={index}>
                <div className="row">
                  <div className="col-md-1">
                    <img
                      className="comment-user-img"
                      src="https://app.xpets.com/avatar/Peterparker/smaller/ad9fe0f1e9f6dd15edd313ce7fe3507f.jpeg"
                      alt="User Avatar"
                    />
                  </div>
                  <div className="col-md-10">
                    <div className="comment-contents">
                      <p>
                        <a className="ossn-output-user-url owner-link">
                          {comment.commented_user.name}
                        </a>
                        <span className="comment-text">{comment.comment}</span>
                      </p>
                      <div className="comment-metadata">
                        <div className="time-created">37 minutes ago</div>
                        <a className="ossn-like-comment">Like it</a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="ossn-comment-menu">
                      <div className="dropdown" ref={dropdownRef}>
                        <a role="button" className="btn btn-link p-0">
                          <img src={Menu} alt="Menu" />
                        </a>
                        {openDropdowns[`comment-${index}`] && (
                          <ul
                            className="dropdown-menu multi-level dropdown-menu-end"
                            role="menu"
                            style={{
                              position: "absolute",
                              inset: "0px 0px auto auto",
                              margin: "0px",
                              transform: "translate(0px, 42px)",
                            }}
                          >
                            <li>
                              <a
                                className="dropdown-item post-control-report ossn-report-this"
                                onClick={handleShowReportModal}
                              >
                                Report this
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item post-control-delete ossn-wall-post-delete"
                                onClick={handleShowModal}
                              >
                                Delete
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item post-control-edit"
                                onClick={() => handleEditComment(index)}
                              >
                                Edit
                              </a>
                            </li>
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="comments-item">
          <div className="row">
            <div className="col-md-1">
              <img
                className="comment-user-img"
                src={avatarUrl}
                alt="User Avatar"
              />
            </div>
            <div className="col-md-11">
              {/* <div
                className="ossn-comment-attach-photo"
                onClick={() => document.getElementById("file-input").click()}
              >
                <i className="fa fa-camera"></i>
              </div> */}
              <div className="ossn-comment-attach-photo"
              onClick={() => toggleSection("emojiSelector")}
              >
                <i className="fa fa-smile"></i>
              </div>
              <span
                id="comment-box-p607"
                className="comment-box"
                contentEditable
                onInput={(e) => setCommentText(e.currentTarget.textContent)}
                placeholder="Leave a comment..."
                suppressContentEditableWarning
                dangerouslySetInnerHTML={{ __html: commentText }}
              ></span>
              <button
                className="btn btn-primary btn-sm comment-post-btn"
                onClick={handleCommentPost}
              >
                Post
              </button>
            </div>
          </div>
        </div>
      </div>
      <input
        type="file"
        id="file-input"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      {attachmentPreview && (
        <div className="attachment-preview">
          <img
            src={attachmentPreview}
            alt="Attachment Preview"
            style={{ maxWidth: "100%", maxHeight: "200px" }}
          />
        </div>
      )}
        {activeSection === "emojiSelector" && (
        <div id="ossn-wall-emoji">
          <div className="emoji-list">
            {[
              "😊",
              "😂",
              "😍",
              "😎",
              "😢",
              "😅",
              "🤔",
              "😜",
              "😇",
              "😉",
              "😱",
              "🤗",
              "🤩",
              "🥳",
              "🤐",
              "😋",
              "🤪",
              "🥺",
              "😌",
              "🤧",
              "😤",
              "😞",
              "🙄",
              "😏",
              "😜",
              "🥰",
              "😶",
              "😯",
              "😴",
              "😜",
              "👻",
              "💀",
              "🎃",
              "🐱",
              "🐶",
              "🐵",
              "🐮",
              "🦁",
              "🐯",
              "🐷",
              "🐮",
              "🐸",
              "🐣",
              "🐦",
              "🐍",
              "🐛",
              "🦋",
              "🐜",
              "🦄",
              "🦑",
              "🐙",
              "🦞",
              "🦐",
              "🐡",
              "🐠",
              "🐟",
              "🦓",
              "🦒",
              "🦘",
              "🐪",
              "🐫",
              "🐘",
              "🦏",
              "🐗",
              "🐖",
              "🦃",
              "🦢",
              "🦦",
              "🦨",
              "🦩",
              "🦡",
              "🐆",
              "🐅",
              "🐈",
              "🐇",
              "🦔",
              "🐉",
              "🐍",
              "🦅",
              "🦆",
              "🦢",
              "🐦",
              "🦋",
              "🐜",
              "🦇",
              "🐕",
              "🐩",
              "🐈‍⬛",
              "🐦",
              "🐧",
              "🐓",
              "🐢",
              "🦎",
              "🐉",
              "🦖",
              "🦦",
              "🦩",
              "🦨",
              "🦦",
              "🐁",
              "🐀",
              "🐇",
              "🐿",
              "🦔",
              "🐾",
              "🦨",
              "🐚",
              "🦻",
              "🦶",
              "🐁",
              "🐿",
              "🐇",
              "🐊",
              "🦦",
              "🦧",
              "🐒",
              "🦉",
              "🦋",
              "🐜",
              "🦁",
              "🐅",
              "🦄",
              "🦥",
              "🦩",
            ].map((emoji, index) => (
              <span
                key={index}
                onClick={() => handleEmojiClick(emoji)}
                style={{ fontSize: "24px", cursor: "pointer", margin: "5px" }}
              >
                {emoji}
              </span>
            ))}
          </div>
        </div>
      )}
   <ConfirmationModal
        showModal={showModal}
        handleClose={handleCloseModal}
        handleConfirm={handleDeletePost}
      />
      <EditModal
        showModal={showEditModal}
        handleClose={handleCloseEditModal}
        postId={postId}
        title={title}
        fetchData={fetchData}
      />
      <ShareModal
        showModal={showShareModal}
        handleClose={handleCloseShareModal}
        postId={postId}
      />
      <ReportModal
        showModal={showReportModal}
        handleClose={handleCloseReportModal}
        postId={postId}
      />
      <EditCommentModal
        showModal={showEditCommentModal}
        handleClose={handleCloseEditCommentModal}
        commentText={editingCommentText}
        setCommentText={setEditingCommentText}
        handleSave={handleSaveCommentEdit}
      />

<ValidationModal
        showModal={showValidationModal}
        handleClose={handleCloseValidationModal}
        postId={postId}
      />
    </div>
  );
};

export default Post;
