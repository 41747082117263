import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { BACK_TO_HOME_PAGE } from "../../constant";
import Error4 from '../../assets/images/other-images/404.png';

const Error404 = () => {
    return (
        <Fragment>
            <div className="page-wrapper">
                {/* <!-- error-404 start--> */}

                <div className="error-wrapper">
                    <div className="container ">
                       <div className="">
                            <img className="erro" src={Error4}></img>
                        </div>
                        <div className="col-md-8 offset-md-2">
                            <p className="sub-content">{"The page you are attempting to reach is currently not available. This may be because the page does not exist or has been moved."}</p>
                        </div>
                        <div><Link to={`/home`} className="btn btn-danger-gradien"> {BACK_TO_HOME_PAGE}</Link></div>
                    </div>
                </div>
                {/* <!-- error-404 end--> */}
            </div>
        </Fragment>
    );
};

export default Error404;